import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Table from '@/components/Table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { featureFlag } from '@/utils/featureFlags';
import { IRecentTrade, IRecentTradeProps } from './interface';

function RecentTrade(props: IRecentTradeProps) {
  const { recentTrades, isLoading } = props;
  const columns: ColumnsType<IRecentTrade> = [
    {
      title: 'Account',
      dataIndex: 'account_number',
      key: 'account_number',
      render: (value: string, record) =>
        featureFlag['page:account_details'] ? (
          <Link to={`/public-market/account-details/${record.public_guid}`}>{value}</Link>
        ) : (
          <span>{value}</span>
        ),
    },
    {
      title: 'Date',
      dataIndex: 'transaction_date_display',
      key: 'transaction_date_display',
      sorter: (a, b) =>
        moment(a.transaction_date_raw).valueOf() - moment(b.transaction_date_raw).valueOf(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => a.symbol.localeCompare(b.symbol),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Amount',
      dataIndex: 'amount_display',
      key: 'amount_display',
      sorter: (a, b) => a.amount_raw - b.amount_raw,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Table
          className="bg-header-white"
          columns={columns}
          dataSource={recentTrades}
          isLoading={isLoading}
          title="RECENT TRADES"
          rowKey={(record) => record.transaction_id}
        />
      </Col>
    </Row>
  );
}

export default RecentTrade;
