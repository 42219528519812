import { Spin } from 'antd';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Loader(props: { height?: string | number }) {
  const { height } = props;
  return (
    <div
      data-testid="loader"
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        height,
      }}
    >
      <Spin />
    </div>
  );
}
Loader.defaultProps = {
  height: 40,
};

export default Loader;
