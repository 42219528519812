import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth/authSlice';
import dashboardReducer from './dashboard/dashboardSlice';
import publicMarketReducer from './public-market/publicMarketSlice';
import teamReducer from './team/teamSlice';
import privateMarketReducer from './private-market/privateMarketSlice';
import goalsReducer from './goals/goalSlice';
import commonReducer from './common/commonSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    publicMarket: publicMarketReducer,
    team: teamReducer,
    privateMarket: privateMarketReducer,
    goals: goalsReducer,
    common: commonReducer,
  },
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
