import { AnyAction } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import customNotification from '../utils/notification';

export interface IErrorData {
  isError: boolean;
  errorMessage: string;
  clearErrorState: () => AnyAction;
}

export default function useShowErrorToast({ isError, errorMessage, clearErrorState }: IErrorData) {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (isError) {
      customNotification({ type: 'error', message: errorMessage || 'Oops, something went wrong' });
    }

    return () => {
      // actions to be performed when component unmounts
      dispatch(clearErrorState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);
}
