import axios from 'axios';
import { appendQueryParams, getApiErrorMessage, getValueLocalStorage, isPWA } from './helper';
import history from './NavigationHistory/CustomHistory';
import { config as envConfig } from './config';
// import join from 'url-join';

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

async function refreshToken() {
  const userId = getValueLocalStorage('userId');
  const bodyParams = {
    userId,
  };
  try {
    await axios.post('/refresh-token', bodyParams);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    // Handle refresh token failure
    // Redirect to login or handle the error accordingly
    // customNotification({
    //   type: 'error',
    //   message: getApiErrorMessage(error) || 'Oops, something went wrong',
    // });

    localStorage.clear();
    history.replace('/login');
    throw new Error(getApiErrorMessage(error) || 'Oops, something went wrong');
  }
}

const excludedUrlPatterns = [/\/update-household\/\d+$/, /\/refresh-token$/];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
axios.interceptors.request.use(async (config: any) => {
  const updateConfig = { ...config, withCredentials: true };
  if (!isAbsoluteURLRegex.test(updateConfig.url) && envConfig.API_URL) {
    updateConfig.url = `${envConfig.API_URL}${updateConfig.url}`;
  }

  // Check if the request URL matches any of the excluded patterns
  const isExcluded = excludedUrlPatterns.some((pattern) => pattern.test(config.url));

  // If not excluded, append the query parameters
  if (!isExcluded) {
    const selectedHousehold = getValueLocalStorage('selectedHousehold');
    const isPwa = isPWA();
    if (isPwa && selectedHousehold) {
      const queryParams = { household_id: selectedHousehold };
      updateConfig.url = appendQueryParams(updateConfig.url, queryParams);
    }
  }

  // const token: string | null = getValueLocalStorage('token');
  // token += 'abcd';

  // if (token) {
  //   updateConfig.headers = { Authorization: `Bearer ${token}` };
  // }
  updateConfig.timeout = 80000; // 80 Second
  //  console.log("updateConfig-->", updateConfig)
  return updateConfig;
});

let isRefreshing = false;
let refreshPromise: Promise<void> | null = null;

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // eslint-disable-next-line no-underscore-dangle
    if (error.response && error.response.status === 403 && !originalRequest._retry) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          // Create a new refreshPromise for synchronization
          refreshPromise = refreshToken();

          // Wait for the refresh to complete
          await refreshPromise;

          // Retry the original request with the new token
          return await axios(originalRequest);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (refreshError: any) {
          // Redirect to login or handle the error accordingly
          if (refreshError?.response?.status === 401) {
            localStorage.clear();
            history.replace('/login');
          }
        } finally {
          isRefreshing = false;
          refreshPromise = null;
        }
      } else {
        // If another request is already refreshing the token, wait for it to finish
        if (refreshPromise) {
          await refreshPromise;
        }

        // Retry the original request with the new token
        return axios(originalRequest);
      }
    }

    if (error?.response?.status === 401) {
      // Logout
      localStorage.clear();
      history.replace('/login');
    }

    // // Don't show toast notification for 403 errors - 403 is used for accessToken expiry
    // if (error.response && error.response.status === 403) {
    //   return null;
    // }
    return Promise.reject(error);
  }
);

export default axios;
