import Table from '@/components/Table';
import { IMilestone } from './interface';

function Milestones(props: { data: IMilestone[]; isLoading: boolean }) {
  const { data, isLoading } = props;
  const columns = [
    {
      title: 'Date',
      dataIndex: 'duedate_display',
      key: 'duedate_display',
      width: 40,
    },
    {
      title: 'Milestones',
      dataIndex: 'content',
      key: 'content',
      width: 200,
    },
  ];
  return (
    <Table
      data-testid="milestones"
      title="Milestones"
      dataSource={data}
      columns={columns}
      className="bg-header-white"
      rowKey={(record) => record?.id}
      isLoading={isLoading}
    />
  );
}

export default Milestones;
