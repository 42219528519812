/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Ref, useState, useMemo } from 'react';
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Typography, Col, Dropdown } from 'antd';
import { useDrag } from 'react-dnd';
import { downloadFile } from '@/services/documentService';
import { hasAccessToDocumentOperation } from './DocumentsHelper';
import { useDocumentState } from './DocumentContext';
import folderIcon from '../../assets/folder.svg';
import { IFile } from './DocumentInterface';

const FileType = 'FILE';

// File Component (Draggable)
export const File: React.FC<{
  file: IFile;
  getFileIcon: (extension: string, key: string) => void;
  editableRef: Ref<HTMLElement>;
  handleEditClick: (file: IFile) => void;
  oldFileKey: string;
  isEditing: boolean;
  handleTextChange: (text: string) => void;
  handlePressEnter: () => void;
  key: string;
  handleSelectFileFromContext: (file: IFile) => void;
}> = ({
  key,
  file,
  getFileIcon,
  editableRef,
  handleEditClick,
  oldFileKey,
  isEditing,
  handleTextChange,
  handlePressEnter,
  handleSelectFileFromContext,
}) => {
  const {
    setCurrentDirectory,
    filesToMove,
    selectedFiles,
    selectFiles,
    openConfirmationModal,
    selectedDocumentConfig,
  }: {
    setCurrentDirectory: (directory: string) => void;
    filesToMove: IFile[];
    selectedFiles: IFile[];
    selectFiles: (file: IFile) => void;
    isDeleteModalOpen: boolean;
    onConfirmDelete: (file: string[]) => void;
    setIsDeleteModalOpen: (value: boolean) => void;
    modals: { [key: string]: boolean };
    openConfirmationModal: (id: string) => void;
    selectedDocumentConfig: any;
    documentConfig: any;
  } = useDocumentState();

  const isFileSelected = selectedFiles?.findIndex((item: IFile) => item.Key === file.Key);
  const isFileSelectedToMove = filesToMove?.findIndex(
    (fileToMove) => fileToMove?.Key === file?.Key
  );

  const haveAccess = useMemo(
    () => hasAccessToDocumentOperation(selectedDocumentConfig, 'cut'),
    [selectedDocumentConfig]
  );
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [{ isDragging }, drag] = useDrag({
    type: FileType,
    item: selectedFiles.length > 0 ? selectedFiles : [file],
    canDrag: haveAccess,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  const handleRightClick = (e: any) => {
    e.preventDefault();
    setContextMenuVisible(true);
  };

  const setFileToDelete = (currentFile: any) => {
    handleSelectFileFromContext(currentFile);
  };

  const handleMenuClick = (e: any) => {
    setContextMenuVisible(false);
    if (e.key === 'rename') {
      handleEditClick(file);
    } else if (e.key === 'delete') {
      setFileToDelete(file);
      openConfirmationModal('deleteFromContext');
    } else if (e.key === 'download') {
      downloadFile([file]);
    }
  };
  interface IMenuProps {
    visible: boolean;
    key: string;
    label: string;
    icon: JSX.Element;
  }
  const contextMenus: IMenuProps[] = [
    // {
    //   key: 'rename',
    //   label: 'Rename',
    //   icon: <EditOutlined />,
    //   visible: true,
    // },
    {
      key: 'delete',
      label: 'Delete',
      icon: <DeleteOutlined />,
      visible: hasAccessToDocumentOperation(selectedDocumentConfig, 'delete'),
    },
    {
      key: 'download',
      label: 'Download',
      icon: <DownloadOutlined />,
      visible: true,
    },
  ];

  return (
    <Dropdown
      menu={{ items: contextMenus.filter((item) => item.visible), onClick: handleMenuClick }}
      trigger={['contextMenu']}
      open={contextMenuVisible}
      onOpenChange={setContextMenuVisible}
    >
      <Col
        ref={drag}
        onClick={() => !file?.isDir && selectFiles(file)}
        onDoubleClick={() => file?.isDir && setCurrentDirectory(file.Key)}
        key={key}
        span={3}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          // cursor: haveAccess ? 'pointer' : 'not-allowed',
          marginTop: 10,
          marginLeft: 10,
          background: isFileSelected !== -1 || isFileSelectedToMove !== -1 ? '#add8e6' : '',
          opacity: isFileSelectedToMove !== -1 ? 0.4 : 1,
        }}
        onContextMenu={handleRightClick}
      >
        <>
          {file?.isDir ? (
            <img key={key} src={folderIcon} alt="Folder" style={{ height: 50, width: 50 }} />
          ) : (
            getFileIcon(file?.extension, key)
          )}

          <Typography.Paragraph
            ellipsis={{ rows: 1, expandable: false, symbol: '...', tooltip: file.name }}
            ref={editableRef}
            key={file.Key}
            style={{
              margin: '5px 0',
              width: isEditing && oldFileKey === file.Key ? '' : '100px',
              maxWidth: '12ch',
            }}
            // ellipsis
            editable={
              file?.isDir || !hasAccessToDocumentOperation(selectedDocumentConfig, 'rename')
                ? false
                : {
                    icon: <EditOutlined onClick={() => handleEditClick(file)} />,
                    editing: isEditing && oldFileKey === file.Key,
                    onChange: handleTextChange,
                    onEnd: handlePressEnter, // Set editing to false when editing ends
                  }
            }
          >
            {file.name}
          </Typography.Paragraph>
        </>
      </Col>
    </Dropdown>
  );
};
