/* eslint-disable @typescript-eslint/no-explicit-any */
import { PieChartFilled } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

function itemRender(currentRoute: any, params: any, items: any) {
  // const isLast = currentRoute?.path === items[items.length - 1]?.path;
  // const link = isLast ? '' : currentRoute.path;
  return <Link to={currentRoute.path}>{currentRoute.title}</Link>;
}

interface IBreadCrumbProps {
  paths: { path: string; title: any }[];
  includeDashboardPath?: boolean;
}
function CustomBreadcrumb(props: IBreadCrumbProps) {
  const { paths, includeDashboardPath = true } = props;
  const dashboardPath = {
    path: '/dashboard',
    title: (
      <>
        <PieChartFilled />
        <span style={{ marginLeft: 10 }}>My Allocation</span>
      </>
    ),
  };

  const updatedPaths = includeDashboardPath ? [dashboardPath, ...paths] : paths;

  return <Breadcrumb style={{ marginBottom: 20 }} itemRender={itemRender} items={updatedPaths} />;
}

CustomBreadcrumb.defaultProps = {
  includeDashboardPath: true,
};
export default CustomBreadcrumb;
