import { Form, Input, Button, Typography } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import AuthContainer from '../auth-container/AuthContainer';
import { resetLoginSubmitLoader } from '../../../store/auth/authSlice';
import { AppDispatch, AppState } from '../../../store';
import { IsignInData } from '../../../store/auth/authInterface';
import Loader from '../../../components/Loader';
import { signIn } from '../../../services/authService';
import { authMessages } from '../../../utils/messages';
// import { setValueLocalStorage } from '../../../utils/helper';
import { theme } from '../../../utils/theme';

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { PLEASE_ENTER_VALID_EMAIL_ADDRESS, PLEASE_ENTER_PASSWORD } = authMessages;
  const isSubmitLoginLoading = useSelector((state: AppState) => state.auth.isSubmitLoginLoading);
  useEffect(
    () => () => {
      // actions to be performed when component unmounts
      dispatch(resetLoginSubmitLoader());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onFinish = (values: IsignInData) => {
    const { email, password } = values;
    dispatch(signIn({ email, password, navigate }));
  };

  return (
    <AuthContainer>
      <CustomHelmet title="Login" />
      <Form initialValues={{ remember: true }} onFinish={onFinish}>
        <div>
          <p className="bold uppercase">
            Welcome, <span style={{ color: theme['@primary-color'] }}>Please login</span>
          </p>

          <Form.Item
            name="email"
            rules={[{ required: true, type: 'email', message: PLEASE_ENTER_VALID_EMAIL_ADDRESS }]}
          >
            <Input
              data-testid="email"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="E-Mail Address"
              autoComplete="username"
            />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: PLEASE_ENTER_PASSWORD }]}>
            <Input.Password
              data-testid="password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              autoComplete="current-password"
            />
          </Form.Item>
        </div>
        <div className="auth-container-footer">
          {isSubmitLoginLoading ? (
            <Loader />
          ) : (
            <>
              <Form.Item>
                <Link to="/forgot-password" style={{ color: '#66BFBD' }}>
                  Forgot Password?
                </Link>
              </Form.Item>
              <Form.Item>
                <Button
                  data-testid="submit"
                  type="primary"
                  htmlType="submit"
                  style={{ backgroundColor: '#09323C', color: '#66BFBD' }}
                >
                  LOGIN
                </Button>
              </Form.Item>
            </>
          )}
        </div>
      </Form>
    </AuthContainer>
  );
}
export default Login;
