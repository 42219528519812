import { IGoalsPageData } from '@/views/goals/interface';

export const mockGoalsData: IGoalsPageData = {
  historicalValues: [],
  goals: [
    {
      id: 1,
      date: '2023-10-09T18:30:00.000Z',
      date_display: '10/10/2023',
      content: 'Test Notes',
    },
  ],
  milestones: [
    {
      id: 1,
      duedate: '2024-02-23T18:30:00.000Z',
      duedate_display: '02/24/2024',
      content: 'Test Desc',
    },
  ],
};
