import { IPrivateMarketDetailsData, IPrivateMarketPositionSummary } from './interface';

export const emptyAsset = {
  id: '',
  committed_raw: 0.0,
  committed_display: '$0.00',
  called_raw: 0.0,
  called_display: '$0.00',
  distributed_raw: 0.0,
  distributed_display: '$0.00',
  paid_raw: 0.0,
  paid_display: '$0.00',
  efv_raw: 0.0,
  efv_display: '$0.00',
  allocation_raw: 0.0,
  allocation_display: '0.00%',
  name: '',
  positions: [],
  pending_raw: 0.0,
  pending_display: '$0.00',
  remaining_raw: 0.0,
  remaining_display: '$0.00',
};

export function transformPositionData(
  privateMarketDetails: IPrivateMarketDetailsData
): IPrivateMarketPositionSummary {
  return {
    id: Number(privateMarketDetails?.position_id),
    committed_raw: privateMarketDetails?.committed_raw,
    committed_display: privateMarketDetails?.committed_display,
    called_raw: privateMarketDetails?.called_raw,
    called_display: privateMarketDetails?.called_display,
    distributed_raw: privateMarketDetails?.distributed_raw,
    distributed_display: privateMarketDetails?.distributed_display,
    paid_raw: privateMarketDetails?.paid_to_date_raw,
    paid_display: privateMarketDetails?.paid_to_date,
    efv_raw: privateMarketDetails?.efv_raw,
    efv_display: privateMarketDetails?.efv_display,
    dpi: privateMarketDetails?.dpi,
    tvpi: privateMarketDetails?.tvpi,
    net_irr_raw: privateMarketDetails?.irr_raw,
    net_irr_display: privateMarketDetails?.irr_display,
    ownership_raw: privateMarketDetails?.ownership_raw,
    ownership_display: privateMarketDetails?.ownership_display,
    name: privateMarketDetails?.account?.name,
    pending_raw: privateMarketDetails?.pending_raw,
    pending_display: privateMarketDetails?.pending_display,
    remaining_raw: privateMarketDetails?.remaining_raw,
    remaining_display: privateMarketDetails?.remaining_display,
  };
}

export const privateMarketDetailsEmptyState: IPrivateMarketDetailsData = {
  position_id: '',
  committed_raw: 0,
  committed_display: '$0.00',
  called_raw: 0.0,
  called_display: '$0.00',
  distributed_raw: 0.0,
  distributed_display: '$0.00',
  paid_to_date_raw: 0.0,
  paid_to_date: '$0.00',
  efv_raw: 0.0,
  efv_display: '$0.00',
  pending_raw: 0.0,
  pending_display: '$0.00',
  remaining_raw: 0.0,
  remaining_display: '$0.00',
  dpi: 0,
  tvpi: 0,
  irr_raw: 0,
  irr_display: '0.00%',
  ownership_raw: 0.0,
  ownership_display: '0.00%',
  account: {
    name: '',
  },
  capital_calls: [],
  distributions: [],
  investment: {
    company_name: '',
    investment_id: 0,
    efv_display: '$0.00',
    efv_raw: '0',
    description: '',
    name: '',
    investment_type: '',
    security_type: '',
  },
  updates: [],
};
