import { IPrivateMarketDetailsData, IPortfolio } from '@/views/private-market/interface';

export const mockPrivateMarketResponse: IPortfolio = {
  equity: {
    id: '1',
    committed_raw: 1579.25,
    committed_display: '$1579.25',
    called_raw: 6578.5,
    called_display: '$6578.50',
    distributed_raw: 5578.5,
    distributed_display: '$5578.50',
    paid_raw: 6679.5,
    paid_display: '$6679.50',
    efv_raw: 6679.5,
    efv_display: '$6679.50',
    allocation_raw: 0.36,
    allocation_display: '0.36%',
    pending_display: '$100',
    pending_raw: 100,
    remaining_raw: 200,
    remaining_display: '$200',
    name: 'Equity',
    positions: [
      {
        id: 11,
        committed_raw: 6578.5,
        committed_display: '$6578.50',
        called_raw: 6578.5,
        called_display: '$6578.50',
        distributed_raw: 5458.5,
        distributed_display: '$5458.50',
        paid_raw: 6578.5,
        paid_display: '$6578.50',
        efv_raw: 6578.5,
        efv_display: '$6578.50',
        dpi: 6578.5,
        tvpi: 6578.5,
        net_irr_raw: 0.45,
        net_irr_display: '0.45%',
        name: 'Reymundo',
        pending_display: '$100',
        pending_raw: 100,
        remaining_raw: 200,
        remaining_display: '$200',
      },
    ],
    bdc_positions: [
      {
        account_name: 'Carissa',
        subscription_amount_raw: 6578.5,
        subscription_amount_display: '$6578.50',
        nav_raw: 659.5,
        nav_display: '659.50',
        investment_name: 'Cale',
        shares: 10,
        pending_display: '$100',
        pending_raw: 0,
      },
    ],
  },
  debt: {
    id: '1',
    committed_raw: 6578.5,
    committed_display: '$6578.50',
    called_raw: 6578.5,
    called_display: '$6578.50',
    distributed_raw: 5478.75,
    distributed_display: '$5478.75',
    paid_raw: 6578.5,
    paid_display: '$6578.50',
    efv_raw: 6578.5,
    efv_display: '$6578.50',
    allocation_raw: 0.35,
    allocation_display: '0.35%',
    name: 'Debt',
    pending_display: '$100',
    pending_raw: 100,
    remaining_raw: 200,
    remaining_display: '$200',
    positions: [
      {
        id: 15,
        committed_raw: 6578.5,
        committed_display: '$6578.50',
        called_raw: 6578.5,
        called_display: '$6578.50',
        distributed_raw: 5478.75,
        distributed_display: '$5478.75',
        paid_raw: 6578.5,
        paid_display: '$6578.50',
        efv_raw: 6578.5,
        efv_display: '$6578.50',
        dpi: 6578.5,
        tvpi: 6578.5,
        name: 'Jessyca',
        net_irr_raw: 56.32,
        net_irr_display: '56.32%',
        pending_display: '$100',
        pending_raw: 100,
        remaining_raw: 200,
        remaining_display: '$200',
      },
    ],
  },
  real_estate: {
    id: '1',
    committed_raw: 6578.5,
    committed_display: '$6578.50',
    called_raw: 6578.5,
    called_display: '$6578.50',
    distributed_raw: 5478.75,
    distributed_display: '$5478.75',
    paid_raw: 5478.5,
    paid_display: '$5478.50',
    efv_raw: 5478.5,
    efv_display: '$5478.50',
    allocation_raw: 0.29,
    allocation_display: '0.29%',
    name: 'Real Estate',
    pending_display: '$100',
    pending_raw: 100,
    remaining_raw: 200,
    remaining_display: '$200',
    positions: [
      {
        id: 21,
        committed_raw: 5478.75,
        committed_display: '$6578.50',
        called_raw: 6578.5,
        called_display: '$6578.50',
        distributed_raw: 5478.75,
        distributed_display: '$5478.75',
        paid_raw: 5478.75,
        paid_display: '5478.75',
        efv_raw: 5478.75,
        efv_display: '$5478.75',
        dpi: 5478.75,
        tvpi: 5478.75,
        name: 'Alden',
        net_irr_raw: 11.5,
        net_irr_display: '11.50%',
        pending_display: '$100',
        pending_raw: 100,
        remaining_raw: 200,
        remaining_display: '$200',
      },
    ],
  },
};

export const mockPrivateMarketDetails: IPrivateMarketDetailsData = {
  position_id: '1',
  committed_raw: 5689,
  committed_display: '$5689',
  called_raw: 6348,
  called_display: '$6348',
  distributed_raw: 1927,
  distributed_display: '$1927',
  paid_to_date_raw: 1500,
  paid_to_date: '$1500',
  efv_raw: 178,
  efv_display: '$178',
  dpi: 15.25,
  tvpi: 17.5,
  irr_raw: 0.35,
  irr_display: '0.35%',
  ownership_raw: 10.5,
  ownership_display: '10.50%',
  pending_display: '$100',
  pending_raw: 100,
  remaining_raw: 200,
  remaining_display: '$200',
  account: {
    name: 'Bridget',
  },
  capital_calls: [
    {
      transaction_id: 1,
      transaction_date_raw: '2023-09-28 10:44:37',
      transaction_date_display: '09/28/2023',
      description:
        'Temporibus cum quisquam quidem sequi assumenda tempore. Maiores quo saepe labore. Veniam non ipsam quam aperiam asperiores illo quidem accusantium ratione. Culpa magnam fugit. Adipisci repellat aspernatur fuga incidunt ullam dicta commodi. Doloribus laudantium ea quasi enim suscipit.',
      amount_raw: 4612.23,
      amount_display: '$4612.23',
    },
  ],
  distributions: [
    {
      transaction_id: 2,
      transaction_date_raw: '2023-09-28 10:44:37',
      transaction_date_display: '09/28/2023',
      description:
        'Eum repellat consequatur in at quibusdam beatae. Laborum similique adipisci atque ea nam eius. Nisi esse nobis perferendis ullam a possimus quo tempora. Aliquam voluptates expedita. Sint enim vero illum aperiam tempore error.',
      amount_raw: 1509.5,
      amount_display: '$1509.50',
    },
  ],

  investment: {
    company_name: 'Test',
    investment_id: 151,
    name: 'test',
    description:
      'Ipsam dicta atque asperiores magni perspiciatis. Consectetur culpa aliquam consectetur amet. Praesentium nihil sunt blanditiis occaecati.',
    efv_raw: '10526.30',
    efv_display: '$10526.30',
    security_type: 'Test',
    investment_type: 'Test',
  },
  updates: [
    {
      update_id: 1,
      title: 'News Title1',
      description:
        'Accusamus odit nisi iusto quaerat. Tempora non similique totam consequatur praesentium enim. Beatae repellat corporis saepe iure saepe doloribus sit. Repudiandae modi ullam iure exercitationem.',
      date_raw: '1/1/24',
      date_display: '10 Jan',
    },
  ],
};
