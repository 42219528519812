import { sendOtp } from '@/services/authService';
import { AppDispatch, AppState } from '@/store';
import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function getRetryTimeout(attemptNumber: number) {
  const retryTimeouts: { [key: number]: number } = {
    1: 30,
    2: 40,
    3: 60,
    4: 90,
    5: 120,
  };

  return retryTimeouts[attemptNumber] || 300;
}
const RetrySms: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { smsRetryCount, isSmsRetryLoading } = useSelector((state: AppState) => state.auth);
  const [remainingTime, setRemainingTime] = useState(getRetryTimeout(smsRetryCount)); // Initial remaining time in seconds

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime === 0 && intervalId) {
            clearInterval(intervalId);
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [remainingTime]);

  useEffect(() => {
    setRemainingTime(getRetryTimeout(smsRetryCount));
  }, [smsRetryCount]);

  const handleResendClick = () => {
    dispatch(sendOtp());
  };
  const formatRemainingTime = () => {
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `${minutes > 0 ? `${minutes} minutes` : ''} ${seconds} seconds`;
  };

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        onClick={handleResendClick}
        className="uppercase"
        style={{
          backgroundColor: remainingTime > 0 ? '#E8E8E8' : '#09323C',
          color: remainingTime > 0 ? '#C0C0C0' : '#66BFBD',
        }}
        disabled={remainingTime > 0}
        loading={isSmsRetryLoading}
      >
        Resend Code
      </Button>
      {remainingTime > 0 && (
        <div>
          <span> Resend code in {formatRemainingTime()}.</span>
        </div>
      )}
    </>
  );
};

export default RetrySms;
