import CustomBreadcrumb from '@/components/Breadcrumb';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import useShowErrorToast from '@/hooks/useShowErrorToast';
import { getprivateMarketDetails } from '@/services/privateMarketService';
import { AppDispatch, AppState } from '@/store';
import { clearprivateMarketDetailsErrorState } from '@/store/private-market/privateMarketSlice';
import { Col, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AssetClass from '../AssetClass';
import { transformPositionData } from '../constants';
import FinancialOverview from './FinancialOverview';
import InvestmentInfo from './InvestmentInfo';
import InvestmentNews from './InvestmentNews';
import TransactionDetails from './TransactionDetails';

function PrivateMarketDetails() {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getprivateMarketDetails(id));
    }
  }, [dispatch, id]);
  const {
    privateMarketDetails,
    isGetPrivateMarketDetailsLoading,
    isGetPrivateMarketDetailsError,
    getprivateMarketDetailsErrorMessage,
  } = useSelector((state: AppState) => state.privateMarket);
  const { updates, capital_calls, distributions, investment } = privateMarketDetails;

  useShowErrorToast({
    isError: isGetPrivateMarketDetailsError,
    errorMessage: getprivateMarketDetailsErrorMessage,
    clearErrorState: clearprivateMarketDetailsErrorState,
  });

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <CustomHelmet title="Private Investment" />
      <CustomBreadcrumb
        paths={[
          {
            path: '/private-market',
            title: 'Overview',
          },
          {
            path: '',
            title: investment?.name || 'Details',
          },
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <InvestmentInfo
            data={{
              investment_type: investment?.investment_type || '',
              security_type: investment?.security_type || '',
              name: investment?.company_name || '',
            }}
            isLoading={isGetPrivateMarketDetailsLoading}
          />
        </Col>
        <Col span={12}>
          <FinancialOverview
            data={transformPositionData(privateMarketDetails)}
            isLoading={isGetPrivateMarketDetailsLoading}
          />
        </Col>
      </Row>

      <AssetClass
        title="Position Summary"
        data={[transformPositionData(privateMarketDetails)]}
        isLoading={isGetPrivateMarketDetailsLoading}
      />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <TransactionDetails
            data={capital_calls || []}
            isLoading={isGetPrivateMarketDetailsLoading}
            title="Capital Calls"
          />
        </Col>
        <Col span={12}>
          <TransactionDetails
            data={distributions || []}
            isLoading={isGetPrivateMarketDetailsLoading}
            title="Distributions"
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <InvestmentNews data={updates || []} isLoading={isGetPrivateMarketDetailsLoading} />
        </Col>
        <Col span={12} />
      </Row>
    </Space>
  );
}

export default PrivateMarketDetails;
