import { IAllocationDetail, IAllocationType } from './interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformAllocationData = ({ allocationData }: { allocationData: any }) => {
  const data = Object.keys(allocationData)
    .map((key) => allocationData[key])
    .filter((item) => typeof item === 'object');

  const totalValue = data
    .reduce((acc, item) => acc + parseFloat(item?.market_value_raw), 0)
    .toFixed(2);
  const totalAllocation = data
    .reduce((acc, item) => acc + parseFloat(item?.allocation_raw), 0)
    .toFixed(2);

  const result = {
    totalValue: Number(totalValue),
    totalPercentage: Number(totalAllocation),
    data,
  };
  return result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformAllocationSummaryData = (allocation: any) => {
  const transformedEquity = transformAllocationData({ allocationData: allocation?.equity });
  const transformedDebt = transformAllocationData({ allocationData: allocation?.debt });

  const totalEquityValue = transformedEquity?.totalValue;
  const totalDebtValue = transformedDebt?.totalValue;
  const totalEquityPercentage = transformedEquity?.totalPercentage;
  const totalDebtPercentage = transformedDebt?.totalPercentage;

  const totalHouseholdValue = totalEquityValue + totalDebtValue;
  const totalHouseholdPercentage = totalEquityPercentage + totalDebtPercentage;

  // To proide unique keys to graph updating the private and public keys
  const updatedEquityData = transformedEquity.data.map((item) => {
    if (item.display_name === 'Private') {
      return { ...item, display_name: 'Private Equity' };
    }
    if (item.display_name === 'Public') {
      return { ...item, display_name: 'Public Equity' };
    }

    return item;
  });
  const updatedDebtData = transformedDebt.data.map((item) => {
    if (item.display_name === 'Private') {
      return { ...item, display_name: 'Private Fixed Income' };
    }
    if (item.display_name === 'Public') {
      return { ...item, display_name: 'Public Fixed Income' };
    }

    return item;
  });
  const mergedAllocationData = [...updatedEquityData, ...updatedDebtData];
  return {
    totalHouseholdValue,
    totalEquityValue,
    mergedAllocationData,
    totalHouseholdPercentage,
    totalEquityPercentage,
    totalDebtValue,
    totalDebtPercentage,
    transformedDebt,
    transformedEquity,
  };
};

export function calculateTotalAllocation(data: IAllocationType[]) {
  const result = data.reduce(
    (accumulator, category) =>
      category?.allocation?.reduce(
        (acc, item) => ({
          totalHouseholdValue:
            acc.totalHouseholdValue + parseFloat(item?.market_value_raw?.toString()),
          totalHouseholdPercentage:
            acc.totalHouseholdPercentage + parseFloat(item?.allocation_raw?.toString()),
        }),
        accumulator
      ),
    { totalHouseholdValue: 0, totalHouseholdPercentage: 0 }
  );
  result.totalHouseholdPercentage = Number(result.totalHouseholdPercentage.toFixed(2));
  result.totalHouseholdValue = Number(result.totalHouseholdValue.toFixed(2));

  return result;
}
type AllocationItem = IAllocationDetail & { root_display_name: string };

function findDuplicatedDisplayNames(data: AllocationItem[]): string[] {
  const displayNamesMap: Record<string, boolean> = {};
  const duplicatedDisplayNames: string[] = [];

  data.forEach((item: IAllocationDetail) => {
    const displayName = item?.display_name;

    if (displayNamesMap[displayName]) {
      if (!duplicatedDisplayNames.includes(displayName)) {
        duplicatedDisplayNames.push(displayName);
      }
    } else {
      displayNamesMap[displayName] = true;
    }
  });

  return duplicatedDisplayNames;
}

export function mergeAndModifyAllocations(data: IAllocationType[]) {
  // Merge allocation arrays and add root_display_name
  const mergedAllocations = [
    ...data.flatMap((item) =>
      item.allocation.map((allocation) => ({
        ...allocation,
        root_display_name: item.display_name,
      }))
    ),
  ];

  // Find duplicated display names
  const duplicateNames = findDuplicatedDisplayNames(mergedAllocations);

  // Modify items with duplicate display names
  const modifiedAllocations = mergedAllocations.map((item) => {
    if (duplicateNames.includes(item.display_name)) {
      return { ...item, display_name: `${item.display_name} ${item.root_display_name}` };
    }
    return item;
  });

  return modifiedAllocations;
}
