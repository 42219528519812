import Card from '@/components/Card';
import { Descriptions } from 'antd';
import { IPortfolioInfo } from './interface';

function PortfolioInfo(props: { isLoading: boolean; portfolioDetails: IPortfolioInfo }) {
  const { isLoading, portfolioDetails } = props;
  const { portfolio_name, portfolio_type, equity_allocation } = portfolioDetails || {};

  return (
    <Card title="Portfolio Details" isLoading={isLoading}>
      <Descriptions layout="horizontal" column={1} contentStyle={{ fontWeight: 'bold' }}>
        <Descriptions.Item label="Name">{portfolio_name}</Descriptions.Item>
        <Descriptions.Item label="Equity Allocation">{equity_allocation}</Descriptions.Item>
        <Descriptions.Item label="Portfolio Type">{portfolio_type}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

export default PortfolioInfo;
