import { Layout } from 'antd';
import { DirectoryTreeProps } from 'antd/lib/tree';
import React, { useState } from 'react';
import { DocumentProvider } from './DocumentContext';
import pdfIcon from '../../assets/pdf.svg';
import excelIcon from '../../assets/excel.svg';
import wordIcon from '../../assets/word.svg';
import imageIcon from '../../assets/image.svg';

import DocumentsContainer from './DocumentsContainer';
import DocumentHeader from './DocumentHeader';
import FileUpload from './FileUpload';
import UnknownFile from '../../assets/file-unknown.svg';

const getFileIcon = (extension: string, key: string) => {
  switch (extension?.toLowerCase()) {
    case 'pdf':
      return <img key={key} style={{ height: 50, width: 50 }} src={pdfIcon} alt="PDF" />;
    case 'doc':
    case 'docx':
      return <img key={key} style={{ height: 50, width: 50 }} src={wordIcon} alt="Word" />;
    case 'xls':
    case 'xlsx':
      return <img key={key} style={{ height: 50, width: 50 }} src={excelIcon} alt="Excel" />;
    case 'jpg':
    case 'jpeg':
    case 'png':
      return <img key={key} style={{ height: 50, width: 50 }} src={imageIcon} alt="IMG" />;
    default:
      return <img key={key} style={{ height: 50, width: 50 }} src={UnknownFile} alt="File" />;
  }
};

// eslint-disable-next-line react/function-component-definition
const Documents: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {};

  return (
    <DocumentProvider>
      <Layout>
        <DocumentHeader showModal={showModal} />

        <DocumentsContainer getFileIcon={getFileIcon} onFolderExpand={onExpand} />

        <div>
          <FileUpload isModalOpen={isModalOpen} handleCancel={handleCancel} />
        </div>
      </Layout>
    </DocumentProvider>
  );
};

export default Documents;
