/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tree } from 'antd';
import { useState, useEffect } from 'react';
import { useDocumentState } from './DocumentContext';
import { IDirectoryNode } from './DocumentInterface';
import { getAllKeys, getFoldersWithSubfolders } from './DocumentsHelper';
import { Folder } from './Folder';

const { DirectoryTree, TreeNode } = Tree;

const DirectoryTreeComponent: React.FC<any> = ({ onSelect }) => {
  const { baseDirectories, currentDirectory, documentConfig } = useDocumentState();

  const renderTreeNodes = (data: any) =>
    data.map((item: any) => {
      if (item.children) {
        return (
          <TreeNode
            title={<Folder folder={item} isSelected={item.key === currentDirectory} />}
            key={item.key}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          title={<Folder folder={item} isSelected={item.key === currentDirectory} />}
          key={item.key}
        />
      );
    });
  const [expandedKeys, setExpandedKeys] = useState<any>(
    getFoldersWithSubfolders(getAllKeys(baseDirectories))
  );

  const onExpand = (keys: any, { expanded }: any) => {
    setExpandedKeys(keys);
  };
  useEffect(() => {
    const baseDirectory = documentConfig.find(
      (item: IDirectoryNode) => item.key === currentDirectory
    );
    if (baseDirectory?.key) {
      setExpandedKeys((prevExpandedKeys: string[]) => {
        const currentBaseDirectoryKeys = getFoldersWithSubfolders(
          getAllKeys(baseDirectories)
        ).filter((key: any) => {
          const slashCount = (key.match(/\//g) || []).length;
          // On selecting base folder expand only one level
          if (typeof key === 'string' && key.startsWith(baseDirectory?.key) && slashCount === 1) {
            return true;
          }
          return false;
        });

        const updatePrevExpanded = prevExpandedKeys.filter(
          (key: any) => typeof key === 'string' && !key.startsWith(baseDirectory?.key)
        );
        return [...updatePrevExpanded, ...currentBaseDirectoryKeys];
      });
    }
    // baseDirectories gets updated when root directory is clicked
  }, [baseDirectories]);
  return (
    <DirectoryTree
      onSelect={onSelect}
      onExpand={onExpand}
      selectedKeys={[currentDirectory]}
      expandedKeys={expandedKeys} // Keys used here is expand in folder tree
      style={{ background: '#ccc' }}
      showIcon={false}
    >
      {/* File drag and drop not works with treeData Props, so using children */}
      {renderTreeNodes(baseDirectories)}
    </DirectoryTree>
  );
};

export default DirectoryTreeComponent;
