import CustomBreadcrumb from '@/components/Breadcrumb';
import Card from '@/components/Card';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { FileOutlined } from '@ant-design/icons';

function Terms() {
  return (
    <>
      <CustomHelmet title="Terms of Service" />

      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: (
              <>
                <FileOutlined />
                <span style={{ marginLeft: 10 }}> Terms of Service</span>
              </>
            ),
          },
        ]}
      />
      <Card title="TERMS AND CONDITIONS FOR BIP CLIENT CARE">
        <div>
          <ol>
            <li>
              LICENSE GRANTS: BIP Technology, LLC ("BIP") grants Client a non-exclusive,
              non-transferable worldwide right to use BIP ClientCare ("ClientCare"), solely for
              Client's own personal purposes subject to the terms of this Agreement. Subject to the
              terms of this Agreement, Client grants to BIP the non-exclusive, worldwide, right to
              use, copy, store, transmit and display Client Data solely to the extent necessary to
              provide BIP as requested by Client. Client shall not (i) license, sublicense, sell,
              resell, transfer, assign, distribute or otherwise make available to any third party
              ClientCare or the Content (including tutorials, resource files, instructional
              materials, and methodology work flows); (ii) modify or make derivative works based
              upon ClientCare or the Content; (iii) commercially exploit ClientCare or the Content
              in any way, or (iv) create Internet "links" to ClientCare or "frame" or "mirror" any
              Content contained in, or accessible from, ClientCare on any other server, wireless, or
              Internet-based device. All rights not expressly granted to Client are reserved by BIP
              and its licensors.
            </li>

            <li>
              RESTRICTIONS: Client is permitted to store, manipulate, analyze, reformat, print, and
              display the Content only for Client's personal use. Unauthorized use, resale, or
              commercial exploitation of ClientCare and/or the Content in any way is expressly
              prohibited. Client agrees not to reverse engineer ClientCare, or access ClientCare in
              order to (i) build a competitive product or service, (ii) build a product using
              similar ideas, features, functions, or graphics of ClientCare, or (iii) copy any
              ideas, features, functions, or graphics of ClientCare. Client shall not copy, license,
              sell, transfer, make available, distribute, or assign this license or the Content to
              any third-party. Client shall not create Internet "links" to ClientCare or "frame" or
              "mirror" any Content contained on, or accessible from, ClientCare on any other server
              or Internet-based device. User licenses cannot be shared or used by more than one
              household.
            </li>

            <li>
              THIRD-PARTY INTERACTION: Client shall not (i) license, sublicense, sell, resell,
              transfer, assign, distribute, or otherwise make available to any third party
              ClientCare or the Content, (ii) modify or make derivative works based upon the
              ClientCare technology or the Content; (iii) commercially exploit ClientCare or the
              Content in any way, or (iv) create Internet "links" to ClientCare or "frame" or
              "mirror" any Content contained in, or accessible from, ClientCare on any other server,
              wireless, or Internet-based device.{' '}
            </li>

            <li>
              OWNERSHIP: BIP alone (and its licensors, where applicable) shall own all right, title,
              and interest, including all related Intellectual Property Rights, in and to
              ClientCare, the Content, and any suggestions, ideas, enhancement requests, feedback,
              recommendations, or other information provided by Client or any other party relating
              to ClientCare. This Agreement is not a sale and does not convey any rights of
              ownership in or related to ClientCare, or Intellectual Property owned by BIP to
              Client. The BIP name and logo are trademarks of BIP, and no right or license is
              granted to use them.
            </li>

            <li>
              ACCOUNT INFORMATION AND DATA: Client, not BIP, shall have sole responsibility for the
              accuracy, quality, integrity, legality, reliability, appropriateness and copyright of
              all Client Data, and BIP shall not be responsible or liable for any action taken by
              the Client that results in the deletion, correction, destruction, damage, loss, or
              failure to store any data. In the event that Client terminates this Agreement (other
              than by reason of Client's breach), BIP will make available to Client a file of the
              Client Data within 30 days of termination notice if Client so requests. BIP reserves
              the right to withhold Client Data for any breach, including, without limitation,
              Client's non-payment, until such time that the breach is satisfied. Upon termination
              for cause, Client's right to access or use ClientCare immediately ceases, and BIP will
              make available to Client a file of the Client Data within 30 days of termination
              notice if Client so requests.
            </li>

            <li>
              SECURITY: BIP shall use reasonable measures available to protect the security of
              Client's data, including virus protection, but does not warrant or guarantee that
              ClientCare is free from the vulnerability of Internet attacks.
            </li>

            <li>
              INTERNET DELAYS: ClientCare's services may be subject to limitations, delays, and
              other problems inherent in the use of the Internet and electronic communications. BIP
              is not responsible for any delays, delivery failures, or other damage resulting from
              such problems.
            </li>

            <li>
              MUTUAL INDEMNIFICATION: Client shall indemnify and hold BIP, its licensors, and each
              such party's parent organizations, subsidiaries, affiliates, officers, directors,
              employees, attorneys and agents harmless from and against any and all claims, costs,
              damages, losses, liabilities and expenses (including attorneys' fees and costs)
              arising out of or in connection with: (i) a claim alleging that use of the Client Data
              infringes the rights of, or has caused harm to, a third party; (ii) a claim, which if
              true, would constitute a violation by Client of the representations and warranties; or
              (iii) a claim arising from the breach by Client or Users of this Agreement, provided
              in any such case that BIP (i) gives written notice of the claim promptly to Client
              (ii) gives Client sole control of the defense and settlement of the claim (provided
              that Client may not settle or defend any claim unless it unconditionally releases BIP
              of all liability and such settlement does not affect BIP's business or BIP); (iii)
              provides to Client all available information and assistance; and (iv) has not
              compromised or settled such claim. BIP shall indemnify and hold Client and its parent
              organizations, subsidiaries, affiliates, officers, directors, employees, attorneys and
              agents harmless from and against any and all claims, costs, damages, losses,
              liabilities and expenses (including attorneys' fees and costs) arising out of or in
              connection with: (i) a claim alleging that BIP directly infringes a copyright, a U.S.
              patent issued as of the Effective Date, or trademark of a third party; (ii) a claim,
              which if true, would constitute a violation of BIP of the representations or
              warranties; or (iii) a claim arising from breach of this Agreement by BIP's; provided
              that Client (i) promptly gives written notice of the claim to BIP's; (ii) gives BIP
              sole control of the defense and settlement of the claim (provided that BIP may not
              settle or defend any claim unless it unconditionally releases Client of all
              liability); (iii) provides to BIP all available information and assistance; and (iv)
              has not compromised or settled such claim. BIP shall have no indemnification
              obligation, and Client shall indemnify BIP pursuant to this Agreement, for claims
              arising from any infringement arising from the combination of BIP with any of Client's
              products, service, hardware, or business process(s).
            </li>

            <li>
              DISCLAIMER OF WARRANTIES: BIP AND ITS LICENSORS MAKE NO REPRESENTATION, WARRANTY, OR
              GUARANTY AS TO THE RELIABILITY, INTERNET AVAILABILITY, QUALITY, SUITABILITY, TRUTH,
              AVAILABILITY, ACCURACY, OR COMPLETENESS OF CLIENTCARE OR ANY CONTENT; BIP AND ITS
              LICENSORS DO NOT REPRESENT OR WARRANT THAT (A) THE USE OF THE SERVICE WILL BE SECURE,
              TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN COMBINATION WITH ANY OTHER HARDWARE,
              SOFTWARE, SYSTEM OR DATA, (B) THE SERVICE WILL MEET CLIENT'S REQUIREMENTS OR
              EXPECTATIONS, (C) ANY STORED DATA WILL BE ACCURATE OR RELIABLE, (D) THE QUALITY OF ANY
              PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY CLIENT
              THROUGH ACHIEVEIT ONLINE WILL MEET CLIENT'S REQUIREMENTS OR EXPECTATIONS, (E) ERRORS,
              OR DEFECTS WILL BE CORRECTED, OR (F) THE SERVICE OR THE SERVER(S) THAT MAKE THE
              SERVICE AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; (III) THE SERVICE
              AND ALL CONTENT IS PROVIDED TO CLIENT STRICTLY ON AN "AS IS" BASIS; AND (IV) ALL
              CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR
              OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE
              HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW BY BIP AND ITS
              LICENSORS.
            </li>

            <li>
              LIMITATION OF LIABILITY: IN NO EVENT SHALL BIP'S AGGREGATE LIABILITY EXCEED ONE
              THOUSAND DOLLARDS ($1,000.00). THERE SHALL BE NO CAP ON CLIENT'S LIABILITY. IN NO
              EVENT SHALL EITHER PARTY AND/OR ITS LICENSORS BE LIABLE TO ANYONE FOR ANY INDIRECT,
              PUNITIVE, SPECIAL, EXEMPLARY, INCIDENTAL, CONSEQUENTIAL, OR OTHER DAMAGES OF ANY TYPE
              OR KIND (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE)
              ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THIS SERVICE, INCLUDING BUT NOT LIMITED
              TO THE USE OR INABILITY TO USE THE SERVICE, OR FOR ANY CONTENT OBTAINED FROM OR
              THROUGH THE SERVICE, ANY INTERRUPTION, INACCURACY, ERROR OR OMISSION, REGARDLESS OF
              CAUSE IN THE CONTENT, EVEN IF THE PARTY FROM WHICH DAMAGES ARE BEING SOUGHT OR SUCH
              PARTY'S LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </li>

            <li>
              ADDITIONAL RIGHTS: Certain states and/or jurisdictions do not allow the exclusion of
              implied warranties or limitation of liability for incidental or consequential damages,
              so the exclusions set forth above may not apply to Client.
            </li>

            <li>
              GENERAL: With respect to all Clients, this Agreement shall be governed by Georgia law
              and controlling United States federal law, without regard to the choice or conflicts
              of law provisions of any jurisdiction, and any disputes, actions, claims, or causes of
              action arising out of or in connection with this Agreement or BIP shall be subject to
              the exclusive jurisdiction of the state and federal courts located in Georgia. No text
              or information set forth on any other purchase order, preprinted form, or document
              shall add to or vary the terms and conditions of this Agreement. If any provision of
              this Agreement is held by a court of competent jurisdiction to be invalid or
              unenforceable, then such provision(s) shall be construed, as nearly as possible, to
              reflect the intentions of the invalid or unenforceable provision(s), with all other
              provisions remaining in full force and effect. No joint venture, partnership,
              employment, or agency relationship exists between Client and BIP as a result of this
              agreement or use of ClientCare. The failure of BIP to enforce any right or provision
              in this Agreement shall not constitute a waiver of such right or provision unless
              acknowledged and agreed to by BIP in writing. This Agreement comprises the entire
              agreement between Client and BIP and supersedes all prior or contemporaneous
              negotiations, discussions, or agreements, whether written or oral, between the parties
              regarding the subject matter contained herein.
            </li>

            <li>
              PRIVACY: The privacy policy for BIP can be found online at{' '}
              <a href="http://www.bipwealth.com" target="_blank" rel="noreferrer">
                http://www.bipwealth.com
              </a>
              .
            </li>
            <li>
              BIP reserves the right to modify this privacy policy in its reasonable discretion from
              time to time.
            </li>
          </ol>
        </div>
      </Card>
    </>
  );
}

export default Terms;
