import { Col, Empty, Row, Skeleton } from 'antd';
import Card from '@/components/Card';
import ProfileAvatar from '@/assets/images/profile-avatar.png';
import { ITeam } from './interface';

function SkeletonTeams() {
  const SKELETON_COUNT = 4;

  return (
    <>
      {Array.from({ length: SKELETON_COUNT }, (x, i) => (
        <Col key={i} className="gutter-row" span={12} data-testid="team-skeleton-loader">
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Skeleton.Image style={{ marginRight: 10 }} />
            <Skeleton active />
          </div>
        </Col>
      ))}
    </>
  );
}
export interface ITeamProps {
  teams: ITeam[];
  isLoading: boolean;
}
function Teams(props: ITeamProps) {
  const { teams, isLoading } = props;
  return (
    <Card title="MY BIP TEAM">
      <Row gutter={[16, 24]} style={teams?.length === 0 ? { justifyContent: 'center' } : {}}>
        {/* To show loading */}
        {isLoading && <SkeletonTeams />}

        {/* To show Empty message when no data in a list */}
        {!isLoading && teams?.length === 0 && <Empty data-testid="team-empty" />}

        {/* To show Data */}
        {teams?.length > 0 &&
          !isLoading &&
          teams.map((team: ITeam) => {
            const { picture, first_name, last_name, email, phone, title } = team;
            const name = `${first_name} ${last_name}`;
            return (
              <Col xs={24} sm={24} md={12} lg={12} key={email} data-testid="team-data">
                <Row gutter={[16, 24]}>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    <img
                      alt={name}
                      height={200}
                      src={picture || ProfileAvatar}
                      style={{ background: '#ccc', width: 200 }}
                    />
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12}>
                    {name && (
                      <h4 className="bold uppercase" data-testid="team-name">
                        {name}
                      </h4>
                    )}
                    {title && <p data-testid="team-title">{title}</p>}
                    {email && (
                      <p>
                        <a href={`mailto:${email}`} data-testid="team-email">
                          {email}
                        </a>
                      </p>
                    )}
                    {phone && <p data-testid="team-phone">{phone}</p>}
                  </Col>
                </Row>
              </Col>
            );
          })}
      </Row>
    </Card>
  );
}

export default Teams;
