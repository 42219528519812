import { getApiErrorMessage } from '@/utils/helper';
import customNotification from '@/utils/notification';
import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../utils/HttpClient';

export const getGoals = createAsyncThunk('goal', async (_, thunkAPI) => {
  try {
    const response = await HttpClient.get('/goals');
    if (response.status === 200) {
      return { data: response.data };
    }
    return { data: [] };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    customNotification({
      type: 'error',
      message: getApiErrorMessage(error) || 'Oops, something went wrong',
    });
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});
