import 'antd/dist/reset.css';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Suspense, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Routes from './routes';
import Loader from './components/Loader';
import CustomHistory from './utils/NavigationHistory/CustomHistory';
import { theme } from './utils/theme';
import { initializePendo } from './utils/pendo';

function App() {
  useEffect(() => {
    initializePendo();
  }, []);
  return (
    <Suspense fallback={<Loader height="80vh" />}>
      <HistoryRouter history={CustomHistory}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme['@primary-color'],
              fontSize: 12,
            },
          }}
        >
          <HelmetProvider>
            <Routes />
          </HelmetProvider>
        </ConfigProvider>
      </HistoryRouter>
    </Suspense>
  );
}

export default App;
