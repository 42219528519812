import { Link } from 'react-router-dom';
import './AuthContainer.css';
import { ReactNode } from 'react';
import moment from 'moment';

function AuthContainer(props: { children: ReactNode }) {
  const { children } = props;

  return (
    <div className="auth-container">
      <div className="auth-container-header">
        <h1>BIP CLIENTCARE</h1>
      </div>
      <div className="auth-container-body">{children}</div>
      <div style={{ backgroundColor: '#55B8B6', minHeight: 10, minWidth: '100%' }} />
      <div style={{ backgroundColor: '#4AA4A2', minHeight: 5, minWidth: '100%' }} />
      <div style={{ backgroundColor: '#1A8583', minHeight: 25, minWidth: '100%' }} />
      <div className="tandc">
        <p>© {moment().year()} BIP Technology</p>
        <Link to="/terms-of-service">Privacy Policy | Terms</Link>
      </div>
    </div>
  );
}
export default AuthContainer;
