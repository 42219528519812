import PieChart from '@/components/Charts/Pie';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function Allocation(props: { isLoading: boolean; allocation: any }) {
  const { isLoading = true, allocation } = props;

  return (
    <PieChart
      isCardLayout={true}
      title="Allocation"
      data={[
        {
          type: 'Domestic',
          value: allocation?.domestic_raw ?? 0,
          tooltipValue: allocation?.domestic_display ?? '0.00%',
        },
        {
          type: 'International',
          value: allocation?.international_raw ?? 0,
          tooltipValue: allocation?.international_display ?? '0.00%',
        },
        {
          type: 'Fixed Income',
          value: allocation?.fixed_income_raw ?? 0,
          tooltipValue: allocation?.fixed_income_display ?? '0.00%',
        },
      ]}
      chartColors={['#71CD9D', '#023522', '#55B9B7']}
      isLoading={isLoading}
      tooltipCurrencyFormat={false}
      showCustomPercent={true}
    />
  );
}

export default Allocation;
