import { notification, Progress } from 'antd';

type NotificationType = {
  type: 'success' | 'info' | 'warning' | 'error' | 'open';
  message: string;
  description?: string;
  key?: string;
  percent?: number;
};

const customNotification = (props: NotificationType) => {
  const { type, message, description } = props;
  const notIncludedMessages = ['Invalid token payload catch', 'Please login to proceed'];
  return (
    typeof message === 'string' &&
    message &&
    !notIncludedMessages.includes(message) &&
    notification[type]({
      message,
      description,
    })
  );
};

export const downloadNotification = (props: NotificationType) => {
  const { type, message, key, percent } = props;
  return notification[type]({
    message,
    description: type === 'open' ? <Progress percent={percent} /> : '',
    key,
  });
};
export default customNotification;
