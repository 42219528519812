/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Input, Layout, Row, Space, Tooltip } from 'antd';

// import backIcon from '../../assets/back.svg';
import { downloadFile } from '@/services/documentService';
import { hasAccessToDocumentOperation } from './DocumentsHelper';
import deleteIcon from '../../assets/delete.svg';
import uploadIcon from '../../assets/upload.svg';
import cutIcon from '../../assets/cut.svg';
import pasteIcon from '../../assets/paste.svg';
import downloadIcon from '../../assets/download.svg';

// import renameIcon from '../../assets/rename.svg';

import { useDocumentState } from './DocumentContext';
import ConfirmationModal from './ConfirmationModal';
import { IFile } from './DocumentInterface';

const { Search } = Input;

const { Header } = Layout;

interface CustomButtonProps {
  icon: string;
  onClick: () => void;
  disabled?: boolean;
  style?: React.CSSProperties;
  name: string;
  visible?: boolean;
  tooltip?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  icon,
  onClick,
  disabled = false,
  style = {},
  name,
  tooltip = '',
}) => (
  <Tooltip title={tooltip}>
    <Button
      style={{ backgroundColor: disabled ? '#f3f3f3' : '#fff', ...style }}
      icon={<img src={icon} style={{ width: 40, height: 20 }} alt="icon" />}
      onClick={onClick}
      disabled={disabled}
    >
      {name}
    </Button>
  </Tooltip>
);

export default function DocumentHeader({
  showModal,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  const {
    currentDirectory,
    filesToMove,
    setFilesToMove,
    selectedFiles,
    searchQuery,
    setSearchQuery,
    // setCurrentDirectory,
    moveFileBetweenDirectories,
    onConfirmDelete,
    isConfirmationModalOpen,
    openConfirmationModal,
    closeConfirmationModal,
    selectedDocumentConfig,
  } = useDocumentState();

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };
  // const handleGoBack = () => {
  //   setCurrentDirectory(`${removeLastPartOfPath(currentDirectory)}/`);
  // };

  const onMoveFileByPasteButton = async () => {
    moveFileBetweenDirectories({
      sourceFileKeys: filesToMove?.map((file: IFile) => file.Key),
      destinationFolderKey: currentDirectory,
    });
  };

  return (
    <Header
      style={{
        background: '#ccc',
        padding: '10px',
      }}
    >
      <Row justify="space-between">
        <Col>
          <Space style={{ marginBottom: 16 }}>
            <CustomButton
              icon={uploadIcon}
              onClick={() => showModal()}
              name="Upload"
              disabled={!hasAccessToDocumentOperation(selectedDocumentConfig, 'upload')}
              tooltip={
                hasAccessToDocumentOperation(selectedDocumentConfig, 'upload') ? '' : 'No access'
              }
            />
            <CustomButton
              icon={deleteIcon}
              onClick={() => {
                openConfirmationModal('deleteFromHeader');
              }}
              name="Delete"
              disabled={
                !hasAccessToDocumentOperation(selectedDocumentConfig, 'delete') ||
                selectedFiles.length === 0
              }
              tooltip={
                // eslint-disable-next-line no-nested-ternary
                !hasAccessToDocumentOperation(selectedDocumentConfig, 'delete')
                  ? 'No access'
                  : selectedFiles.length === 0
                  ? 'Please select file/files'
                  : ''
              }
            />

            <CustomButton
              icon={cutIcon}
              onClick={() => setFilesToMove(selectedFiles)}
              disabled={
                !hasAccessToDocumentOperation(selectedDocumentConfig, 'cut') ||
                selectedFiles.length === 0 ||
                filesToMove?.length > 0
              }
              name="Cut"
              tooltip={(() => {
                if (!hasAccessToDocumentOperation(selectedDocumentConfig, 'delete')) {
                  return 'No access';
                }
                if (selectedFiles.length === 0) {
                  return 'Please select file';
                }
                if (filesToMove?.length > 0) {
                  return 'File already selected to Move';
                }
                return '';
              })()}
            />

            <CustomButton
              icon={pasteIcon}
              onClick={() => {
                onMoveFileByPasteButton();
              }}
              disabled={
                !hasAccessToDocumentOperation(selectedDocumentConfig, 'cut') ||
                filesToMove.length === 0
              }
              name="Paste"
              tooltip={
                // eslint-disable-next-line no-nested-ternary
                !hasAccessToDocumentOperation(selectedDocumentConfig, 'delete')
                  ? 'No access'
                  : filesToMove.length === 0
                  ? 'No File selected to Move'
                  : ''
              }
            />
            <CustomButton
              icon={downloadIcon}
              onClick={() => {
                downloadFile(selectedFiles);
              }}
              disabled={selectedFiles.length === 0}
              name="Download"
              tooltip={selectedFiles.length === 0 ? 'Please select file/files' : ''}
            />

            {/* <Button
              style={{ backgroundColor: selectedFiles.length === 0 ? '#f3f3f3' : '#fff' }}
              icon={
                <img
                  src={renameIcon}
                  style={{
                    width: 40,
                    height: 20,
                  }}
                  alt="back"
                />
              }
              onClick={() => {}}
              disabled={selectedFiles.length === 0}
            >
              Rename
            </Button> */}
          </Space>
        </Col>
        <Col>
          <Search
            key="search-input"
            placeholder="Search files"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchQuery}
            style={{ width: 200, marginTop: 16 }}
            ref={(input) => {
              // Used to focus the input on rerenders
              if (input && searchQuery) {
                input.focus();
              }
            }}
            defaultValue={searchQuery}
          />
        </Col>
      </Row>
      <ConfirmationModal
        id="deleteFromHeader"
        isModalOpen={isConfirmationModalOpen.deleteFromHeader}
        onOk={() =>
          onConfirmDelete(
            selectedFiles?.map((file: IFile) => file.Key),
            'deleteFromHeader'
          )
        }
        onCancel={() => closeConfirmationModal('deleteFromHeader')}
        action="delete"
      />
    </Header>
  );
}
