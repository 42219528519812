import { Button, Layout, Result } from 'antd';
import { useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { AppState } from '@/store';
import SideBar from './Sidebar';
// eslint-disable-next-line import/no-cycle
import MyHeader from './Header';
import useAuth from '../../hooks/useAuth';

const { Content } = Layout;
function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}) {
  const navigate = useNavigate();

  // const handleGoBack = () => {
  //   navigate(-1);
  //   // Timeout is used to call resetBoundary after successful navigation
  //   setTimeout(() => resetErrorBoundary(), 100);
  // };

  return (
    <Result
      status="500"
      title=""
      subTitle={`${
        process.env.REACT_APP_ENVIRONMENT === 'production'
          ? 'Sorry, something went wrong.'
          : error?.message
      }`}
      extra={
        <>
          {/* <Button type="primary" onClick={resetErrorBoundary}>
            Try Again
          </Button> */}
          <Button
            type="primary"
            onClick={() => {
              resetErrorBoundary();
              navigate('/');
            }}
          >
            Go Home
          </Button>
        </>
      }
    />
  );
}

function MainLayout() {
  const [collapsed, setCollapsed] = useState(false);
  const { isLoggedIn } = useAuth();
  const selectedHousehold = useSelector((state: AppState) => state.common.selectedHousehold);
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <Layout>
      <SideBar collapsed={collapsed} />
      <Layout className="site-layout" style={{ minHeight: '100vh' }}>
        <MyHeader collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          className="site-layout-background"
          style={{
            margin: '40px 16px 100px 216px',
            marginLeft: !collapsed ? 210 : 100,
            padding: 16,
            // minHeight: 2000,
          }}
        >
          {/* {children} */}
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {/* Key is used to make child routes force rerender when household gets changed in Header */}
            <Outlet key={selectedHousehold} />
          </ErrorBoundary>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
