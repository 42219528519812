import { useEffect, useState } from 'react';
import { uploadFile } from '@/services/documentService';
import { Modal, Spin, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { UploadFile } from 'antd/lib';
import { useDocumentState } from './DocumentContext';

const { Dragger } = Upload;

export default function FileUpload({
  isModalOpen,
  handleCancel,
}: {
  isModalOpen: boolean;
  handleCancel: () => void;
}) {
  const { currentDirectory, fetchFilesAndFolder, selectedDocumentConfig } = useDocumentState();

  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const beforeUpload = (file: UploadFile, fileList: UploadFile[]) => {
    setUploadFiles(fileList);
    return false;
  };
  const handleUploadFile = async () => {
    setIsLoading(true);
    const res = await uploadFile(uploadFiles, currentDirectory);
    if (res) {
      handleCancel();
      fetchFilesAndFolder(currentDirectory);
    }
    setUploadFiles([]);
    setIsLoading(false);
  };

  useEffect(() => {
    if (uploadFiles.length > 0) {
      handleUploadFile();
    }
  }, [uploadFiles]);

  return (
    <Modal
      title="Upload File"
      open={isModalOpen}
      footer={null}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      onCancel={handleCancel}
      maskClosable={false}
    >
      <Spin tip="Loading..." spinning={isLoading}>
        <Dragger
          beforeUpload={beforeUpload}
          multiple={true}
          fileList={uploadFiles}
          accept={selectedDocumentConfig?.fileTypes
            ?.map((extension: string) => `.${extension}`)
            .join(',')}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click or drag file to this area to upload</p>
          {/* <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibited from uploading company data or
            other banned files.
          </p> */}
        </Dragger>
      </Spin>
    </Modal>
  );
}
