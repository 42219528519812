import { useState } from 'react';

export const useTwoFa = (initialPhone: string) => {
  const [twoFaState, setTwoFaState] = useState({
    selectedTwoFaMethod: '',
    qrData: { qrUrl: '', secret: '' },
    isLoading: false,
  });

  const [phoneState, setPhoneState] = useState({
    phoneNumber: initialPhone,
    isChangePhoneNumber: false,
  });

  const handleTwoFaMethodChange = (value: string) => {
    setTwoFaState((prevState) => ({ ...prevState, selectedTwoFaMethod: value }));
  };
  const handleQRData = (value: { qrUrl: string; secret: string }) => {
    setTwoFaState((prevState) => ({ ...prevState, qrData: value }));
  };

  const handleChangePhoneNumber = (value: string) => {
    setPhoneState((prevState) => ({ ...prevState, phoneNumber: value }));
  };

  const handleIsChangePhoneNumber = (value: boolean) => {
    setPhoneState((prevState) => ({ ...prevState, isChangePhoneNumber: value }));
  };

  const handle2FaIsLoading = (value: boolean) => {
    setTwoFaState((prevState) => ({ ...prevState, isLoading: value }));
  };

  return {
    twoFaState,
    phoneState,
    handleTwoFaMethodChange,
    handleChangePhoneNumber,
    handleIsChangePhoneNumber,
    handleQRData,
    handle2FaIsLoading,
  };
};
