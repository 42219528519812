// import { useEffect, useState } from 'react';

import { getValueLocalStorage } from '../utils/helper';

function useAuth() {
  //   const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isLoggedInLocal = getValueLocalStorage('isLoggedIn');
  //   useEffect(() => {
  //     setIsLoggedIn(isLoggedInLocal);
  //   }, [isLoggedInLocal]);

  return { isLoggedIn: isLoggedInLocal };
}

export default useAuth;
