/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice } from '@reduxjs/toolkit';
import { getTeam } from '../../services/teamService';

const initialState = {
  // ==============================|| Dashboard ||============================== //
  isGetTeamLoading: false,
  isGetTeamError: false,
  getTeamErrorMessage: '',
  team: [],
  professionals: [],
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    // ==============================|| TEAM ||============================== //
    clearGetTeamErrorState: (state: any) => ({
      ...state,
      isGetTeamError: false,
      getTeamErrorMessage: '',
    }),
  },
  extraReducers: (builder: any) => {
    builder
      // ==============================|| TEAM Dashboard ||============================== //
      .addCase(getTeam.pending, (state: any) => ({
        ...state,
        isGetTeamLoading: true,
        isGetTeamError: false,
        getTeamErrorMessage: '',
      }))
      .addCase(getTeam.fulfilled, (state: any, action: any) => ({
        ...state,
        isGetTeamLoading: false,
        team: action.payload?.data?.team || [],
        professionals: action.payload?.data?.professional || [],
      }))
      .addCase(getTeam.rejected, (state: any, action: any) => ({
        ...state,
        isGetTeamError: true,
        isGetTeamLoading: false,
        getTeamErrorMessage: action.payload,
      }));
  },
});
export const { clearGetTeamErrorState } = teamSlice.actions;

export default teamSlice.reducer;
