import { Col, Row, Space } from 'antd';
import CustomBreadcrumb from '@/components/Breadcrumb';
import { BarChartOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { AppDispatch, AppState } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import { getGoals } from '@/services/goalService';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { featureFlag } from '@/utils/featureFlags';
import GrowthOfWealth from './GrowthOfWealth';
import Milestones from './Milestones';
import FinancialGoals from './FinancialGoals';
import { IGoalsPageData } from './interface';

function Goals() {
  const { goalData, isGetGoalLoading } = useSelector((state: AppState) => state.goals);
  const { goals, historicalValues, milestones }: IGoalsPageData = goalData;
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(getGoals());
  }, [dispatch]);
  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <CustomHelmet title="Goals / Milestones" />

      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: (
              <>
                <BarChartOutlined />
                <span style={{ marginLeft: 10 }}>Goals and Milestones</span>
              </>
            ),
          },
        ]}
      />
      <Row gutter={8}>
        <Col span={18}>
          {featureFlag['component:growth_of_wealth'] && (
            <GrowthOfWealth historicalValues={historicalValues} isLoading={isGetGoalLoading} />
          )}
          <div style={featureFlag['component:growth_of_wealth'] ? { marginTop: 20 } : undefined}>
            <Milestones data={milestones} isLoading={isGetGoalLoading} />
          </div>
        </Col>
        <Col span={6}>
          {/* <div
            style={{ border: '1px solid #ccc', background: theme['@primary-color'], color: '#fff' }}
          >
            Financial Planning Goals
          </div> */}
          <FinancialGoals data={goals} isLoading={isGetGoalLoading} />
        </Col>
      </Row>
    </Space>
  );
}

export default Goals;
