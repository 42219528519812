import { Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AppDispatch, AppState } from '@/store';
import Professionals from '@/views/teams/professionals';
import Teams from '@/views/teams/teams';
import { getTeam } from '@/services/teamService';
import { TeamOutlined } from '@ant-design/icons';
import CustomBreadcrumb from '@/components/Breadcrumb';
import CustomHelmet from '@/components/Helmet/CustomHelmet';

function TeamAndProfessional() {
  const dispatch = useDispatch<AppDispatch>();

  const {
    team: teams,
    professionals,
    isGetTeamLoading,
  } = useSelector((state: AppState) => state.team);

  useEffect(() => {
    dispatch(getTeam());
  }, [dispatch]);

  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      <CustomHelmet title="Teams" />
      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: (
              <>
                <TeamOutlined />
                <span style={{ marginLeft: 10 }}>Teams</span>
              </>
            ),
          },
        ]}
      />
      <Teams teams={teams} isLoading={isGetTeamLoading} />
      <Professionals professionals={professionals} isLoading={isGetTeamLoading} />
    </Space>
  );
}

export default TeamAndProfessional;
