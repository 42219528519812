import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiErrorMessage, getValueLocalStorage } from '../utils/helper';
import HttpClient from '../utils/HttpClient';

// ==============================|| Common Service ||============================== //

export const getTwoFaPreferences = createAsyncThunk('2fa-preferences', async (_, thunkAPI) => {
  try {
    const response = await HttpClient.get('/2fa-preferences');
    return { data: response.data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});

export const getHouseholdList = createAsyncThunk('householdList', async (_, thunkAPI) => {
  try {
    const pwaId = getValueLocalStorage('pwaId');
    const response = await HttpClient.get(`/household/list/${pwaId}`);
    return { data: response.data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});

const commonService = {
  getTwoFaPreferences,
  getHouseholdList,
};
export default commonService;
