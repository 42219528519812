import { Button, Select, Space } from 'antd';
import { useState, useEffect } from 'react';
import Card from '@/components/Card';
import AreaChart, { IAreaChartProps } from '@/components/Charts/Area';
// import { historicalValues } from '@/components/Charts/Area/data';
import moment from 'moment';
import { theme } from '@/utils/theme';
import { IGrowthOfWealth, IHistoricalValue } from './interface';

function GrowthOfWealth(props: IGrowthOfWealth) {
  const { historicalValues, isLoading } = props;

  const { Option } = Select;
  const statusObj = {
    growthOfWealth: 'Growth of Wealth',
    assetClassGrowth: 'Asset Class Growth Of Wealth',
  };
  const [selectedStatus, setSelectedStatus] = useState(statusObj.growthOfWealth);
  const [chartData, setChartData] = useState<{
    growthOfWealth: IAreaChartProps[];
    assetClassGrowth: IAreaChartProps[];
  }>({
    growthOfWealth: [],
    assetClassGrowth: [],
  });
  const [historicalValuesState, setHistoricalValuesState] =
    useState<IHistoricalValue[]>(historicalValues);
  const [selectedPeriod, setSelectedPeriod] = useState('10 year');
  const [xAxisLabelType, setXAxisLabelType] = useState('year');

  const handleChange = (value: string) => {
    setSelectedStatus(value);
  };

  useEffect(() => {
    setHistoricalValuesState(
      [...historicalValues]?.sort((a, b) => moment(a?.date).valueOf() - moment(b?.date).valueOf())
    );
  }, [historicalValues]);

  useEffect(() => {
    const isConcentratedStock = historicalValuesState.some(
      (item) => item.assets.concentrated_stock !== 0
    );

    const convertedData = {
      growthOfWealth: historicalValuesState
        // .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
        ?.map((item) => ({
          date: item.date,
          value: item.total_market_value,
        })),
      assetClassGrowth: historicalValuesState
        // .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
        ?.flatMap(
          (item) =>
            [
              {
                name: 'Public Equities & Fixed Income',
                date: item.date,
                value: Number(item.assets.traditional),
              },
              {
                name: 'BIP Hedged Equity',
                date: item.date,
                value: Number(item.assets.brs),
              },
              {
                name: 'BIP Hedged Yield',
                date: item.date,
                value: Number(item.assets.hy),
              },
              {
                name: 'Private Investments',
                date: item.date,
                value: Number(item.assets.private),
              },
              isConcentratedStock && {
                name: 'Concentrated Stock',
                date: item.date,
                value: Number(item.assets.concentrated_stock),
              },
              {
                name: 'Short-Term Tactical',
                date: item.date,
                value: Number(item.assets.short_term_tactical),
              },
            ] as IAreaChartProps[]
        ),
    };
    setChartData(convertedData);
  }, [historicalValuesState]);

  const filterDataByPeriod = (period: number, unit: moment.unitOfTime.DurationConstructor) => {
    const data = [...historicalValues]
      ?.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
      ?.filter((item) => moment(item.date).isAfter(moment().subtract(period, unit)));

    setHistoricalValuesState(data);
  };

  const handlePeriodData = (period: string) => {
    const [duration, unit] = period.split(' ');

    setSelectedPeriod(period);
    if (period === '3 months' || period === '1 year') {
      setXAxisLabelType('months');
    } else {
      setXAxisLabelType('year');
    }
    filterDataByPeriod(Number(duration), unit as moment.unitOfTime.DurationConstructor);
  };

  return (
    <Card
      title={selectedStatus}
      renderToolbar={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ marginRight: 10 }}>Choose</p>
          <Select
            date-testid="status"
            value={selectedStatus}
            style={{ width: 240 }}
            onChange={handleChange}
          >
            {Object.values(statusObj).map((status) => (
              <Option key={status} value={status}>
                {status}
              </Option>
            ))}
          </Select>
        </div>
      }
      isLoading={isLoading}
    >
      {selectedStatus === 'Asset Class Growth Of Wealth' ? (
        <AreaChart
          data={chartData.assetClassGrowth}
          xAxisLabelType={xAxisLabelType}
          isStacked={true}
        />
      ) : (
        <AreaChart data={chartData.growthOfWealth} xAxisLabelType={xAxisLabelType} />
      )}
      <Space direction="horizontal">
        {[
          { period: '3 months', label: '3 Month' },
          { period: '1 year', label: '1 Year' },
          { period: '3 year', label: '3 Year' },
          { period: '5 year', label: '5 Year' },
          { period: '10 year', label: '10 Year' },
          // { period: 'Max', label: 'Max' },
        ].map(({ period, label }) => (
          <Button
            key={period}
            type="primary"
            style={{
              marginTop: 20,
              background: selectedPeriod === period ? theme['@primary-color'] : '#ccc',
            }}
            onClick={() => {
              if (period !== 'Max') {
                handlePeriodData(period);
              }
            }}
          >
            {label}
          </Button>
        ))}
      </Space>
    </Card>
  );
}

export default GrowthOfWealth;
