import CustomBreadcrumb from '@/components/Breadcrumb';
import Card from '@/components/Card';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { BookOutlined } from '@ant-design/icons';

function Disclosures() {
  return (
    <>
      <CustomHelmet title="Disclosures" />

      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: (
              <>
                <BookOutlined />
                <span style={{ marginLeft: 10 }}>Disclosures</span>
              </>
            ),
          },
        ]}
      />
      <Card title="IMPORTANT DISCLOSURES">
        <div>
          <p>
            Founded in 2007, Buckhead Investment Partners, LLC (“BIP”, “we”, or “us”) is an
            independent investment management firm registered with the U.S. Securities and Exchange
            Commission (“SEC”). SEC registration does not imply any particular training or
            competence. BIP’s Form ADV and Firm Brochure are available for review by visiting the
            SEC’s Investment Adviser Public Disclosure website (available at:
            http://www.adviserinfo.sec.gov/IAPD), clicking “Firm” in the navigation bar and entering
            “Buckhead Investment Partners” in the “Firm Name” box. These disclosures apply to this
            report and any oral or written comments of any BIP personnel presenting this report. All
            information within this report, including, without limitation, any information regarding
            performance results, must be considered in conjunction with all applicable disclosures
            set forth herein. You should review the account balances in this report and compare them
            with the statements that you receive directly from your custodian. You should notify us
            promptly if you do not receive statements from your custodian at least quarterly. You
            should contact your BIP Personal Wealth Advisor if: (a) there have been any changes in
            your financial situation or investment objectives; or (b) you wish to modify existing
            restrictions or impose new restrictions on the management of your account. Past returns
            are no guarantee of future results. Investment losses during some periods are a normal
            part of investing and should be expected. All investments are subject to investment
            risk, including the possible loss of the entire amount invested. There can be no
            assurance that any investment objective will be achieved, and results may vary
            substantially over time. We encourage you to work with your BIP Personal Wealth Advisor
            to review the portfolio risk level that is most appropriate for you. Neither the
            information, nor any opinion expressed or implied in this report, constitutes a
            solicitation by BIP for the purchase or sale of any securities. No information contained
            in this report should be construed as tax advice and you should consult a tax
            professional regarding your specific tax situation.
          </p>

          <h4 style={{ fontWeight: 'bold', marginTop: 10 }}>
            DISCLOSURES CONCERNING PUBLIC MARKET INVESTMENTS
          </h4>

          <p>
            Portfolio performance for public market investments is calculated on a time-weighted
            return basis using account balances on the last day of each period. We report annualized
            returns, except for the three-month, one year and year-to-date periods. Adjustments are
            made for deposits and withdrawals so that the reported performance more accurately
            reflects the investment performance of the balance invested during each period. Except
            as otherwise noted, return data presented include reinvestment of dividends and income,
            and are net of all transaction costs and BIP advisory fees. Portfolio performance
            calculations for public market investments employ a methodology designed to report the
            information needed by investors to compare their performance with widely available
            indexes of public markets. The indexes have been selected because BIP believes they
            provide some reference to evaluate performance against a broader market. A description
            of each index referenced herein has been included below. Comparisons against indexes are
            provided for informational purposes only and should not be used as the basis for making
            any investment decision. There may be significant differences between your portfolio and
            any indexes referenced herein, including, but not limited to, risk profile, liquidity,
            volatility and asset comparison. Accordingly, comparison against such indexes may be of
            limited use. It is not possible to invest directly in the indexes referenced herein or
            in any other index. The Blended Benchmarks are weighted averages of two component
            indexes that BIP believes approximate the risk of investment portfolios with equivalent
            equity percentages. The Global Equity index (defined below) was selected as an unbiased
            allocation to global stocks based on market capitalization. The U.S. Fixed Income index
            (defined below) was selected as an unbiased allocation to dollar-denominated investment
            grade taxable bonds based on commonly available investments in the United States. The
            Blended Benchmarks may differ from your portfolio allocation for a variety of reasons,
            including decisions made by BIP that are intended to improve your portfolio performance
            or lower your risk.
          </p>

          <ul>
            <li>
              The “Global Equity index” is the MSCI ACWI IMI Index, which is a free float-adjusted
              market capitalization weighted global index. This index was selected by BIP because
              BIP believes it is the best available proxy for a diversified stock portfolio
              consistent with modern portfolio theory. Approximately 45% of the index is comprised
              of the U.S. stock market and 55% is comprised of international stock markets,
              including both developed and emerging countries. The “Gross Daily Total Return”
              version of the index is reported herein, which means that dividends are included and
              reinvested, and foreign taxes are not withheld.
            </li>
            <li>
              The “U.S. Fixed Income index” is the Bloomberg Barclays Capital U.S. Aggregate Bond
              Index, which is a broad-based benchmark. This index was selected by BIP because BIP
              believes is the best available proxy for a high quality, diversified fixed income
              portfolio suitable for a U.S. investor. It is comprised of the Bloomberg Barclays
              Capital U.S. Government/Credit Bond Index, the Mortgage-Backed Securities Indices, and
              the Asset-Backed Securities Index. It is an unmanaged market value-weighted
              performance benchmark for investment grade fixed-rate debt issues, with maturities of
              at least one year, and an outstanding par value of at least $100 million.
            </li>{' '}
            The “Total Return” version of the index is reported herein, which means that interest is
            included and reinvested.
            <li>
              The Citigroup Treasury Bill 3-Month Index measures monthly return equivalents of yield
              averages that are not marked to market. It consists of the last three three-month
              Treasury bill issues.
            </li>
            <li>
              The S&P Municipal Bond Index is a broad-based benchmark that covers the U.S.
              dollar-denominated long-term tax exempt bond market. The index has four main sectors:
              state and local general obligation bonds, insured bonds, and pre-refunded bonds. The
              “Total Return” version of the index is reported herein, which means that interest is
              included and reinvested.
            </li>
            <li>
              The S&P 500 Index is a market capitalization weighted index that consists of 500
              widely traded stocks chosen for market size, liquidity, and industry group
              representation. The “Total Return” version of the index is reported herein, which
              means that dividends are included and reinvested and there is no withholding tax
              applied to reduce returns.
            </li>
            <li>
              The Russell 2500 Index is a free float-adjusted market capitalization weighted index
              intended to represent the smallest 2500 of the top 3000 U.S. companies. The “Total
              Return” version of the index is reported herein, which means that dividends are
              included and reinvested.
            </li>
            <li>
              The MSCI EAFE Index is a free float-adjusted market capitalization weighted index that
              is designed to measure the equity market performance of international developed
              markets, excluding the U.S. and Canada. EAFE refers to Europe, Australasia, and Far
              East and includes 21 individual countries that collectively represent many of the
              major markets of the world. The “Gross Return” version of the index is reported
              herein, which means that dividends are included and reinvested and there is no
              withholding tax applied to reduce returns.
            </li>
            <li>
              The MSCI Emerging Markets Index is a free float-adjusted market capitalization
              weighted index that is designed to measure the equity market performance of
              international emerging markets. It includes 21 individual countries that collectively
              represent many of the developing markets of the world. The “Gross Return” version of
              the index is reported herein, which means that dividends are included and reinvested
              and there is no withholding tax applied to reduce returns.
            </li>
          </ul>
          <p>
            We have obtained index results from third parties and believe the information to be
            reliable and accurate; however, this information is neither guaranteed nor warranted by
            BIP or the index providers and is subject to revision without notice. The “Table of
            Historical Risk and Return Based on Target Percentage in Equities” represents past
            performance of the current strategy for portfolios labeled “balanced”. It is back-tested
            data using asset class returns represented by market indexes without any fees, and is
            not the actual performance of portfolios. The equity target percentage is a weighted
            average between a “balanced” equity allocation and a “balanced” fixed income calculation
            using taxable (not municipal) bonds. Portfolios labeled “accumulation” or “distribution”
            may have minor differences in the allocation of the fixed income portion of the
            portfolio due to different cash balances. The equity allocation of the portfolios are
            constituted by the following indexes, which total 100% of the equity portion: 16% U.S.
            Large Value: S&P 500 Value Total Return (1995-2011), S&P 500 Composite total Return
            (1970-1994). 13% U.S. Large Growth: S&P 500 Growth Total Return (1995-2011), S&P 500
            Composite total Return (1970-1994). 16% U.S. Small Value: Russell 2000 Value
            (1979-2011), DFA Small Cap (1970-1978). 10% U.S. Small Growth: Russell 2000 Growth
            (1979-2011), DFA Small Cap (1970-1978). 8% International Developed Large Value: MSCI
            EAFE (1970-2011). 7% International Developed Large Growth: MSCI EAFE (1970-2011). 8%
            International Developed Small Value: MSCI EAFE (1970-2011). 5% International Developed
            Small Growth: MSCI EAFE (1970-2011). 7% International Emerging Markets: MSCI Emerging
            Markets (1988-2011), IFC Global Emerging Markets Index (1976-1987), MSCI EAFE
            (1970-1975). 10% Real Assets: S&P GSCI (Commodity) Index (1970-2011). The fixed income
            allocation of the portfolios are constituted by the following indexes, which total 100%
            of the fixed income portion: 5% Cash: Ibbotson U.S. Treasury Bills-Total Return
            (1926-Current). 5% Treasurys/GNMAs,CDs: Bloomberg Barclays Capital Intermediate
            Government Bonds (1973-Current). 2% TIPS: Bloomberg Barclays Capital U.S. TIPs Index
            (1997-Current), CPI-U (1970-1996). 20% Multi-Sector/Total Return: Bloomberg Barclays
            Capital Intermediate Government/Credit Index (1973-Current). 8% Foreign: Citigroup
            Non-U.S. World Government Bonds U.S. $(Citigroup Non-US. World Government Bonds U.S. $
            (1985-2011), Ibbotson Intermediate-Term Government Bonds Total Return (1970-1984). 60%
            U.S. Corporate/Agency/Muni: Bloomberg Barclays Capital Intermediate Government/Credit
            Index (1973-Current).
          </p>
          <h4 style={{ fontWeight: 'bold', marginTop: 10 }}>
            DISCLOSURES CONCERNING PRIVATE MARKET INVESTMENTS
          </h4>

          <p>
            <i>
              (Please refer to the Glossary of Terms below for certain terms used in this report and
              the Disclosures that follow.)
            </i>
          </p>
          <p>
            Estimated Fair Value. Because private market investments such as private equity and
            private debt are not readily marketable, their Estimated Fair Value is subject to
            uncertainty and therefore may differ from the value that would have been used had a
            ready market for such investments existed. Such difference could be material.
          </p>
          <p>
            Private market investments described herein for which market price quotations are not
            available or which are not readily marketable are stated at Estimated Fair Value, as
            determined in good faith, exercising reasonable business judgment, by BIP Capital or
            other non-BIP Capital entity(ies) or person(s) responsible for managing such private
            market investments. Estimated Fair Value amounts reported herein represent the
            investment value as of the last day of the reporting period, adjusted for any capital
            contributions or distributions made as of such date. The Estimated Fair Value of private
            market investments is derived using a market approach which utilizes prices and other
            relevant information generated by market transactions, including the type of security,
            size of the position, degree of liquidity, restrictions on disposition, data from the
            latest round of financing, and current financial position and operating results, among
            other factors. Estimated Fair Value does not represent an offer to purchase your
            investment, nor does it represent the value of an investor’s individual capital account
            (which may include fees and operating costs not reflected in the Estimated Fair Value).
            Although believed to be accurate, neither BIP nor any of its members, officers,
            employees, affiliates or subsidiaries make any warranties regarding the Estimated Fair
            Value of private market investments described herein. The financial books and records of
            each private market investing entity are audited annually by an independent CPA firm.
            The Estimated Fair Values shown in this report are unaudited estimates of your pro rata
            share of the aggregate fair value of the investing entities in which you have invested
            and are therefore subject to adjustment based upon the results of annual audits
            conducted by an independent CPA firm.
          </p>
          <p>
            Performance Calculations. Except as otherwise noted in this report, portfolio
            performance for private market investments is calculated at the investing entity level
            (i.e., the investment fund or other investment vehicle level) on a time-weighted return
            basis using Estimated Fair Value on the last day of each period. Adjustments are made
            for capital contributions and distributions so that the reported performance more
            accurately reflects the investment performance of the balance invested during each
            period. The time-weighted return for funds are calculated using the final close date as
            the inception date.
          </p>

          <h4 style={{ fontWeight: 'bold', marginTop: 10 }}>Glossary of Terms</h4>

          <ul>
            <li>
              DPI (Distributions to Paid-in Capital) — The ratio obtained by dividing Since
              Inception Distributions you have received by Since Inception Paid-in Capital that you
              have invested.
            </li>
            <li>
              TVPI (Total Value to Paid-in Capital) — The ratio obtained by dividing Total Value by
              Since Inception Paid-in Capital.
            </li>
            <li>
              GROSS IRR (Internal Rate of Return) — The implied discount rate or effective
              compounded rate of return that equates the present value of cash outflows
              (distributions) from a fund or other investment vehicle with the present value of cash
              inflows (capital contributions) to the fund or other investment vehicle. The Since
              Inception Internal Rate of Return is a specific version of the IRR where the
              measurement period covers the entire investment period since inception.{' '}
            </li>
            <li>
              NET IRR (Internal Rate of Return) — Gross IRR, net of management fees and the carried
              interest, if any, of the general partner or manager (or their affiliates) of the fund
              or other investment vehicle.
            </li>
            <li>
              EFV (Estimated Fair Value) — The best estimate of the amount at which your investment
              in a fund or other investment vehicle could be exchanged in a current arm’s length
              transaction between willing parties in which the parties each act knowledgeably and
              prudently.
            </li>
            <li>
              Since Inception Distributions — The total amount of cash distributions that you have
              received from a fund or other investment vehicle.
            </li>
            <li>
              Since Inception Paid-in Capital — The total amount of cash that you have invested in a
              fund or other investment vehicle.
            </li>
            <li>
              Total Value — An amount equal to the remaining value that you have in a fund or other
              investment vehicle at the end of the performance reporting period plus all
              distributions cash distributed to you from the fund or investment vehicle.
            </li>
          </ul>
        </div>
      </Card>
    </>
  );
}

export default Disclosures;
