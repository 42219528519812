import NewlineText from '@/components/NewLine';
import { IPreference } from '@/store/auth/authInterface';
import { Space, Typography, Select, Input, Button } from 'antd';

const { Paragraph, Title } = Typography;
interface IChoose2FAMethod {
  selectedTwoFaMethod: string;
  handleTwoFaMethodChange: (value: string) => void;
  phoneNumber: string;
  isChangePhoneNumber: boolean;
  handleChangePhoneNumber: (value: string) => void;
  handleIsChangePhoneNumber: (value: boolean) => void;
  twoFaMethods: IPreference[];
}
interface IDescription {
  name: string;
  requirement: string;
  downloadInstructions?: string;
}
interface IDescriptions {
  [key: string]: IDescription[];
}
function Choose2FaMethod(props: IChoose2FAMethod) {
  const {
    selectedTwoFaMethod,
    handleTwoFaMethodChange,
    phoneNumber,
    isChangePhoneNumber,
    handleChangePhoneNumber,
    handleIsChangePhoneNumber,
    twoFaMethods = [],
  } = props;

  const descriptions: IDescriptions = {
    TOTP: [
      {
        name: 'Authenticator App',
        requirement: `You must have an authenticator app installed on your phone in order to enroll in TOTP two-factor authentication. We support this method of authentication for both iOS and Android devices. \n
        Our recommended authenticator apps are Authy, Google Authenticator and 1Password. \n
        Please reference the App Store or Google Play page of your preferred authentication app for minimum software version requirements. As a general guideline, we always recommend maintaining the most up-to-date version of your phone’s operating system to ensure the most recent security patches are in place.\n
        Once you have your authenticator app installed and set up, please click ‘Next Step’ to enroll your device.`,
      },
    ],
    SMS: [
      {
        name: 'Text',
        requirement: `To use our SMS-based authentication, you must have a phone that is able to receive text messages. Only U.S.-based phone numbers are supported. Please click the ‘Next Step’ button to enroll the phone number listed above.`,
      },
    ],
  };

  return (
    <>
      <Title level={3}>Choose Authentication Method</Title>
      <Paragraph>
        Please use the dropdown below to select your preferred authentication method.
      </Paragraph>
      <Space direction="vertical">
        <Select
          value={selectedTwoFaMethod}
          defaultValue=""
          style={{ width: 200, marginBottom: 10 }}
          onChange={handleTwoFaMethodChange}
          options={[
            { value: '', label: 'Please Choose One' },
            ...twoFaMethods.map((TwoFaMethod: IPreference) => ({
              value: TwoFaMethod.preference,
              label: TwoFaMethod.name,
            })),
          ]}
        />
        {selectedTwoFaMethod === 'SMS' && (
          <>
            <Space>
              <Typography.Title level={4} style={{ margin: 0 }}>
                +1
              </Typography.Title>
              <Input
                placeholder="Phone Number"
                value={phoneNumber.startsWith('+1') ? phoneNumber.slice(2) : phoneNumber}
                onChange={(e) => {
                  const validatedValue = e.target.value.replace(/[^0-9]/g, '');
                  handleChangePhoneNumber(validatedValue);
                }}
                disabled={!isChangePhoneNumber}
                maxLength={10}
                type="tel"
              />
              <Button type="primary" onClick={() => handleIsChangePhoneNumber(true)}>
                Change Phone Number
              </Button>
            </Space>
            <p>
              {isChangePhoneNumber && phoneNumber.length < 10 && 'Please enter 10 digit number'}
            </p>
          </>
        )}
      </Space>

      {selectedTwoFaMethod &&
        descriptions[selectedTwoFaMethod].map((description: IDescription) => (
          <Space key={description?.name} direction="vertical">
            <Typography.Title level={4}>{description?.name} Requirements</Typography.Title>
            <NewlineText text={description?.requirement} />
          </Space>
        ))}
    </>
  );
}

export default Choose2FaMethod;
