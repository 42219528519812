import { config } from './config';
import { getValueLocalStorage } from './helper';

/*
  Pendo Installation Guide
  https://support.pendo.io/hc/en-us/articles/360031862272-Install-Pendo-on-a-single-page-web-application#01H6XE6QX8YPJ0QDZGV9WTMGTD

*/

export function initializePendo() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { pendo } = window as any;
  const userId = getValueLocalStorage('userId');
  const email = getValueLocalStorage('email');
  const householdId = getValueLocalStorage('householdId');
  const full_name = getValueLocalStorage('name');
  // Initialize Pendo in the production environment
  if (userId && config.ENVIRONMENT === 'production' && pendo?.initialize) {
    // TODO: Uncomment the code below once API Key is confirmed
    // pendo?.initialize({
    //   visitor: {
    //     id: userId,
    //     email,
    //     full_name,
    //   },
    //   account: {
    //     id: householdId,
    //   },
    // });
  }
}
