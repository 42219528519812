import { Typography } from 'antd';

const { Title, Paragraph } = Typography;
function OverView() {
  return (
    <>
      <Title level={3}>Welcome to Clientcare</Title>
      <Paragraph>
        Welcome to BIP Wealth’s ClientCare portal. We are pleased to provide Two-Factor
        Authentication security measures to help protect your account.
      </Paragraph>
      <Paragraph>
        To begin, please click ‘Next Step’ to select and enroll your desired 2FA method.
      </Paragraph>
    </>
  );
}

export default OverView;
