import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Table from '@/components/Table';
import moment from 'moment';
import { IPortfolioManagerComment, IPortfolioManagerCommentProps } from './interface';

function PortfolioManagerCommnet(props: IPortfolioManagerCommentProps) {
  const { portfolioManagerComments, isLoading } = props;
  const columns: ColumnsType<IPortfolioManagerComment> = [
    {
      title: 'Date',
      dataIndex: 'date_display',
      key: 'date_display',
      sorter: (a, b) => moment(a.date_raw).valueOf() - moment(b.date_raw).valueOf(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
      sorter: (a, b) => a.comment.localeCompare(b.comment),
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <Table
          className="bg-header-white"
          columns={columns}
          dataSource={portfolioManagerComments}
          isLoading={isLoading}
          title="PORTFOLIO MANAGER COMMENTS"
          rowKey={(record) => record.id}
        />
      </Col>
    </Row>
  );
}

export default PortfolioManagerCommnet;
