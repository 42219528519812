/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDashboardData } from '@/views/dashboard/interface';
import { createSlice } from '@reduxjs/toolkit';
import { getDashboard } from '../../services/dashboardService';

export interface IDashboardSlice {
  isGetDashboardLoading: boolean;
  isGetDashboardError: boolean;
  getDashboardErrorMessage: string;
  dashboardData: IDashboardData;
}
const initialState = {
  // ==============================|| Dashboard ||============================== //
  isGetDashboardLoading: false,
  isGetDashboardError: false,
  getDashboardErrorMessage: '',
  team: [],
  professionals: [],
  dashboardData: {
    equityTotal_display: '',
    equityTotal_raw: '',
    fixedTotal_display: '',
    fixedTotal_raw: '',
    grandTotal_display: '',
    grandTotal_raw: '',
    private_pending: '',
    private_pending_household_percentage: '',
    private_pending_household_percentage_display: '',
    private_pending_display: '',
    total_equity_household_percentage_display: '',
    total_household_percentage_display: '',
    total_fixed_income_household_percentage_display: '',
    public_strategy_concentrated_stock_visible: 0,
    private_pending_visible: 0,
    household_name: '',
    household_allocation: [],
    tax_allocation: [],
    liquidity_allocation: [],
  },
} as IDashboardSlice;

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // ==============================|| Dashboard ||============================== //
    clearGetDashboardErrorState: (state) => ({
      ...state,
      isGetDashboardError: false,
      getDashboardErrorMessage: '',
    }),
  },
  extraReducers: (builder) => {
    builder
      // ==============================|| GET Dashboard ||============================== //
      .addCase(getDashboard.pending, (state) => ({
        ...state,
        isGetDashboardLoading: true,
        isGetDashboardError: false,
        getDashboardErrorMessage: '',
      }))
      .addCase(getDashboard.fulfilled, (state, action: any) => ({
        ...state,
        isGetDashboardLoading: false,
        team: action.payload.data.team,
        professionals: action.payload.data.professionals,
        dashboardData: action.payload.data || initialState.dashboardData,
      }))
      .addCase(getDashboard.rejected, (state, action: any) => ({
        ...state,
        isGetDashboardError: true,
        isGetDashboardLoading: false,
        getDashboardErrorMessage: action.payload,
      }));
  },
});
export const { clearGetDashboardErrorState } = dashboardSlice.actions;

export default dashboardSlice.reducer;
