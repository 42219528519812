import customNotification from '@/utils/notification';
import { useDrop } from 'react-dnd';
import { hasAccessToDocumentOperation } from './DocumentsHelper';
import folderIcon from '../../assets/folder.svg';
import { useDocumentState } from './DocumentContext';
import { IDirectoryNode, IFile } from './DocumentInterface';

const FileType = 'FILE';
type DirectoryNode = {
  key: string;
  title: string;
  children: DirectoryNode[];
};

// Folder Component (Droppable), Used in Left side Tree structure
export const Folder: React.FC<{
  folder: DirectoryNode;
  isSelected: boolean;
}> = ({ folder, isSelected }) => {
  const { moveFileBetweenDirectories, documentConfig } = useDocumentState();
  const handleDropFile = async (sourceFileKeys: string[], destinationFolderKey: string) => {
    moveFileBetweenDirectories({ sourceFileKeys, destinationFolderKey });
  };
  const [{ isOver }, drop] = useDrop({
    accept: FileType,
    drop: (dropFiles: IFile[]) => {
      const folderConfig = documentConfig.find((directory: IDirectoryNode) =>
        folder?.key.startsWith(directory.key)
      );
      const haveAccess = hasAccessToDocumentOperation(folderConfig, 'cut');
      if (haveAccess) {
        const files = dropFiles.map((file) => file.Key);
        handleDropFile(files, folder.key);
      } else {
        customNotification({
          type: 'error',
          message: `You don't have permission to move the file to this location`,
        });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  const folderConfig = documentConfig.find((item: IDirectoryNode) =>
    folder?.key.startsWith(item.key)
  );
  const haveAccess = hasAccessToDocumentOperation(folderConfig, 'cut');
  // console.log('folderConfig', folderConfig);
  // console.log('access', hasAccessToDocumentOperation(folderConfig, 'paste'));
  return (
    <div
      ref={drop}
      className={`folder-node ${isSelected ? 'selected' : ''}`}
      style={{
        // eslint-disable-next-line no-nested-ternary
        backgroundColor: isOver ? (haveAccess ? 'lightgreen' : 'red') : '',
      }}
    >
      <img src={folderIcon} alt="Folder" style={{ height: 20, width: 20, marginRight: 8 }} />
      {folder.title}
    </div>
  );
};
