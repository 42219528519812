import { IDirectoryNode, IDocumentConfig, IFile, IFolder } from './DocumentInterface';

/* eslint-disable @typescript-eslint/no-explicit-any */
const downloadFile = (blob: any, fileName: string) => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  link.click();
};

const extractDisplayName = (entry: IFile) => {
  const modifiedEntry = { ...entry }; // Clone the entry to avoid modifying the original object

  if (entry.isDir) {
    const parts = entry?.Key?.split('/');
    modifiedEntry.name = parts[parts.length - 2];
  } else {
    const parts = entry?.Key?.split('/');

    const fullName = parts[parts.length - 1];
    const lastDotIndex = fullName.lastIndexOf('.');

    let name: string;
    let extension: string;
    if (lastDotIndex === -1) {
      // No dot found, so no extension
      name = fullName;
      extension = '';
    } else {
      name = fullName.substring(0, lastDotIndex);
      extension = fullName.substring(lastDotIndex + 1);
    }

    modifiedEntry.name = name;
    modifiedEntry.extension = extension;
  }

  return modifiedEntry;
};

function removeLastPartOfPath(path: string): string {
  const parts = path.split('/');
  const keys = parts.filter((item) => Boolean(item));
  // Restrict user to go beyond their own directory
  if (keys.length > 2) {
    keys.pop();
  }
  return keys.join('/');
}

function buildFolderTree(folders: IFolder[]): IDirectoryNode[] {
  const folderMap: { [key: string]: IDirectoryNode } = {};
  const root: IDirectoryNode[] = [];

  // Step 1: Create a lookup table for each folder
  folders.forEach((folder) => {
    folderMap[folder.Key] = { key: folder.Key, title: folder.name, children: [] };
  });

  // Step 2: Determine parent-child relationships
  folders.forEach((folder) => {
    const parts = folder.Key.split('/').filter(Boolean);
    if (parts.length > 1) {
      const parentKey = `${parts.slice(0, parts.length - 1).join('/')}/`;
      if (folderMap[parentKey]) {
        folderMap[parentKey].children.push(folderMap[folder.Key]);
      } else {
        // If no parent in the map, it's a root-level folder
        root.push(folderMap[folder.Key]);
      }
    } else {
      root.push(folderMap[folder.Key]);
    }
  });

  return root;
}

function findBaseDirectory(key: string, baseDirectories: IDirectoryNode[]): IDirectoryNode | null {
  return (
    baseDirectories
      .filter((baseDirectory) => key?.startsWith(baseDirectory?.key))
      // using reduce method to find exact base directory with longest match e.g (/docs/ and /docs/other tax documents);
      .reduce(
        (longestMatch, current) =>
          longestMatch === null || current?.key?.length > longestMatch?.key?.length
            ? current
            : longestMatch,
        null as IDirectoryNode | null
      )
  );
}

function attachChildrenToBaseDirectories(
  baseDirectories: IDirectoryNode[],
  itemsToAdd: IDirectoryNode[]
): IDirectoryNode[] {
  // Create a copy of the base directories
  const copyBase = baseDirectories.map((dir) => ({
    ...dir,
    children: [...dir.children],
  }));

  // Map base directories by their keys
  const baseDirMap = new Map<string, IDirectoryNode>(copyBase.map((dir) => [dir.key, dir]));
  // Attach folder tree to base directories
  itemsToAdd.forEach((folder) => {
    baseDirMap.forEach((baseDir, baseDirKey) => {
      if (folder.key.startsWith(baseDirKey)) {
        const updatedBaseDir: IDirectoryNode = { ...baseDir };
        updatedBaseDir.children.push(folder);
        baseDirMap.set(baseDirKey, updatedBaseDir);
      }
    });
  });

  return Array.from(baseDirMap.values());
}

function generateUniqueFileName(existingFiles: IFile[], fileName: string): string {
  const nameParts = fileName?.split('.');
  let fileNameWithoutExtension = nameParts?.[0];
  const extension = nameParts.length > 1 ? `.${nameParts[nameParts.length - 1]}` : '';
  // Regex to detect if the base name already has 'copy' with a number
  const copyRegex = /(.*)( copy (\d+))$/;
  const match = fileNameWithoutExtension?.match(copyRegex);

  let counter = 1;

  if (match) {
    [, fileNameWithoutExtension] = match;
    counter = parseInt(match[3], 10) + 1;
  }

  let newFileName = fileName;
  const fileNamesSet = new Set(
    existingFiles?.map((file: IFile) => `${file?.name}.${file?.extension}`)
  );

  while (fileNamesSet.has(newFileName)) {
    newFileName = `${fileNameWithoutExtension} copy ${counter}${extension}`;
    counter += 1;
  }

  return newFileName;
}

function filterItemsInDirectory(files: IFile[], directory: string): IFile[] {
  return files
    .filter((file) => file.Key.startsWith(directory))
    .map((file) => {
      const relativePath = file.Key.replace(directory, '');
      const relativePathParts = relativePath.split('/').filter(Boolean);
      if (relativePathParts.length === 1) {
        return file;
      }

      return null;
    })
    .filter(Boolean) as IFile[];
}

const getAllKeys = (data: IDirectoryNode[]): string[] =>
  data.reduce<string[]>((keys, node) => {
    keys.push(node.key);
    if (node.children) {
      keys.push(...getAllKeys(node.children));
    }
    return keys;
  }, []);

const getFileNameFromKey = (
  fileKey: string
): { fileNameWithExtension: string; extension: string } => {
  const parts = fileKey.split('/').filter((item: any) => Boolean(item));
  const fileNameWithExtension = parts?.pop() as string;
  const extension = fileNameWithExtension?.split('.')?.pop() as string;
  return { fileNameWithExtension, extension };
};

const formatTheResponse = (data: any) => {
  const result = data?.files
    .map((item: any) => ({
      ...item,
      isDir: item.Key.endsWith('/'),
    }))
    .map(extractDisplayName);
  return result;
};

const getFoldersWithSubfolders = (folders: string[]): string[] => {
  // Create a set of parent folders
  const parentFolders = new Set<string>();

  folders.forEach((folder) => {
    const parts = folder.split('/').filter(Boolean);
    if (parts.length > 1) {
      const parentFolder = `${parts.slice(0, parts.length - 1).join('/')}/`;
      parentFolders.add(parentFolder);
    }
  });

  // Filter the original list to include only folders that are in the parent set
  const foldersWithSubfolders = folders.filter((folder) => parentFolders.has(folder));

  return foldersWithSubfolders;
};

const hasAccessToDocumentOperation = (config: IDocumentConfig, control: string) =>
  config?.accessControl?.includes(control);

const getDirectoryNameFromFileKey = (key: string): string =>
  `${`${key.split('/').slice(0, -1).filter(Boolean).join('/')}/`}`;
export {
  downloadFile,
  extractDisplayName,
  removeLastPartOfPath,
  buildFolderTree,
  attachChildrenToBaseDirectories,
  generateUniqueFileName,
  filterItemsInDirectory,
  getAllKeys,
  getFileNameFromKey,
  findBaseDirectory,
  formatTheResponse,
  getFoldersWithSubfolders,
  hasAccessToDocumentOperation,
  getDirectoryNameFromFileKey,
};
