/* eslint-disable @typescript-eslint/no-explicit-any */
import { emptyAsset, privateMarketDetailsEmptyState } from '@/views/private-market/constants';
import { IGpUpdate, IGpUpdateDetails } from '@/views/private-market/gp-updates/interface';
import { IAsset, IPrivateMarketDetailsData } from '@/views/private-market/interface';
import { IK1CheckList } from '@/views/private-market/k1-checklist/interface';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import {
  getGpUpdates,
  getGpUpdatesDetail,
  getK1CheckList,
  getprivateMarket,
  getprivateMarketDetails,
} from '../../services/privateMarketService';

export interface IGpUpdateDetailsReducer {
  data: IGpUpdateDetails;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
}
export interface IPrivateMarketSlice {
  isGetPrivateMarketLoading: boolean;
  isGetPrivateMarketError: boolean;
  getprivateMarketErrorMessage: string;
  equity: IAsset;
  debt: IAsset;
  real_estate: IAsset;
  privateMarketDetails: IPrivateMarketDetailsData;
  isGetPrivateMarketDetailsLoading: boolean;
  isGetPrivateMarketDetailsError: boolean;
  getprivateMarketDetailsErrorMessage: string;
  gpUpdates: {
    data: IGpUpdate[];
    isGetGpUpdatesLoading: boolean;
    isGetGpUpdatesError: boolean;
    getGpUpdatesErrorMessage: string;
  };
  gpUpdateDetails: IGpUpdateDetailsReducer;
  k1CheckList: {
    data: IK1CheckList;
    isLoading: boolean;
    isError: boolean;
    errorMessage: string;
  };
}

const initialState: IPrivateMarketSlice = {
  // ==============================|| private Market ||============================== //
  isGetPrivateMarketLoading: false,
  isGetPrivateMarketError: false,
  getprivateMarketErrorMessage: '',
  equity: { ...emptyAsset, bdc_positions: [] },
  debt: emptyAsset,
  real_estate: emptyAsset,
  privateMarketDetails: privateMarketDetailsEmptyState,
  isGetPrivateMarketDetailsLoading: false,
  isGetPrivateMarketDetailsError: false,
  getprivateMarketDetailsErrorMessage: '',
  gpUpdates: {
    data: [],
    isGetGpUpdatesLoading: false,
    isGetGpUpdatesError: false,
    getGpUpdatesErrorMessage: '',
  },
  gpUpdateDetails: {
    data: {
      update_id: '',
      encoded_update_id: '',
      publish_date_display: '',
      publish_date_raw: '',
      title: '',
      excerpt_display: '',
      excerpt_raw: '',
      documents: [],
      related_investments: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: '',
  },
  k1CheckList: {
    data: {
      accounts: {},
      totalNav: 0,
      totalIlLiquid: 0,
    },
    isLoading: false,
    isError: false,
    errorMessage: '',
  },
};

function getPrivateMarket(builder: ActionReducerMapBuilder<IPrivateMarketSlice>) {
  builder
    // ==============================|| GET private Market ||============================== //
    .addCase(getprivateMarket.pending, (state) => ({
      ...state,
      isGetPrivateMarketLoading: true,
      isGetPrivateMarketError: false,
      getprivateMarketErrorMessage: '',
    }))
    .addCase(getprivateMarket.fulfilled, (state, action: any) => ({
      ...state,
      isGetPrivateMarketLoading: false,
      equity: action.payload?.data?.equity ?? [],
      debt: action.payload?.data?.debt ?? [],
      real_estate: action.payload?.data?.real_estate ?? [],
      isGetPrivateMarketError: false,
      getprivateMarketErrorMessage: '',
    }))
    .addCase(getprivateMarket.rejected, (state, action: any) => ({
      ...state,
      isGetPrivateMarketError: true,
      isGetPrivateMarketLoading: false,
      getprivateMarketErrorMessage: action.payload,
    }));
}

function getPrivateMarketDetails(builder: ActionReducerMapBuilder<IPrivateMarketSlice>) {
  builder
    // ==============================|| GET private Market ||============================== //
    .addCase(getprivateMarketDetails.pending, (state) => ({
      ...state,
      isGetPrivateMarketDetailsLoading: true,
      isGetPrivateMarketDetailsError: false,
      getprivateMarketDetailsErrorMessage: '',
    }))
    .addCase(getprivateMarketDetails.fulfilled, (state, action: any) => ({
      ...state,
      isGetPrivateMarketDetailsLoading: false,
      privateMarketDetails: action?.payload?.data || privateMarketDetailsEmptyState,
      isGetPrivateMarketDetailsError: false,
      getprivateMarketDetailsErrorMessage: '',
    }))
    .addCase(getprivateMarketDetails.rejected, (state, action: any) => ({
      ...state,
      isGetPrivateMarketDetailsError: true,
      isGetPrivateMarketDetailsLoading: false,
      getprivateMarketDetailsErrorMessage: action.payload,
    }));
}

function gpUpdates(builder: ActionReducerMapBuilder<IPrivateMarketSlice>) {
  builder
    // ==============================|| GET Gp Updates ||============================== //
    .addCase(getGpUpdates.pending, (state) => ({
      ...state,
      gpUpdates: {
        ...state.gpUpdates,
        isGetGpUpdatesLoading: true,
        isGetGpUpdatesError: false,
        getGpUpdatesErrorMessage: '',
      },
    }))
    .addCase(getGpUpdates.fulfilled, (state, action: any) => ({
      ...state,
      gpUpdates: {
        ...state.gpUpdates,
        isGetGpUpdatesLoading: false,
        isGetGpUpdatesError: false,
        getGpUpdatesErrorMessage: '',
        data: action.payload.data,
      },
    }))
    .addCase(getGpUpdates.rejected, (state, action: any) => ({
      ...state,
      gpUpdates: {
        ...state.gpUpdates,
        isGetGpUpdatesLoading: false,
        isGetGpUpdatesError: true,
        getGpUpdatesErrorMessage: action.payload,
      },
    }));
}

function gpUpdatesDetail(builder: ActionReducerMapBuilder<IPrivateMarketSlice>) {
  builder
    // ==============================|| GET Gp Update Details ||============================== //
    .addCase(getGpUpdatesDetail.pending, (state) => ({
      ...state,
      gpUpdateDetails: {
        ...state.gpUpdateDetails,
        isLoading: true,
        isError: false,
        errorMessage: '',
      },
    }))
    .addCase(getGpUpdatesDetail.fulfilled, (state, action: any) => ({
      ...state,
      gpUpdateDetails: {
        ...state.gpUpdateDetails,
        isLoading: false,
        isError: false,
        errorMessage: '',
        data: action.payload.data,
      },
    }))
    .addCase(getGpUpdatesDetail.rejected, (state, action: any) => ({
      ...state,
      gpUpdateDetails: {
        ...state.gpUpdateDetails,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      },
    }));
}

function k1CheckList(builder: ActionReducerMapBuilder<IPrivateMarketSlice>) {
  builder
    // ==============================|| GET Gp Updates ||============================== //
    .addCase(getK1CheckList.pending, (state) => ({
      ...state,
      k1CheckList: {
        ...state.k1CheckList,
        isLoading: true,
        isError: false,
        errorMessage: '',
      },
    }))
    .addCase(getK1CheckList.fulfilled, (state, action: any) => ({
      ...state,
      k1CheckList: {
        ...state.k1CheckList,
        isLoading: false,
        isError: false,
        errorMessage: '',
        data: action.payload.data,
      },
    }))
    .addCase(getK1CheckList.rejected, (state, action: any) => ({
      ...state,
      k1CheckList: {
        ...state.k1CheckList,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      },
    }));
}
const privateMarketSlice = createSlice({
  name: 'private-market',
  initialState,
  reducers: {
    // ==============================|| private Market ||============================== //
    clearprivateMarketErrorState: (state) => ({
      ...state,
      isGetPrivateMarketError: false,
      getprivateMarketErrorMessage: '',
    }),
    clearprivateMarketDetailsErrorState: (state) => ({
      ...state,
      isGetPrivateMarketDetailsError: false,
      getprivateMarketDetailsErrorMessage: '',
    }),
    clearGpUpdatesErrorState: (state) => ({
      ...state,
      gpUpdates: {
        ...state.gpUpdates,
        isGetGpUpdatesError: false,
        getGpUpdatesErrorMessage: '',
      },
    }),
    clearGpUpdateDetailsErrorState: (state) => ({
      ...state,
      gpUpdateDetails: {
        ...state.gpUpdateDetails,
        isError: false,
        errorMessage: '',
      },
    }),
    clearK1ChecklistErrorState: (state) => ({
      ...state,
      k1CheckList: {
        ...state.k1CheckList,
        isError: false,
        errorMessage: '',
      },
    }),
  },
  extraReducers: (builder) => {
    getPrivateMarket(builder);
    getPrivateMarketDetails(builder);
    gpUpdates(builder);
    gpUpdatesDetail(builder);
    k1CheckList(builder);
  },
});
export const {
  clearprivateMarketErrorState,
  clearprivateMarketDetailsErrorState,
  clearGpUpdatesErrorState,
  clearGpUpdateDetailsErrorState,
  clearK1ChecklistErrorState,
} = privateMarketSlice.actions;

export default privateMarketSlice.reducer;
