/* eslint-disable @typescript-eslint/no-explicit-any */
import customNotification from '@/utils/notification';
import { IFile } from '@/components/Documents/DocumentInterface';
import { getApiErrorMessage } from '../utils/helper';
import HttpClient from '../utils/HttpClient';
import { getFileNameFromKey } from '../components/Documents/DocumentsHelper';

export const getDocumentsConfig = async () => {
  try {
    const response = await HttpClient.get(`/document-structure`);
    // customNotification({ type: 'success', message: response.data.message });

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message = getApiErrorMessage(error);
    customNotification({ type: 'error', message });
    return message;
  }
};

export const getFiles = async (prefix: string) => {
  try {
    const response = await HttpClient.get(`/documents?prefix=${prefix}`);
    // customNotification({ type: 'success', message: response.data.message });
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message = getApiErrorMessage(error);
    customNotification({ type: 'error', message });
    return message;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const uploadFile = async (files: any, currentDirectory: string) => {
  const formData: any = new FormData();
  files.forEach((file: any, index: any) => {
    formData.append(`fileDetail[${index}][file]`, file);
    formData.append(`fileDetail[${index}][fileKey]`, `${currentDirectory}${file.name}`);
  });
  try {
    const response = await HttpClient.post('/documents', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    customNotification({ type: 'success', message: response.data.message });

    return { data: response.data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message = getApiErrorMessage(error);
    customNotification({ type: 'error', message });
    return message;
  }
};

export const renameFile = async (data: { newFileKey: string; oldFileKey: string }[]) => {
  try {
    const response = await HttpClient.put('/documents/rename-file', { files: data });
    customNotification({ type: 'success', message: response.data.message });

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message = getApiErrorMessage(error);
    customNotification({ type: 'error', message });
    return message;
  }
};

export const moveFile = async (data: { newFileKey: string; oldFileKey: string }[]) => {
  try {
    const response = await HttpClient.put('/documents/move-file', { files: data });
    customNotification({ type: 'success', message: 'File moved successfully' });

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message = getApiErrorMessage(error);
    customNotification({ type: 'error', message });
    return message;
  }
};

export const deleteFile = async (fileKeys: any) => {
  try {
    const response = await HttpClient.delete('/documents/files', { data: { fileKeys } });
    customNotification({ type: 'success', message: response.data.message });

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const message = getApiErrorMessage(error);
    customNotification({ type: 'error', message });
    return message;
  }
};

export const downloadFile = async (files: any) => {
  try {
    const formattedFiles = files.map((file: IFile) => {
      const { fileNameWithExtension } = getFileNameFromKey(file?.Key);
      return {
        fileKey: file.Key,
        name: fileNameWithExtension,
      };
    });
    const response = await HttpClient.post(
      `/documents/download`,
      { files: formattedFiles },
      { responseType: 'arraybuffer' }
    );
    if (!response.data) {
      customNotification({ type: 'error', message: 'No file found' });
      return false;
    }
    let fileName;
    if (files.length === 1) {
      fileName = getFileNameFromKey(files[0]?.Key)?.fileNameWithExtension;
    } else {
      fileName = 'files.zip';
    }
    const blob = new Blob([response.data]);
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download = fileName;
    document?.body?.appendChild(link);
    link.click();
    document?.body?.removeChild(link);
    // customNotification({ type: 'success', message: 'File(s) downloaded successfully' });
    return true;
  } catch (error) {
    const message = getApiErrorMessage(error);
    customNotification({ type: 'error', message });
    return message;
  }
};
const documentService = {
  getDocumentsConfig,
  getFiles,
  moveFile,
};
export default documentService;
