import {
  BarChartOutlined,
  BookOutlined,
  CheckSquareOutlined,
  DesktopOutlined,
  FileOutlined,
  MessageOutlined,
  PieChartFilled,
  TeamOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const { Sider } = Layout;
function SideBar(props: { collapsed: boolean }) {
  const { collapsed } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [activeKey, setActiveKey] = useState(
    location?.pathname === '/' || location?.pathname === '' ? '/dashboard' : location?.pathname
  );

  // On Changing the route, scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  // const getMenuOpenKeys = (pathname: string) => {
  //   // openKeys based on the URL
  //   switch (pathname) {
  //     case '/private-market':
  //     case '/updates':
  //     case '/checklist':
  //       return ['Private Market'];

  //     case '/public-market':
  //       return ['Public Market'];
  //     case '/performance':
  //     case '/benchmarks':
  //       return ['Performance'];
  //     default:
  //       return [];
  //   }
  // };

  // Define all parent menu keys to expand by default
  const defaultOpenKeys = ['Private Market', 'Public Market', 'Performance'];
  const [openKeys, setOpenKeys] = useState(defaultOpenKeys);

  useEffect(() => {
    if (location) {
      if (activeKey !== location?.pathname) {
        setActiveKey(location?.pathname);
      }
    }
  }, [location, activeKey]);

  const onOpenChange = (key: string[]) => {
    setOpenKeys(key);
  };
  useEffect(() => {
    if (collapsed) {
      setOpenKeys([]);
    } else {
      setOpenKeys(defaultOpenKeys);
    }
  }, [collapsed]);

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ position: 'fixed', height: '100vh', fontSize: '1.2em', overflow: 'auto' }}
      width={200}
    >
      <div className="logo" />
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[activeKey]}
        openKeys={collapsed ? [] : openKeys}
        // defaultOpenKeys={defaultOpenKeys}
        onOpenChange={onOpenChange}
        items={[
          {
            key: '/dashboard',
            icon: <PieChartFilled />,
            label: 'My Allocation',
            onClick: () => navigate('/dashboard'),
          },

          {
            key: '/goals',
            icon: <BarChartOutlined />,
            label: 'Goals / Milestones',
            onClick: () => navigate('/goals'),
          },
          {
            key: 'Performance',
            icon: <BarChartOutlined />,
            label: 'Performance',
            children: [
              {
                key: '/performance',
                icon: <DesktopOutlined />,
                label: 'Summary',
                onClick: () => navigate('/performance'),
              },
              {
                key: '/benchmarks',
                icon: <BarChartOutlined />,
                label: 'Vs. Benchmarks',
                onClick: () => navigate('/benchmarks'),
              },
            ],
          },
          {
            key: 'Public Market',
            icon: <DesktopOutlined />,
            label: 'Public Market',
            children: [
              {
                key: '/public-market',
                icon: <DesktopOutlined />,
                label: 'Overview',
                onClick: () => navigate('/public-market'),
              },
            ],
          },
          {
            key: 'Private Market',
            icon: <DesktopOutlined />,
            label: 'Private Market',
            children: [
              {
                key: '/private-market',
                icon: <DesktopOutlined />,
                label: 'Overview',
                onClick: () => navigate('/private-market'),
              },
              {
                key: '/updates',
                icon: <MessageOutlined />,
                label: 'Investment News',
                onClick: () => navigate('/updates'),
              },
              {
                key: '/checklist',
                icon: <CheckSquareOutlined />,
                label: 'K1 Checklist',
                onClick: () => navigate('/checklist'),
              },
            ],
          },
          {
            key: '/documents',
            icon: <BookOutlined />,
            label: 'Documents',
            onClick: () => navigate('/documents'),
          },
          {
            key: '/teams',
            icon: <TeamOutlined />,
            label: 'Team',
            onClick: () => navigate('/teams'),
          },
          {
            key: '/disclosures',
            icon: <BookOutlined />,
            label: 'Disclosures',
            onClick: () => navigate('/disclosures'),
          },
          {
            key: '/terms',
            icon: <FileOutlined />,
            label: 'Terms of Service',
            onClick: () => navigate('/terms'),
          },
        ]}
      />
    </Sider>
  );
}
export default SideBar;
