import Card from '@/components/Card';
import { Descriptions } from 'antd';
import { IAccountInfo } from './interface';

function AccountInfo(props: { isLoading: boolean; accountInfo: IAccountInfo }) {
  const { isLoading = true, accountInfo } = props;
  const { account_name, account_nickname, account_number } = accountInfo;

  return (
    <Card title="Account Info" isLoading={isLoading}>
      <Descriptions layout="horizontal" column={1} contentStyle={{ fontWeight: 'bold' }}>
        <Descriptions.Item label="Account Number">{account_number}</Descriptions.Item>
        <Descriptions.Item label="Account Legal Name">{account_name}</Descriptions.Item>
        <Descriptions.Item label="Account Nickname">{account_nickname}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

export default AccountInfo;
