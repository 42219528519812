import { IGpUpdateDetails, IGpUpdate } from '@/views/private-market/gp-updates/interface';

export const mockGpUpdates: IGpUpdate[] = [
  {
    title: 'Demo Title',
    update_id: 11,
    encoded_update_id: '2el0349rzpyzw7n5o6mj',
    publish_date_raw: '2023-10-11 10:44:37',
    publish_date_display: 'Oct 11th, 2023',
    excerpt_raw:
      'Quas adipisci quibusdam illum. Voluptatum nihil debitis. Enim maiores voluptatibus quia qui tempora mollitia laudantium amet quae.',
    excerpt_display:
      'Soluta tenetur sint sunt laborum itaque veniam repellat quas. Provident sint esse. Enim iusto dolores nam. Cum explicabo est labore cupiditate aliquid et. Quibusdam totam optio ex optio aperiam voluptatibus suscipit nihil dolores. Doloremque voluptatem maiores voluptas culpa voluptate vitae quae doloremque corrupti.',
    related_investments: [
      {
        id: 0,
        name: 'Noah',
        position_id: 1,
      },
      {
        id: 1,
        name: 'Haskell',
        position_id: 1,
      },
    ],
  },
];

export const mockGpUpdatesDetails: IGpUpdateDetails = {
  update_id: '1',
  encoded_update_id: '2el0349rzpyzw7n5o6mj',
  publish_date_raw: '2023-10-11 10:44:37',
  publish_date_display: 'Oct 11th, 2023',
  title: 'Gordon',
  excerpt_raw:
    'Quia vitae culpa. Omnis pariatur omnis illum autem quo. Accusamus laudantium ea delectus sint iusto.',
  excerpt_display:
    'Delectus optio rem mollitia quod enim. Voluptas accusamus temporibus enim dignissimos laborum. Odit fuga dicta ut non officia deleniti. Pariatur ullam libero eum velit aliquid vel dolor.',
  documents: [
    {
      document_id: 0,
      encoded_document_id: '2el0349rzpyzw7n5o6mj',
      file_name_raw: 'som.mseed',
      file_name_display: 'wells_west.mk3d',
    },
    {
      document_id: 1,
      encoded_document_id: '2el0349rzpyzw7n5o6mj',
      file_name_raw: 'androgyne.mj2',
      file_name_display: 'access_withdrawal_borders.com',
    },
  ],
  related_investments: [
    {
      id: 0,
      name: 'Nona',
      position_id: 1,
    },
    {
      id: 1,
      name: 'Kiarra',
      position_id: 1,
    },
  ],
};
