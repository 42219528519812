import Table from '@/components/Table';
import { IFinancialGoal } from './interface';

function FinancialGoals(props: { data: IFinancialGoal[]; isLoading: boolean }) {
  const { data, isLoading } = props;
  const columns = [
    // {
    //   title: 'Date',
    //   dataIndex: 'date_display',
    //   key: 'date_display',
    //   width: 40,
    // },
    {
      title: '',
      dataIndex: 'content',
      key: 'content',
      width: 170,
    },
  ];
  return (
    <Table
      title="Financial Planning Goals"
      dataSource={data}
      columns={columns}
      className="bg-header-white"
      globalSearch={false}
      rowKey={(record) => record?.id}
      isLoading={isLoading}
    />
  );
}

export default FinancialGoals;
