import CustomBreadcrumb from '@/components/Breadcrumb';
import Card from '@/components/Card';
import useShowErrorToast from '@/hooks/useShowErrorToast';
import { downloadGpFile, getGpUpdatesDetail } from '@/services/privateMarketService';
import { AppDispatch, AppState } from '@/store';
import { clearGpUpdateDetailsErrorState } from '@/store/private-market/privateMarketSlice';
import { theme } from '@/utils/theme';
import { Button, Col, Empty, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';

function GpUpdateDetail() {
  const dispatch = useDispatch<AppDispatch>();

  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getGpUpdatesDetail(id));
    }
  }, [dispatch, id]);

  const { isError, isLoading, errorMessage, data } = useSelector(
    (state: AppState) => state?.privateMarket?.gpUpdateDetails
  );
  useShowErrorToast({
    isError,
    errorMessage,
    clearErrorState: clearGpUpdateDetailsErrorState,
  });

  const { documents, related_investments } = data;

  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
      <CustomBreadcrumb
        paths={[
          {
            path: '/updates',
            title: 'Private Investment Updates ',
          },
          {
            path: '',
            title: data?.title || 'Details',
          },
        ]}
      />
      <Row gutter={[16, 16]}>
        <Col span={16}>
          <Card title={data?.title} subTitle={data?.publish_date_display} isLoading={isLoading}>
            {data?.excerpt_display ? data.excerpt_display : <Empty />}
          </Card>
        </Col>
        <Col span={8}>
          <Space size="middle" direction="vertical" style={{ display: 'flex' }}>
            <Card title="Attachments" isLoading={isLoading}>
              {documents?.length > 0 ? (
                documents?.map((item) => (
                  <Button
                    key={item.document_id}
                    type="link"
                    style={{ color: theme['@primary-color'] }}
                    onClick={() =>
                      downloadGpFile({
                        updateId: data.encoded_update_id,
                        documentId: item.encoded_document_id,
                      })
                    }
                  >
                    {item.file_name_display}
                  </Button>
                ))
              ) : (
                <Empty />
              )}
            </Card>
            <Card title="Related Investments" isLoading={isLoading}>
              {related_investments?.length > 0 ? (
                related_investments?.map((item) => (
                  <Link
                    key={item?.id}
                    to={`/private-market/${item?.position_id}`}
                    style={{ color: theme['@link-color'] }}
                  >
                    {item.name}
                  </Link>
                ))
              ) : (
                <Empty />
              )}
            </Card>
          </Space>
        </Col>
      </Row>
    </Space>
  );
}
export default GpUpdateDetail;
