import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Table from '@/components/Table';
import { Link } from 'react-router-dom';
import { featureFlag } from '@/utils/featureFlags';
import Footer from '@/components/Table/Footer';
import { IPortfolioAccounts, IRenderFooter } from './interface';

function renderFooter({ pageData, columns, sumFields, label }: IRenderFooter) {
  return <Footer pageData={pageData} label={label} sumFields={sumFields} columns={columns} />;
}

function PortfolioAccounts(props: { accounts: IPortfolioAccounts[]; isLoading: boolean }) {
  const { accounts, isLoading } = props;
  const filteredAccounts = Array.isArray(accounts)
    ? accounts.filter((account) => account.market_value_raw > 0)
    : [];
  const columns: ColumnsType<IPortfolioAccounts> = [
    {
      title: 'Account Number',
      dataIndex: 'account_number',
      key: 'account_number',
      render: (value: string, record) =>
        featureFlag['page:account_details'] ? (
          <Link to={`/public-market/account-details/${record.public_guid}`}>{value}</Link>
        ) : (
          <span>{value}</span>
        ),
    },
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      key: 'account_name',
    },
    {
      title: 'Market Value',
      dataIndex: 'market_value_display',
      key: 'market_value_display',
    },
  ];

  const footerSumFields = [
    {
      field: 'market_value_display',
      displayFormat: 'currency',
      value: 'market_value_raw',
    },
  ];
  return (
    <Row>
      <Col span={24}>
        <Table
          className="bg-header-white"
          columns={columns}
          // pagination={false}
          dataSource={filteredAccounts}
          isLoading={isLoading}
          rowKey={(record) => record.id}
          title="PORTFOLIO ACCOUNTS"
          summary={(pageData) =>
            filteredAccounts.length > 0 &&
            renderFooter({
              pageData,
              columns,
              sumFields: footerSumFields,
              label: 'Total',
            })
          }
        />
      </Col>
    </Row>
  );
}

export default PortfolioAccounts;
