import { ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

// ==============================|| AUTH LAYOUT ||============================== //

function AuthLayout(props: { children?: ReactNode }) {
  const { children } = props;
  const { isLoggedIn } = useAuth();
  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#dfdcdc',
      }}
    >
      {/* Using outlet for nested routes. children of the parent route should automatically render inside parent */}
      <Outlet />
      {/* Children is used currently to create stories for the Auth components */}
      {children}
    </div>
  );
}
AuthLayout.defaultProps = {
  children: '',
};

export default AuthLayout;
