import { Layout, Typography, Image } from 'antd';
import Card from '@/components/Card';
import Logo from '../../assets/images/logo-light-bg.png';

const { Content } = Layout;
const { Paragraph } = Typography;

function Maintenance() {
  return (
    <Layout style={{ height: '100vh' }}>
      <Content
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 20,
          flexDirection: 'column',
        }}
      >
        <div style={{ marginBottom: '1rem' }}>
          <Image src={Logo} height={80} alt="logo" preview={false} />
        </div>
        <Card title="Maintenance">
          <Paragraph style={{ fontSize: '1.5rem' }}>
            ClientCare is temporarily unavailable while we perform required maintenance. If you need
            assistance, please contact your Personal Wealth Advisor or Relationship Manager.
          </Paragraph>
        </Card>
      </Content>
    </Layout>
  );
}
export default Maintenance;
