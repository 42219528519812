import Card from '@/components/Card';
import { Descriptions } from 'antd';

interface IInvestmentInfo {
  data: { security_type: string; name: string; investment_type: string };
  isLoading: boolean;
}

function InvestmentInfo(props: IInvestmentInfo) {
  const { data, isLoading } = props;
  const { security_type, name, investment_type } = data;
  return (
    <Card title={`Investment Info - ${name}` || 'Investment Info'} isLoading={isLoading}>
      <Descriptions layout="horizontal" column={1} contentStyle={{ fontWeight: 'bold' }}>
        <Descriptions.Item label="Type">{investment_type}</Descriptions.Item>
        <Descriptions.Item label="Security Type">{security_type}</Descriptions.Item>
      </Descriptions>
    </Card>
  );
}

export default InvestmentInfo;
