import Card from '@/components/Card';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import useShowErrorToast from '@/hooks/useShowErrorToast';
import { getPortfolioDetails } from '@/services/publicMarketService';
import { AppDispatch, AppState } from '@/store';
import { clearPortfolioDetailsErrorState } from '@/store/public-market/publicMarketSlice';
import { Col, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PortfolioManagerComments from '../PortfolioManagerComments';
import PublicHolding from '../PublicHolding';
import PortfolioAccounts from './PortfolioAccounts';
import PortfolioInfo from './PortfolioInfo';
import PortfolioPerformance from './PortfolioPerformance';
import Allocation from './Allocation';

function PortfolioDetailsPage() {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      dispatch(getPortfolioDetails(id));
    }
  }, [id, dispatch]);
  const { data, isLoading, isError, errorMessage } = useSelector(
    (state: AppState) => state.publicMarket.portfolioDetails
  );

  useShowErrorToast({ isError, errorMessage, clearErrorState: clearPortfolioDetailsErrorState });

  const {
    portfolio_name,
    portfolio_type,
    equity_allocation,
    accounts,
    comments,
    positions,
    performance,
    allocations,
  } = data;
  const portfolioInfo = { portfolio_name, portfolio_type, equity_allocation };
  return (
    <>
      <CustomHelmet title={`Portfolio - ${portfolio_name}`} />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={16}>
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <PortfolioInfo isLoading={isLoading} portfolioDetails={portfolioInfo} />
            <PortfolioAccounts accounts={accounts} isLoading={isLoading} />
          </Space>
        </Col>
        <Col span={8}>
          <Allocation allocation={allocations} isLoading={isLoading} />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
        <Col span={24}>
          <PortfolioPerformance isLoading={isLoading} performance={performance} />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
        <Col span={24}>
          <PublicHolding publicHoldings={positions} isLoading={isLoading} />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
        <Col span={24}>
          <PortfolioManagerComments portfolioManagerComments={comments} isLoading={isLoading} />
        </Col>
      </Row>
    </>
  );
}

export default PortfolioDetailsPage;
