/* eslint-disable @typescript-eslint/no-explicit-any */
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { enrollService, sendOtp, signIn, verifyOtp } from '../../services/authService';

const initialState = {
  isSubmitLoginLoading: false,
  isSubmitOtpLoading: false,
  isLoginError: false,
  isOtpError: false,
  loginErrorMessage: '',
  otpErrorMessage: '',
  preference_id: '',
  loginSuccessMessage: '',
  smsRetryCount: 0,
  isSmsRetryLoading: false,
};

function sendOtpReducer(builder: ActionReducerMapBuilder<any>) {
  builder
    // ==============================|| Send OTP ||============================== //
    .addCase(sendOtp.pending, (state) => ({
      ...state,
      isSmsRetryLoading: true,
    }))
    .addCase(sendOtp.fulfilled, (state, action: any) => ({
      ...state,
      smsRetryCount: action?.payload?.send_code_attempts?.length || 0,
      isSmsRetryLoading: false,
    }))
    .addCase(sendOtp.rejected, (state, action: any) => ({
      ...state,
      isSmsRetryLoading: false,
      // If the maximum send attempts are reached or there is another issue in sending the SMS
      smsRetryCount: state.smsRetryCount + 1,
    }));
}

function enrollReducer(builder: ActionReducerMapBuilder<any>) {
  builder
    // ==============================|| Send OTP ||============================== //
    .addCase(enrollService.pending, (state) => ({
      ...state,
    }))
    .addCase(enrollService.fulfilled, (state, action: any) => ({
      ...state,
      smsRetryCount: action?.payload?.send_code_attempts?.length || 0,
    }))
    .addCase(enrollService.rejected, (state, action: any) => ({
      ...state,
    }));
}

function loginReducer(builder: ActionReducerMapBuilder<any>) {
  builder
    .addCase(signIn.pending, (state) => ({
      ...state,
      isSubmitLoginLoading: true,
      isLoginError: false,
      loginErrorMessage: '',
    }))
    .addCase(signIn.fulfilled, (state: any, action: any) => ({
      ...state,
      preference_id: action?.payload?.preference_id,
      isSubmitLoginLoading: false,
      loginSuccessMessage: action?.payload?.message,
      smsRetryCount: action?.payload?.send_code_attempts?.length || 0,
    }))
    .addCase(signIn.rejected, (state, action: any) => ({
      ...state,
      isLoginError: true,
      isSubmitLoginLoading: false,
      loginErrorMessage: action.payload,
    }));
}
function verifyOtpReducer(builder: ActionReducerMapBuilder<any>) {
  builder
    .addCase(verifyOtp.pending, (state) => ({ ...state, isSubmitOtpLoading: true }))
    .addCase(verifyOtp.fulfilled, (state: any, action: any) => ({
      ...state,
      isSubmitOtpLoading: false,
    }))
    .addCase(verifyOtp.rejected, (state, action: any) => ({
      ...state,
      isOtpError: true,
      isSubmitOtpLoading: false,
      otpErrorMessage: action.payload,
    }));
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearLoginErrorState: (state: any) => ({
      ...state,
      isLoginError: false,
      loginErrorMessage: '',
    }),
    clearOtpErrorState: (state: any) => ({ ...state, isOtpError: false, otpErrorMessage: '' }),
    resetLoginSubmitLoader: (state: any) => ({ ...state, isSubmitLoginLoading: false }),
  },
  extraReducers: (builder) => {
    loginReducer(builder);
    verifyOtpReducer(builder);
    sendOtpReducer(builder);
    enrollReducer(builder);
  },
});
export const { clearLoginErrorState, clearOtpErrorState, resetLoginSubmitLoader } =
  authSlice.actions;

export default authSlice.reducer;
