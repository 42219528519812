import { Col, Empty, Row, Skeleton } from 'antd';
import Card from '@/components/Card';
import { IProfessionals } from './interface';

function SkeletonProfessionals() {
  const SKELETON_COUNT = 8;
  return (
    <>
      {Array.from({ length: SKELETON_COUNT }, (x, i) => (
        <Col key={i} className="gutter-row" span={6} data-testid="professional-skeleton-loader">
          <div>
            <Skeleton active />
          </div>
        </Col>
      ))}
    </>
  );
}
export interface IProfessionalsProps {
  professionals: IProfessionals[];
  isLoading: boolean;
}
function Professionals(props: IProfessionalsProps) {
  const { professionals, isLoading } = props;
  return (
    <Card title="MY OTHER PROFESSIONALS">
      <Row
        gutter={[16, 24]}
        style={professionals?.length === 0 ? { justifyContent: 'center' } : {}}
      >
        {/* To show loading */}
        {isLoading && <SkeletonProfessionals />}

        {/* To show Empty message when no data in a list */}
        {!isLoading && professionals.length === 0 && <Empty data-testid="professional-empty" />}

        {/* To show Data */}
        {professionals.length > 0 &&
          !isLoading &&
          professionals.map((professional: IProfessionals) => {
            const { id, name, email, phone } = professional;
            return (
              <Col xs={24} sm={12} md={8} lg={6} key={id} data-testid="professional-data">
                <div style={{ wordWrap: 'break-word' }}>
                  {name && (
                    <h4 className="bold uppercase" data-testid="professional-name">
                      {name}
                    </h4>
                  )}
                  {email && (
                    <p>
                      <a href={`mailto:${email}`} data-testid="professional-email">
                        {email}
                      </a>
                    </p>
                  )}
                  {phone && <p data-testid="professional-phone">{phone}</p>}
                </div>
              </Col>
            );
          })}
      </Row>
    </Card>
  );
}

export default Professionals;
