import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { getValueLocalStorage, validatePassword } from '@/utils/helper';
import { useEffect, useState } from 'react';
import { resetPassword } from '@/services/authService';
import { useNavigate } from 'react-router-dom';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import AuthContainer from '../auth-container/AuthContainer';
import { authMessages } from '../../../utils/messages';
import { theme } from '../../../utils/theme';

const { PLEASE_ENTER_CONFIRM_PASSWORD } = authMessages;

function ResetPassword() {
  const { PLEASE_ENTER_OTP, ONLY_NUMBERS_ALLOWED } = authMessages;
  const forgotPasswordMessage = getValueLocalStorage('forgotPasswordMessage');
  const email = getValueLocalStorage('email') as string;

  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const onFinish = async (formValues: { secretCode: string; newPassword: string }) => {
    const { secretCode, newPassword } = formValues;
    setIsSubmitLoading(true);
    const res = await resetPassword({ email, password: newPassword, secretCode });
    if (res?.data?.success === true) {
      localStorage.clear(); // Clear the email and forgotPasswordMessage from local storage
      navigate('/login');
    }
    setIsSubmitLoading(false);
  };
  return (
    <AuthContainer>
      <CustomHelmet title="Reset Password" />
      <p className="bold uppercase">
        Password <span style={{ color: theme['@primary-color'] }}>reset</span>
      </p>
      <p>{forgotPasswordMessage}</p>
      <Form form={form} initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item name="newPassword" rules={[{ required: true, validator: validatePassword }]}>
          <Input.Password
            data-testid="password"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="New Password - Minimum 8 Characters"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            { required: true, message: PLEASE_ENTER_CONFIRM_PASSWORD },
            ({ getFieldValue }: { getFieldValue: (name: string) => string }) => ({
              async validator(_: RuleObject, value: StoreValue) {
                if (!value || getFieldValue('newPassword') === value) {
                  return;
                }
                throw new Error('The two passwords that you entered do not match!');
              },
            }),
          ]}
        >
          <Input.Password
            data-testid="confirmPassword"
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder='Confirm Password - Same as "New Password"'
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          name="secretCode"
          rules={[
            { required: true, message: PLEASE_ENTER_OTP },
            { pattern: /^[0-9]+$/, message: ONLY_NUMBERS_ALLOWED },
          ]}
        >
          <Input
            data-testid="secretCode"
            placeholder="Enter 6 digit Code"
            maxLength={6}
            autoFocus={true}
          />
        </Form.Item>
        <div className="auth-container-footer">
          <Form.Item />
          <Form.Item>
            <Button
              data-testid="submit"
              type="primary"
              htmlType="submit"
              className="uppercase"
              style={{
                backgroundColor: !isFormValid ? theme.lightGray : theme.darkCyan,
                color: !isFormValid ? theme.grayColor : theme['@link-color'],
              }}
              disabled={!isFormValid}
              loading={isSubmitLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </AuthContainer>
  );
}
export default ResetPassword;
