/* eslint-disable @typescript-eslint/no-explicit-any */
import { publicAllocationInitialState } from '@/utils/contants';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { IPublicMarketData } from '@/views/public-market/interface';
import { IAccountDetails } from '@/views/public-market/account-details/interface';
import { IPortfolioDetails } from '@/views/public-market/portfolio-details/interface';
import {
  getAccountDetailsService,
  getPortfolioDetails,
  getPublicMarket,
} from '../../services/publicMarketService';

export interface IAccountDetailsReducer {
  data: IAccountDetails;
  isGetAccountDetailsLoading: boolean;
  isGetAccountDetailsError: boolean;
  getAccountDetailsErrorMessage: string;
}

export interface IPortfolioDetailsReducer {
  data: IPortfolioDetails;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
}

interface publicMarkeDetailsReducer extends IPublicMarketData {
  isGetPublicMarketLoading: boolean;
  isGetPublicMarketError: boolean;
  getPublicMarketErrorMessage: string;
}
export interface IPublicMarketSlice {
  publicMarketDetails: publicMarkeDetailsReducer;
  accountDetails: IAccountDetailsReducer;
  portfolioDetails: IPortfolioDetailsReducer;
}
const initialState: IPublicMarketSlice = {
  // ==============================|| Public Market ||============================== //
  publicMarketDetails: {
    isGetPublicMarketLoading: false,
    isGetPublicMarketError: false,
    getPublicMarketErrorMessage: '',
    portfolios: [],
    transactions: [],
    pmComments: [],
    positions: [],
    accounts: [],
    allocation: publicAllocationInitialState,
  },
  accountDetails: {
    data: {
      account_name: '',
      account_nickname: '',
      account_number: '',
      positions: [],
      transactions: [],
    },
    isGetAccountDetailsLoading: false,
    isGetAccountDetailsError: false,
    getAccountDetailsErrorMessage: '',
  },
  portfolioDetails: {
    data: {
      portfolio_name: '',
      equity_allocation: '',
      portfolio_type: '',
      allocations: [],
      accounts: [],
      positions: [],
      performance: {
        inception_date_display: '',
        inception_date_raw: '',
        as_of: '',
        as_of_display: '',
        returns: [],
        return_date_display: '',
      },
      comments: [],
    },
    isLoading: false,
    isError: false,
    errorMessage: '',
  },
};

function getPublicMarketReducer(builder: ActionReducerMapBuilder<IPublicMarketSlice>) {
  builder
    // ==============================|| GET Public Market ||============================== //
    .addCase(getPublicMarket.pending, (state) => ({
      ...state,
      publicMarketDetails: {
        ...state.publicMarketDetails,
        isGetPublicMarketLoading: true,
        isGetPublicMarketError: false,
        getPublicMarketErrorMessage: '',
      },
    }))
    .addCase(getPublicMarket.fulfilled, (state, action: any) => ({
      ...state,
      publicMarketDetails: {
        ...state.publicMarketDetails,
        isGetPublicMarketLoading: false,
        portfolios: action.payload?.data?.portfolios ?? [],
        transactions: action.payload?.data?.transactions ?? [],
        pmComments: action.payload?.data?.pmcomments ?? [],
        positions: action.payload?.data?.positions ?? [],
        accounts: action.payload?.data?.accounts ?? [],
        allocation: action.payload.data?.allocation ?? publicAllocationInitialState,
      },
    }))
    .addCase(getPublicMarket.rejected, (state, action: any) => ({
      ...state,
      publicMarketDetails: {
        ...state.publicMarketDetails,
        isGetPublicMarketError: true,
        isGetPublicMarketLoading: false,
        getPublicMarketErrorMessage: action.payload,
      },
    }));
}

function getAccountDetailsReducer(builder: ActionReducerMapBuilder<IPublicMarketSlice>) {
  builder
    // ==============================|| GET Public Market  Account Details ||============================== //
    .addCase(getAccountDetailsService.pending, (state) => ({
      ...state,
      accountDetails: {
        ...state.accountDetails,
        isGetAccountDetailsLoading: true,
        isGetAccountDetailsError: false,
        getAccountDetailsErrorMessage: '',
      },
    }))
    .addCase(getAccountDetailsService.fulfilled, (state, action: any) => {
      const parsedTransactions =
        Array.isArray(action.payload?.data?.transactions) &&
        action.payload?.data?.transactions?.map((transaction: any) => {
          try {
            // if valid JSON string
            const parsedTransaction = JSON.parse(transaction);
            return parsedTransaction;
          } catch (error) {
            return [];
          }
        });
      return {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          data: {
            ...action.payload.data,
            transactions: parsedTransactions || [],
          },
          isGetAccountDetailsLoading: false,
          isGetAccountDetailsError: false,
          getAccountDetailsErrorMessage: '',
        },
      };
    })
    .addCase(getAccountDetailsService.rejected, (state, action: any) => ({
      ...state,
      accountDetails: {
        ...state.accountDetails,
        isGetAccountDetailsLoading: false,
        isGetAccountDetailsError: true,
        getAccountDetailsErrorMessage: action.payload,
      },
    }));
}

function getPortfolioDetailsReducer(builder: ActionReducerMapBuilder<IPublicMarketSlice>) {
  builder
    // ==============================|| GET Public Market  Account Details ||============================== //
    .addCase(getPortfolioDetails.pending, (state) => ({
      ...state,
      portfolioDetails: {
        ...state.portfolioDetails,
        isLoading: true,
        isError: false,
        errorMessage: '',
      },
    }))
    .addCase(getPortfolioDetails.fulfilled, (state, action: any) => ({
      ...state,
      portfolioDetails: {
        ...state.portfolioDetails,
        data: action.payload.data,
        isLoading: false,
        isError: false,
        errorMessage: '',
      },
    }))
    .addCase(getPortfolioDetails.rejected, (state, action: any) => ({
      ...state,
      portfolioDetails: {
        ...state.portfolioDetails,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      },
    }));
}

const publicMarketSlice = createSlice({
  name: 'public-market',
  initialState,
  reducers: {
    // ==============================|| Public Market ||============================== //
    clearPublicMarketErrorState: (state) => ({
      ...state,
      isGetPublicMarketError: false,
      getPublicMarketErrorMessage: '',
    }),
    clearAccountDetailsErrorState: (state) => ({
      ...state,
      accountDetails: {
        ...state.accountDetails,
        isGetAccountDetailsError: false,
        getAccountDetailsErrorMessage: '',
      },
    }),
    clearPortfolioDetailsErrorState: (state) => ({
      ...state,
      portfolioDetails: {
        ...state.portfolioDetails,
        isError: false,
        errorMessage: '',
      },
    }),
  },
  extraReducers: (builder) => {
    getPublicMarketReducer(builder);
    getAccountDetailsReducer(builder);
    getPortfolioDetailsReducer(builder);
  },
});
export const {
  clearPublicMarketErrorState,
  clearAccountDetailsErrorState,
  clearPortfolioDetailsErrorState,
} = publicMarketSlice.actions;

export default publicMarketSlice.reducer;
