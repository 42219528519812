/* eslint-disable @typescript-eslint/no-explicit-any */
import { rest } from 'msw';
import {
  mockAccountDetails,
  mockDashboardData,
  mockGpUpdates,
  mockGpUpdatesDetails,
  mockK1CheckList,
  mockPrivateMarketDetails,
  mockPrivateMarketResponse,
  mockPublicMarket,
  mockTeamData,
} from './data';
import { mockGoalsData } from './data/goals';
import { mockPortfolioDetails } from './data/portfolio-details';

export const handlers = [
  rest.post('/login', (req, res, ctx) =>
    res(
      ctx.json({
        message: 'OTP sent on your primary number',
        success: true,
        userId: 1,
        preference_id: '1',
      })
    )
  ),
  rest.post('/enroll', (req, res, ctx) =>
    res(
      ctx.json({
        message: 'OTP sent on your primary number',
        sid: 'YF02645291ea10e2045e28d2fef158d90a',
        sucess: true,
      })
    )
  ),
  rest.post('/verify', (req, res, ctx) =>
    res(
      ctx.json({
        success: true,
        message: 'Verified successfully',
      })
    )
  ),

  rest.get('/private-market/', (req, res, ctx) =>
    // console.log('Request intercepted:', req.url.toString());
    res(ctx.json(mockPrivateMarketResponse))
  ),
  rest.get('/private-market/positions/1', (req, res, ctx) =>
    res(ctx.json(mockPrivateMarketDetails))
  ),

  rest.get('/private-market/updates', (req, res, ctx) => res(ctx.json(mockGpUpdates))),
  rest.get('/private-market/updates/1', (req, res, ctx) => res(ctx.json(mockGpUpdatesDetails))),
  rest.get('/k1-checklist', (req, res, ctx) => res(ctx.json(mockK1CheckList))),
  rest.get('/public-market', (req, res, ctx) => res(ctx.json(mockPublicMarket))),
  rest.get('/accounts/1', (req, res, ctx) => res(ctx.json(mockAccountDetails))),
  rest.get('/dashboard', (req, res, ctx) => res(ctx.json(mockDashboardData))),
  rest.get('/team', (req, res, ctx) => res(ctx.json(mockTeamData))),
  rest.get('/portfolios/1', (req, res, ctx) => res(ctx.json(mockPortfolioDetails))),
  rest.get('/goals', (req, res, ctx) => res(ctx.json(mockGoalsData))),
];
