import { downloadNotification } from '@/utils/notification';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fileDownloadFromUrl, getApiErrorMessage } from '../utils/helper';
import HttpClient from '../utils/HttpClient';

// ==============================|| Private Market ||============================== //

export const getprivateMarket = createAsyncThunk('private-market', async (_, thunkAPI) => {
  try {
    const response = await HttpClient.get(`/private-market/`);
    return { data: response.data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});

export const getprivateMarketDetails = createAsyncThunk(
  'private-market-details',
  async (id: string, thunkAPI) => {
    try {
      const response = await HttpClient.get(`/private-market/positions/${id}`);
      return { data: response.data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return thunkAPI.rejectWithValue(getApiErrorMessage(error));
    }
  }
);

export const getGpUpdates = createAsyncThunk('gp-updates', async (_, thunkAPI) => {
  try {
    const response = await HttpClient.get(`/private-market/updates`);
    return { data: response.data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});
export const getK1CheckList = createAsyncThunk('k1-checklist', async (_, thunkAPI) => {
  try {
    const response = await HttpClient.get(`/k1-checklist`);
    return { data: response.data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});

export const getGpUpdatesDetail = createAsyncThunk(
  'gp-updates-detail',
  async (id: string, thunkAPI) => {
    try {
      const response = await HttpClient.get(`/private-market/updates/${id}`);
      return { data: response.data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return thunkAPI.rejectWithValue(getApiErrorMessage(error));
    }
  }
);

const downloadFile = async (url: string, notificationKey: string) => {
  try {
    const response = await HttpClient.get(url, {
      responseType: 'arraybuffer',
      onDownloadProgress: (progressEvent) => {
        const totalBytes = progressEvent.total;
        const loadedBytes = progressEvent.loaded;
        const percentComplete = (loadedBytes / totalBytes) * 100;
        if (totalBytes) {
          downloadNotification({
            type: 'open',
            message: 'Downloading',
            percent: Number(percentComplete.toFixed(2)),
            key: notificationKey,
          });
        }
      },
    });

    const contentDisposition = response.headers['content-disposition'];
    if (!contentDisposition) {
      throw new Error('File name header is missing');
    }

    const filenameMatch = contentDisposition.match(/filename="(.+?)"/);
    if (!filenameMatch || !filenameMatch[1]) {
      throw new Error('Filename not found in header');
    }

    const filename = filenameMatch[1];
    const blob = new Blob([response.data]);
    const blobUrl = URL.createObjectURL(blob);
    fileDownloadFromUrl(blobUrl, filename);

    downloadNotification({
      type: 'success',
      message: 'Download completed',
      key: notificationKey,
    });
  } catch (error) {
    const message = getApiErrorMessage(error);
    downloadNotification({
      type: 'error',
      message,
      key: notificationKey,
    });
  }
};
export const downloadGpFile = async ({
  updateId,
  documentId,
}: {
  updateId: string;
  documentId: string;
}) => {
  const url = `/download-gp-update/${updateId}/${documentId}`;
  const notificationKey = 'downloadingGpUpdates';
  await downloadFile(url, notificationKey);
};

export const downloadK1ChecklistFile = async ({ documentId }: { documentId: string | number }) => {
  const url = `/download-file/${documentId}`;
  const notificationKey = 'downloadingK1Checklist';
  await downloadFile(url, notificationKey);
};

const privateMarketService = {
  getprivateMarket,
  getprivateMarketDetails,
  getGpUpdates,
};
export default privateMarketService;
