import { IAccountDetails } from '@/views/public-market/account-details/interface';

export const mockAccountDetails: IAccountDetails = {
  account_number: '*****4375',
  account_name: 'account_info.account_name',
  account_nickname: 'BIP Test - Individual',
  positions: [
    {
      position_id: '1',
      symbol: 'DISVX',
      description: '',
      quantity_raw: 712.87,
      quantity_display: '712.87',
      price_raw: 20560.5,
      price_display: '$20650.50',
      amount_raw: 156893.75,
      amount_display: '$156893.75',
    },
  ],
  transactions: [
    '{"symbol": "Test", "price_raw": 1, "amount_raw": 1, "description": null, "quantity_raw": 1, "price_display": "$1.00", "amount_display": "$1.00", "transaction_id": 1, "quantity_display": 1, "transaction_type": "Test", "transaction_date_raw": "2023-10-10", "transaction_date_display": "10/10/2023"}',
    '{"symbol": "Test", "price_raw": 1, "amount_raw": 1, "description": null, "quantity_raw": 1, "price_display": "$1.00", "amount_display": "$1.00", "transaction_id": 1, "quantity_display": 1, "transaction_type": "Test", "transaction_date_raw": "2023-10-10", "transaction_date_display": "10/10/2023"}',
  ],
};
