import { IPublicAllocation } from '@/views/public-market/interface';

export const publicAllocationInitialState: IPublicAllocation[] = [
  {
    name: 'Domestic',
    market_value_raw: 0,
    market_value_display: '$0',
    percentage_raw: 0,
    percentage_display: '0%',
  },
  {
    name: 'International',
    market_value_raw: 0,
    market_value_display: '$0',
    percentage_raw: 0,
    percentage_display: '0%',
  },
  {
    name: 'Hedged-Equity',
    market_value_raw: 0,
    market_value_display: '$0',
    percentage_raw: 0,
    percentage_display: '0%',
  },
  {
    name: 'Hedged-Yield',
    market_value_raw: 0,
    market_value_display: '$0',
    percentage_raw: 0,
    percentage_display: '0%',
  },
  {
    name: 'Short-Term Tactical',
    market_value_raw: 0,
    market_value_display: '$0',
    percentage_raw: 0,
    percentage_display: '0%',
  },
  {
    name: 'Concentrated Stock',
    market_value_raw: 0,
    market_value_display: '$0',
    percentage_raw: 0,
    percentage_display: '0%',
  },
  {
    name: 'Fixed Income',
    market_value_raw: 0,
    market_value_display: '$0',
    percentage_raw: 0,
    percentage_display: '0%',
  },
];
