import { ITeamsComponentData } from '@/views/teams/interface';

export const mockTeamData: ITeamsComponentData = {
  team: [
    {
      id: '1',
      first_name: 'John',
      last_name: 'Doe',
      email: 'John@test.com',
      picture: '',
      title: 'Personal Wealth Advisor',
      phone: '222-222-2222',
    },
  ],
  professional: [
    {
      id: '1',
      phone: '111-111-1111',
      email: 'shardul@test.com',
      name: 'MR Shardul A Sawane',
    },
  ],
};
