import PieChart from '@/components/Charts/Pie';
import { mergeAndModifyAllocations } from './common';
import { IAllocationDetail, IAllocationType } from './interface';

function AllocationChart(props: {
  allocation: IAllocationType[];
  isLoading: boolean;
  // TODO: add type for dashboardData
  // eslint-disable-next-line react/require-default-props, @typescript-eslint/no-explicit-any
  dashboardData?: any;
}) {
  const { allocation, isLoading, dashboardData } = props;
  const {
    private_pending_household_percentage_display,
    private_pending_household_percentage,
    private_pending_visible,
    public_strategy_concentrated_stock_visible,
  } = dashboardData || {};
  const allocationData =
    Array.isArray(allocation) && allocation.length > 0 ? mergeAndModifyAllocations(allocation) : [];
  const chartData = allocationData
    .map((item: IAllocationDetail) => {
      if (
        item?.display_name === 'Concentrated Stock' &&
        public_strategy_concentrated_stock_visible !== 1
      ) {
        return null;
      }

      return {
        type: item?.display_name,
        value: Number(item?.allocation_raw),
        tooltipValue: `${item?.allocation_display}`,
      };
    })
    .filter((item) => item !== null) as {
    type: string;
    value: number;
    tooltipValue: string;
  }[];
  const updatedChartData =
    private_pending_visible === 1
      ? [
          ...chartData,
          {
            type: 'Pending Private Investment',
            value: Number(private_pending_household_percentage),
            tooltipValue: private_pending_household_percentage_display,
          },
        ]
      : chartData;

  return (
    <PieChart
      isLoading={isLoading}
      showCustomPercent={true}
      data={updatedChartData}
      chartColors={[
        '#71CD9D',
        '#9C7A97',
        '#55B9B7',
        '#5C6784',
        '#FCAB64',
        '#B5446E',
        '#023522',
        '#D5A021',
        '#4A051C',
        '#E05ED8',
      ]}
    />
  );
}

export default AllocationChart;
