import { Col, Row, Table as AntdTable, Typography } from 'antd';
import { ReactNode, useMemo } from 'react';
import { ColumnsType } from 'antd/lib/table';
import Table from '@/components/Table';
import { dollarCurrencyFormat } from '@/utils/helper';
import { Link } from 'react-router-dom';
import { featureFlag } from '@/utils/featureFlags';
import { IAccount, IAccountProps } from './interface';

function useFooterTotals(pageData: IAccount[]) {
  return useMemo(
    () =>
      pageData.reduce(
        (totals, { total_raw, public_raw, private_raw }) => ({
          totalRaw: totals.totalRaw + total_raw,
          publicRaw: totals.publicRaw + public_raw,
          privateRaw: totals.privateRaw + private_raw,
        }),
        { publicRaw: 0, privateRaw: 0, totalRaw: 0 }
      ),
    [pageData]
  );
}

function Footer({ pageData }: { pageData: IAccount[] }) {
  const { publicRaw, privateRaw, totalRaw } = useFooterTotals(pageData);

  return (
    <AntdTable.Summary.Row>
      <AntdTable.Summary.Cell index={0} colSpan={3}>
        <strong>Total</strong>
      </AntdTable.Summary.Cell>
      <AntdTable.Summary.Cell index={1} colSpan={1}>
        <Typography.Text strong>{dollarCurrencyFormat(publicRaw)}</Typography.Text>
      </AntdTable.Summary.Cell>
      <AntdTable.Summary.Cell index={2} colSpan={1}>
        <Typography.Text strong>{dollarCurrencyFormat(privateRaw)}</Typography.Text>
      </AntdTable.Summary.Cell>
      <AntdTable.Summary.Cell index={3} colSpan={1}>
        <Typography.Text strong>{dollarCurrencyFormat(totalRaw)}</Typography.Text>
      </AntdTable.Summary.Cell>
    </AntdTable.Summary.Row>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderFooter(data: readonly any[]): ReactNode {
  const pageData = data as IAccount[];

  return <Footer pageData={pageData} />;
}

function Account(props: IAccountProps) {
  const { accounts, isLoading } = props;
  const columns: ColumnsType<IAccount> = [
    {
      title: 'Account',
      dataIndex: 'account_number',
      key: 'account_number',
      render: (value: string, record: IAccount) =>
        featureFlag['page:account_details'] ? (
          <Link to={`/public-market/account-details/${record.public_guid}`}>{value}</Link>
        ) : (
          <span>{value}</span>
        ),
    },
    {
      title: 'Account Legal Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Tax Treatment',
      dataIndex: 'tax_treatment',
      key: 'tax_treatment',
      sorter: (a, b) => a.tax_treatment.localeCompare(b.tax_treatment),
    },
    {
      title: 'Public',
      dataIndex: 'public_display',
      key: 'public_display',
      sorter: (a, b) => a.public_raw - b.public_raw,
    },
    {
      title: 'Private',
      dataIndex: 'private_display',
      key: 'private_display',
      sorter: (a, b) => a.private_raw - b.private_raw,
    },
    {
      title: 'Total',
      dataIndex: 'total_display',
      key: 'total_display',
      sorter: (a, b) => a.total_raw - b.total_raw,
      defaultSortOrder: 'descend',
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Table
          className="bg-header-white"
          columns={columns}
          pagination={false}
          dataSource={accounts}
          isLoading={isLoading}
          rowKey={(record) => record.id}
          title="MY ACCOUNTS"
          summary={renderFooter}
        />
      </Col>
    </Row>
  );
}

export default Account;
