/* eslint-disable @typescript-eslint/no-explicit-any */
import { deleteFile, getDocumentsConfig, getFiles, moveFile } from '@/services/documentService';
import customNotification from '@/utils/notification';
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  ReactNode,
  useEffect,
  useCallback,
} from 'react';
import { IDirectoryNode, IDocumentConfig, IFile } from './DocumentInterface';
import {
  attachChildrenToBaseDirectories,
  buildFolderTree,
  filterItemsInDirectory,
  findBaseDirectory,
  formatTheResponse,
  generateUniqueFileName,
  getDirectoryNameFromFileKey,
  getFileNameFromKey,
} from './DocumentsHelper';

const DocumentContext = createContext<any>(undefined);
type DirectoryNode = {
  key: string;
  title: string;
  children: DirectoryNode[];
};
// const initialBaseDirectories: DirectoryNode[] = [
//   {
//     key: 'docs/2db128fe65e8a9096411e2cb329e8ad6/Other Tax Documents/',
//     children: [],
//     title: 'Documents for Professionals',
//   },
//   {
//     key: 'client_docs/2db128fe65e8a9096411e2cb329e8ad6/',
//     children: [],
//     title: 'My Files',
//   },
// ];
export const useDocumentState = (): any => {
  const context = useContext(DocumentContext);
  if (!context) {
    throw new Error('useDirectoryState must be used within a DirectoryProvider');
  }
  return context;
};

export const DocumentProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [documentConfig, setDocumentConfig] = useState<DirectoryNode[]>([]);

  const [currentDirectory, setCurrentDirectory] = useState<string>('');
  const [baseDirectories, setBaseDirectories] = useState<DirectoryNode[]>(documentConfig);

  const [isFetchingFiles, setIsFetchingFiles] = useState<boolean>(false);

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

  const [folderStructure, setFolderStructure] = useState<any>([]);

  const [filesToMove, setFilesToMove] = useState<any>([]);

  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState<any>([]);

  const [allFiles, setAllFiles] = useState<any>([]);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState({});

  const [selectedDocumentConfig, setSelectedDocumentConfig] = useState<IDirectoryNode | undefined>(
    undefined
  );
  useEffect(() => {
    const folder = findBaseDirectory(currentDirectory, documentConfig);
    if (folder) {
      setSelectedDocumentConfig(folder);
    }
  }, [currentDirectory, documentConfig]);
  useEffect(() => {
    const fetchDocumentConfig = async () => {
      const data: IDocumentConfig[] = await getDocumentsConfig();
      const formattedDirectories = data
        ?.sort((a: any, b: any) => a.display_order - b.display_order)
        .map((directory: any) => ({
          key: `${directory.path}`,
          children: [],
          ...directory,
        }));
      setDocumentConfig(formattedDirectories);
      setCurrentDirectory(data?.[0]?.path);
      setBaseDirectories(formattedDirectories);
    };
    if (documentConfig.length === 0) {
      fetchDocumentConfig();
    }
  }, []);

  const fetchFilesAndFolder = useCallback(
    async (directory: string) => {
      setIsFetchingFiles(true);
      // const baseDirectory = findBaseDirectory(currentDirectory, baseDirectories);
      const data: any = await getFiles(directory);
      if (data?.success === true) {
        const formattedResponse = formatTheResponse(data);
        // Filter out the old items that belong to the current folder
        // const updatedFiles = allFiles.filter((file: IFile) => !file.Key.startsWith(directory));
        // const newFiles = [...updatedFiles, ...formattedResponse];
        setAllFiles((prevState: any) => {
          const updatedFiles = prevState.filter((file: IFile) => !file.Key.startsWith(directory));
          const newFiles = [...updatedFiles, ...formattedResponse];
          return newFiles;
        });
      } else {
        setAllFiles([]);
      }

      setIsFetchingFiles(false);
    },
    [currentDirectory]
  );
  useEffect(() => {
    const isBaseFolder = documentConfig.findIndex((item) => item.key === currentDirectory);
    if (isBaseFolder !== -1) {
      fetchFilesAndFolder(currentDirectory);
    }
  }, [currentDirectory]);
  useEffect(() => {
    if (searchQuery) {
      const filteredFiles = allFiles.filter(
        (file: any) => !file?.isDir && file?.name?.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredData(filteredFiles);
    } else {
      setFilteredData(allFiles);
    }
  }, [searchQuery]);

  const selectFiles = useCallback(
    (file: any) => {
      const fileIndex = selectedFiles.findIndex((item) => item.Key === file.Key);
      const moveFileIndex = filesToMove.findIndex((item: IFile) => item.Key === file.Key);

      if (fileIndex !== -1) {
        // If file already selected, unselect it
        const updatedSelectedFiles = [...selectedFiles];
        updatedSelectedFiles.splice(fileIndex, 1);
        setSelectedFiles(updatedSelectedFiles);
      } else {
        // If file not selected, select it
        setSelectedFiles([...selectedFiles, file]);
      }
      // Deselect file to move if same file selected
      if (moveFileIndex === fileIndex) {
        setFilesToMove([]);
      }
    },
    [selectedFiles, filesToMove]
  );

  useEffect(() => {
    const isBaseFolder = documentConfig.findIndex((item) => item.key === currentDirectory);
    // Generate Folder Structure only when base directories is selected
    if (isBaseFolder !== -1) {
      const tree = buildFolderTree(allFiles.filter((item: any) => item.isDir));
      setFolderStructure(tree);
      setSelectedFiles([]);
    }
  }, [allFiles]);
  useEffect(() => {
    // Attach the generated folder structures to the initial base folders
    if (folderStructure.length > 0) {
      const updatedBaseDirectories = attachChildrenToBaseDirectories(
        documentConfig,
        folderStructure
      );
      setBaseDirectories(updatedBaseDirectories);
    }
  }, [folderStructure]);

  const moveFileBetweenDirectories = useCallback(
    async ({ sourceFileKeys, destinationFolderKey }: any) => {
      const sourceFolderKey = getDirectoryNameFromFileKey(sourceFileKeys[0]);
      if (sourceFolderKey === destinationFolderKey) {
        customNotification({
          type: 'error',
          message: `The source and destination folders must be different`,
        });
        return null;
      }
      const sourceBaseFolder = findBaseDirectory(sourceFileKeys[0], baseDirectories);
      const destinationBaseFolder = findBaseDirectory(destinationFolderKey, baseDirectories);
      let files = allFiles;
      /* 
       If file move between different base folder then first we need to fetch files 
       from destination folder to check same file already exist in destination folder
       */
      if (sourceBaseFolder?.key !== destinationBaseFolder?.key) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = await getFiles(destinationFolderKey);
        if (data?.success === true) {
          files = formatTheResponse(data);
        }
      }

      const newFileKeys = sourceFileKeys
        .map((fileKey: string) => {
          const { fileNameWithExtension } = getFileNameFromKey(fileKey);
          let name;
          // Generate unique name if same file name already exist, to avoid accidental file replacement.
          if (fileNameWithExtension) {
            name = generateUniqueFileName(
              files?.length > 0 ? filterItemsInDirectory(files, destinationFolderKey) : [],
              fileNameWithExtension
            );
          }
          if (name) {
            return { newFileKey: destinationFolderKey + name, oldFileKey: fileKey };
          }

          return null;
        })
        .filter((item: any) => Boolean(item));
      const res = await moveFile(newFileKeys as { newFileKey: string; oldFileKey: string }[]);
      if (res?.success === true) {
        // Reset selected files on change directory
        setSelectedFiles([]);
        setFilesToMove([]);

        /* 
           If destination folder key is not a base folder then need to trigger fetchFilesAndFolder
           to update files in destination folder.
          */
        await fetchFilesAndFolder(destinationFolderKey);

        // Delete the moved file from source directory
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setAllFiles((prevState: IFile[]) =>
          prevState.filter((file: IFile) => !sourceFileKeys.includes(file.Key))
        );

        /* 
          Update current directory with destination folder, 
          If destinationFolderKey is base directory then only API should get trigger 
          */
        setCurrentDirectory(destinationFolderKey);
        return true;
      }
      return null;
    },
    [currentDirectory]
  );

  const openConfirmationModal = useCallback(
    (id: string) => {
      setIsConfirmationModalOpen({
        [id]: true,
      });
    },
    [isConfirmationModalOpen]
  );

  const closeConfirmationModal = useCallback(
    (id: string) => {
      setIsConfirmationModalOpen({
        [id]: false,
      });
    },
    [isConfirmationModalOpen]
  );
  const onConfirmDelete = useCallback(
    async (fileKeys: string[], id: string) => {
      // const fileKeys = selectedFiles.map((item: any) => item.Key);
      if (fileKeys.length > 0) {
        const res = await deleteFile(fileKeys);
        if (res?.success === true) {
          // After successful Delete fetch files and folder to update the state
          fetchFilesAndFolder(currentDirectory);
        }
        closeConfirmationModal(id);
        return true;
      }
      customNotification({ type: 'error', message: 'No Files Selected!' });
      return false;
    },
    [isConfirmationModalOpen]
  );

  const value = useMemo(
    () => ({
      currentDirectory,
      setCurrentDirectory,

      isFetchingFiles,
      setIsFetchingFiles,
      expandedKeys,
      setExpandedKeys,
      folderStructure,
      setFolderStructure,
      filesToMove,
      setFilesToMove,
      selectedFiles,
      setSelectedFiles,
      searchQuery,
      setSearchQuery,
      filteredData,
      setFilteredData,
      fetchFilesAndFolder,
      allFiles,
      baseDirectories,
      setBaseDirectories,
      selectFiles,
      documentConfig,
      setAllFiles,
      moveFileBetweenDirectories,
      onConfirmDelete,
      openConfirmationModal,
      closeConfirmationModal,
      isConfirmationModalOpen,
      selectedDocumentConfig,
    }),
    [
      currentDirectory,
      setCurrentDirectory,

      isFetchingFiles,
      setIsFetchingFiles,
      expandedKeys,
      setExpandedKeys,
      folderStructure,
      setFolderStructure,
      filesToMove,
      setFilesToMove,
      selectedFiles,
      setSelectedFiles,
      searchQuery,
      setSearchQuery,
      filteredData,
      setFilteredData,
      fetchFilesAndFolder,
      allFiles,
      baseDirectories,
      setBaseDirectories,
      selectFiles,
      setAllFiles,
      moveFileBetweenDirectories,
      onConfirmDelete,
      openConfirmationModal,
      closeConfirmationModal,
      isConfirmationModalOpen,
      documentConfig,
      selectedDocumentConfig,
    ]
  );

  return <DocumentContext.Provider value={value}>{children}</DocumentContext.Provider>;
};
