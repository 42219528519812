import { IPortfolioDetails } from '@/views/public-market/portfolio-details/interface';

export const mockPortfolioDetails: IPortfolioDetails = {
  portfolio_name: 'BIP Test Portfolio',
  equity_allocation: 'Balanced',
  portfolio_type: 'Primary',
  allocations: [
    {
      display_name: 'Equity',
      allocation: [
        {
          display_name: 'Public',
          market_value_raw: 18.65,
          market_value_display: '$18.65',
          allocation_raw: 21.2,
          allocation_display: '21.2%',
        },
        {
          display_name: 'Private',
          market_value_raw: 8.2,
          market_value_display: '$8.2',
          allocation_raw: 9.5,
          allocation_display: '9.50%',
        },
        {
          display_name: 'BIP Hedged Equity',
          market_value_raw: 18.2,
          market_value_display: '$18.2',
          allocation_raw: 19.5,
          allocation_display: '19.50%',
        },
        {
          display_name: 'Real Estate',
          market_value_raw: 84.2,
          market_value_display: '$84.2',
          allocation_raw: 94.5,
          allocation_display: '94.50%',
        },
      ],
    },
    {
      display_name: 'Fixed Income',
      allocation: [
        {
          display_name: 'Public',
          market_value_raw: 48.2,
          market_value_display: '$48.2',
          allocation_raw: 49.5,
          allocation_display: '49.50%',
        },
        {
          display_name: 'Private',
          market_value_raw: 86.2,
          market_value_display: '$86.2',
          allocation_raw: 96.5,
          allocation_display: '96.50%',
        },
        {
          display_name: 'BIP Hedged Yield',
          market_value_raw: 78.2,
          market_value_display: '$78.2',
          allocation_raw: 79.5,
          allocation_display: '79.50%',
        },
        {
          display_name: 'Short-Term Tactical',
          market_value_raw: 6.2,
          market_value_display: '$6.2',
          allocation_raw: 5.5,
          allocation_display: '5.50%',
        },
      ],
    },
  ],

  accounts: [
    {
      account_number: '*****6789',
      account_name: 'BIP Test - Individual',
      market_value_raw: 3689.45,
      market_value_display: '$3689.45',
      public_guid: '61a7ac10-3341-464f-8d24-5736aa8ffe1c',
    },
  ],
  performance: {
    return_date_display: '2023-10-10',
    inception_date_raw: '2023-01-11',
    inception_date_display: '(01/2023)',
    as_of: '2023-12-01',
    as_of_display: '01/12/2023',
    returns: [
      {
        name: '1 Month',
        return_raw: 51,
        return_display: '51%',
      },
      {
        name: '3 Month',
        return_raw: 20,
        return_display: '20%',
      },
      {
        name: 'YTD',
        return_raw: 34,
        return_display: '34%',
      },
      {
        name: '1 Year',
        return_raw: 37,
        return_display: '37%',
      },
      {
        name: '3 Year',
        return_raw: 64,
        return_display: '64%',
      },
      {
        name: '5 Year',
        return_raw: 28,
        return_display: '28%',
      },
      {
        name: '10 Year',
        return_raw: 38,
        return_display: '38%',
      },
      {
        name: 'Inception',
        return_raw: 85,
        return_display: '85%',
      },
    ],
  },
  positions: [
    {
      position_id: '1',
      symbol: 'CASH',
      description: 'This is description for position',
      quantity_raw: 5.6,
      quantity_display: '5.6',
      price_raw: 100,
      price_display: '$100',
      amount_raw: 560,
      amount_display: '$560',
    },
  ],
  comments: [
    {
      id: '1',
      date_raw: '2023-09-29',
      date_display: '09/29/2023',
      comment: 'This is comment for current position',
    },
  ],
};
