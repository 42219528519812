import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiErrorMessage } from '../utils/helper';
import HttpClient from '../utils/HttpClient';

// ==============================|| Public Market ||============================== //

export const getPublicMarket = createAsyncThunk('public-market', async (_, thunkAPI) => {
  try {
    const response = await HttpClient.get(`/public-market/`);
    return { data: response.data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});

export const getAccountDetailsService = createAsyncThunk(
  'account-details',
  async (id: string, thunkAPI) => {
    try {
      const response = await HttpClient.get(`/accounts/${id}`);
      return { data: response.data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return thunkAPI.rejectWithValue(getApiErrorMessage(error));
    }
  }
);

export const getPortfolioDetails = createAsyncThunk(
  'portfolio-details',
  async (id: string, thunkAPI) => {
    try {
      const response = await HttpClient.get(`/portfolio/${id}`);
      return { data: response.data };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return thunkAPI.rejectWithValue(getApiErrorMessage(error));
    }
  }
);

const publicMarketService = {
  getPublicMarket,
};
export default publicMarketService;
