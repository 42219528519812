import Card from '@/components/Card';
import { dollarCurrencyFormat } from '@/utils/helper';
import { IPrivateMarketPositionSummary } from '../interface';
import './PrivateMarketDetails.css';

function financialData(data: IPrivateMarketPositionSummary) {
  return [
    {
      name: '+ Total Contributions',
      value: data?.committed_display || '$0.00',
      breakLine: false,
    },
    {
      name: '- Total Distributions',
      value: data?.distributed_display || '$0.00',
      breakLine: true,
    },
    {
      name: '= Investment Balance',
      value: dollarCurrencyFormat(data.committed_raw - data.distributed_raw) || '$0.00',
      breakLine: true,
      bottomSpace: 10,
    },
    {
      name: 'Distributions to Paid-In Capital',
      value: data?.dpi || '$0.00',
      breakLine: false,
      bottomSpace: 10,
    },
    // TODO: investment.efv_display doubt
    {
      name: 'Portfolio Value',
      value: '$0.00',
      breakLine: false,
    },
    {
      name: 'Ownership (%)',
      value: data?.ownership_display || '$0.00',
      breakLine: false,
    },
    {
      name: 'Residual Value',
      value: data?.efv_display || '$0.00',
      breakLine: false,
      bottomSpace: 10,
    },
    {
      name: 'Total Value to Paid-In Capital',
      value: data?.tvpi || '$0.00',
      breakLine: false,
      bottomSpace: 10,
    },
    {
      name: 'IRR',
      value: data?.net_irr_display || '0.00%',
      breakLine: false,
    },
  ];
}

interface IFinancialOverviewProps {
  data: IPrivateMarketPositionSummary;
  isLoading: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function FinancialOverview(props: IFinancialOverviewProps) {
  const { data, isLoading } = props;

  return (
    <Card title="Financial Overview" isLoading={isLoading}>
      <div style={{ margin: 'auto', width: '80%' }}>
        {financialData(data).map((item) => (
          <div style={{ marginBottom: item.bottomSpace }} key={item.name}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p>
                <strong>{item.name}</strong>
              </p>
              <p>{item.value}</p>
            </div>
            {item.breakLine && <div style={{ border: '1px solid black' }} />}
          </div>
        ))}
      </div>
    </Card>
  );
}

export default FinancialOverview;
