import { IDashboardData } from '@/views/dashboard/interface';

export const mockDashboardData: IDashboardData = {
  household_name: 'BIP Test Household',
  household_allocation: [
    {
      display_name: 'Equity',
      allocation: [
        {
          display_name: 'Public',
          market_value_raw: '390.0000',
          market_value_display: '$390.00',
          allocation_raw: '9.11214953',
          allocation_display: '9.11%',
        },
        {
          display_name: 'Private',
          market_value_raw: '280.0000',
          market_value_display: '$280.00',
          allocation_raw: '6.54205607',
          allocation_display: '6.54%',
        },
        {
          display_name: 'BIP Hedged Equity',
          market_value_raw: '210.0000',
          market_value_display: '$210.00',
          allocation_raw: '4.90654206',
          allocation_display: '4.91%',
        },
        {
          display_name: 'Real Estate',
          market_value_raw: '290.0000',
          market_value_display: '$290.00',
          allocation_raw: '6.77570093',
          allocation_display: '6.78%',
        },
        {
          display_name: 'Concentrated Stock',
          market_value_raw: '230.0000',
          market_value_display: '$230.00',
          allocation_raw: '5.37383178',
          allocation_display: '5.37%',
        },
      ],
    },
    {
      display_name: 'Fixed Income',
      allocation: [
        {
          display_name: 'Public',
          market_value_raw: '1540.0000',
          market_value_display: '$1,540.00',
          allocation_raw: '35.98130841',
          allocation_display: '35.98%',
        },
        {
          display_name: 'Private',
          market_value_raw: '300.0000',
          market_value_display: '$300.00',
          allocation_raw: '7.00934579',
          allocation_display: '7.01%',
        },
        {
          display_name: 'BIP Hedged Yield',
          market_value_raw: '220.0000',
          market_value_display: '$220.00',
          allocation_raw: '5.14018692',
          allocation_display: '5.14%',
        },
        {
          display_name: 'Short-Term Tactical',
          market_value_raw: '240.0000',
          market_value_display: '$240.00',
          allocation_raw: '5.60747664',
          allocation_display: '5.61%',
        },
      ],
    },
  ],

  equityTotal_raw: '1400.0000',
  equityTotal_display: '$1,400.00',
  fixedTotal_raw: '2300.0000',
  fixedTotal_display: '$2,300.00',
  grandTotal_raw: '4280.0000',
  grandTotal_display: '$4,280.00',
  private_pending: '330.0000',
  private_pending_household_percentage: '7.71028037',
  private_pending_household_percentage_display: '7.71%',
  private_pending_display: '$330.00',
  total_equity_household_percentage_display: '32.71%',
  total_household_percentage_display: '100.00%',
  total_fixed_income_household_percentage_display: '53.74%',
  public_strategy_concentrated_stock_visible: 1,
  private_pending_visible: 1,

  liquidity_allocation: [
    {
      display_name: 'Public Investments',
      market_value_raw: 89.2,
      market_value_display: '$89.2',
      allocation_raw: 95.5,
      allocation_display: '95.50%',
    },
    {
      display_name: 'Private Investments',
      market_value_raw: 38.2,
      market_value_display: '$38.2',
      allocation_raw: 39.5,
      allocation_display: '39.50%',
    },
  ],
  tax_allocation: [
    {
      display_name: 'Taxable',
      market_value_raw: 82.2,
      market_value_display: '$82.2',
      allocation_raw: 92.5,
      allocation_display: '92.50%',
    },
    {
      display_name: 'Tax Advantaged',
      market_value_raw: 88.2,
      market_value_display: '$88.2',
      allocation_raw: 99.5,
      allocation_display: '99.50%',
    },
  ],
};
