/* eslint-disable @typescript-eslint/no-explicit-any */
/*
   creating custom history to use react navigation inside axios interceptors and inside redux logics 
   because we can't use useNavigate hooks outside functional component
   Reference: https://stackoverflow.com/questions/69953377/react-router-v6-how-to-use-navigate-redirection-in-axios-interceptor

   */

import { createBrowserHistory } from 'history';

const history = createBrowserHistory() as any;

export default history;
