import { Form, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { forgotPassword } from '@/services/authService';
import { useState } from 'react';
import AuthContainer from '../auth-container/AuthContainer';
import { authMessages } from '../../../utils/messages';

const { PLEASE_ENTER_VALID_EMAIL_ADDRESS } = authMessages;

function ForgotPassword() {
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const navigate = useNavigate();
  const onFinish = async (values: { email: string }) => {
    const { email } = values;
    setIsSubmitLoading(true);
    const response = await forgotPassword({ email });
    if (response?.data?.success === true) {
      navigate('/reset-password');
    }
    setIsSubmitLoading(false);
  };

  return (
    <AuthContainer>
      <CustomHelmet title="Forgot Password" />
      <p className="bold uppercase">Forgot Password?</p>
      <p>Please enter the email associated with this account.</p>
      <Form initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item
          name="email"
          rules={[{ required: true, type: 'email', message: PLEASE_ENTER_VALID_EMAIL_ADDRESS }]}
        >
          <Input
            data-testid="email"
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="E-Mail Address"
          />
        </Form.Item>
        <div className="auth-container-footer">
          <Form.Item>
            <Link to="/">
              <Button
                data-testid="submit"
                type="primary"
                htmlType="submit"
                className="uppercase"
                style={{ backgroundColor: '#09323C', color: '#66BFBD' }}
              >
                Cancel
              </Button>
            </Link>
          </Form.Item>
          <Form.Item>
            <Button
              data-testid="submit"
              type="primary"
              htmlType="submit"
              className="uppercase"
              style={{ backgroundColor: '#09323C', color: '#66BFBD' }}
              loading={isSubmitLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </div>
      </Form>
    </AuthContainer>
  );
}
export default ForgotPassword;
