import { getApiErrorMessage } from '@/utils/helper';
import { createAsyncThunk } from '@reduxjs/toolkit';
import HttpClient from '../utils/HttpClient';

export const getTeam = createAsyncThunk('team', async (_, thunkAPI) => {
  try {
    const response = await HttpClient.get('/team');
    if (response.status === 200) {
      return { data: response.data };
    }
    return { data: [] };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});
