import { Navigate, useRoutes } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';
import MainRoutes from './MainRoutes';
import MaintenancePage from '../views/maintenance';
import { config } from '../utils/config';

export default function Routes() {
  const routes = config.MAINTENANCE_MODE
    ? [
        { path: '/maintenance', element: <MaintenancePage /> },
        { path: '*', element: <Navigate to="/maintenance" /> },
      ]
    : [MainRoutes, AuthRoutes];

  return useRoutes(routes);
}
