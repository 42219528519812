import { memo } from 'react';
import { Modal, Typography } from 'antd';

function ConfirmationModal({
  isModalOpen,
  onOk,
  onCancel,
  action,
  id,
}: {
  isModalOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  action: string;
  id: string;
}) {
  const messages: { [action: string]: string } = {
    delete: 'Are you sure to Delete?',
  };
  return (
    <Modal
      key={id}
      styles={{ body: { width: 400 } }}
      title="Delete File"
      open={isModalOpen || false}
      onOk={onOk}
      onCancel={onCancel}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      okText="Yes"
      maskClosable={false}
    >
      <Typography.Paragraph>{messages[action] || ''}</Typography.Paragraph>
    </Modal>
  );
}

export default memo(ConfirmationModal);
