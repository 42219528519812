import { Button, Form, Input, Space, Typography } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { useEffect, useState } from 'react';
import { authMessages } from '@/utils/messages';
import Card from '@/components/Card';
import { changePassword } from '@/services/authService';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { validatePassword } from '@/utils/helper';
import { theme } from '@/utils/theme';

function ChangePassword() {
  const { PLEASE_ENTER_PASSWORD, PLEASE_ENTER_CONFIRM_PASSWORD } = authMessages;
  const [isChangePasswordLoading, setIsChangePasswordLoading] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const [form] = Form.useForm();

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const handleReset = () => {
    form.resetFields();
    setIsFormValid(false);
  };
  const onFinish = async (formValues: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    setIsChangePasswordLoading(true);
    try {
      const res = await changePassword(formValues);
      if (res?.success === true) {
        handleReset();
      }
      setIsChangePasswordLoading(false);
    } catch (err) {
      setIsChangePasswordLoading(false);
    }
  };
  const passwordRequirements = [
    'Passwords must be at least 8 characters long',
    'Passwords must include at least one uppercase letter (A-Z)',
    'Passwords must include at least one lowercase letter (a-z)',
    'Passwords must include at least one number (0-9)',
  ];

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  return (
    <>
      <CustomHelmet title="Change Password" />
      <Card title="Change Password">
        <Typography.Title level={4}>
          Passwords must meet the following requirements:
        </Typography.Title>
        <ul>
          {passwordRequirements.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>

        <Form
          form={form}
          {...formItemLayout}
          labelAlign="left"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          style={{ maxWidth: 500 }}
        >
          <Form.Item
            label="Current Password"
            name="currentPassword"
            rules={[{ required: true, message: PLEASE_ENTER_PASSWORD }]}
          >
            <Input.Password
              data-testid="old-password"
              placeholder="Current Password"
              autoComplete="current-password"
            />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[{ required: true, validator: validatePassword }]}
          >
            <Input.Password
              data-testid="password"
              placeholder="New Password - Minimum 8 Characters"
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={['newPassword']}
            hasFeedback
            rules={[
              { required: true, message: PLEASE_ENTER_CONFIRM_PASSWORD },
              ({ getFieldValue }: { getFieldValue: (name: string) => string }) => ({
                async validator(_: RuleObject, value: StoreValue) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return;
                  }
                  throw new Error('The two passwords that you entered do not match!');
                },
              }),
            ]}
          >
            <Input.Password
              data-testid="confirmPassword"
              placeholder='Confirm Password - Same as "New Password"'
              autoComplete="new-password"
            />
          </Form.Item>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form.Item />

            <Form.Item>
              <Space direction="horizontal">
                <Button type="default" onClick={handleReset} style={{ marginLeft: '10px' }}>
                  Reset
                </Button>
                <Button
                  loading={isChangePasswordLoading}
                  data-testid="submit"
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: !isFormValid ? theme.lightGray : theme.darkCyan,
                    color: !isFormValid ? theme.grayColor : theme['@link-color'],
                  }}
                  disabled={!isFormValid}
                >
                  Submit
                </Button>
              </Space>
            </Form.Item>
          </div>
        </Form>
      </Card>
    </>
  );
}
export default ChangePassword;
