import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import Table from '@/components/Table';

import { featureFlag } from '@/utils/featureFlags';
import { IPortfolio, IPortfolioProps } from './interface';

function Portfolio(props: IPortfolioProps) {
  const { portfolios, isLoading } = props;
  const columns: ColumnsType<IPortfolio> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      render: (text: string, record: any, index: number) =>
        featureFlag['page:portfolio_details'] ? (
          <Link to={`/portfolios/${record.id}`}>{text}</Link>
        ) : (
          <span>{text}</span>
        ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: 'Equity Allocation',
      dataIndex: 'percentage_display',
      key: 'percentage_display',
      sorter: (a, b) => a.percentage_raw - b.percentage_raw,
    },
    {
      title: 'Market Value',
      dataIndex: 'market_value_display',
      key: 'market_value_display',
      sorter: (a, b) => a.market_value_raw - b.market_value_raw,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Table
          className="bg-header-white"
          pagination={false}
          columns={columns}
          dataSource={portfolios}
          title="MY PORTFOLIOS"
          isLoading={isLoading}
          rowKey={(record) => record.id}
        />
      </Col>
    </Row>
  );
}

export default Portfolio;
