/* eslint-disable @typescript-eslint/no-explicit-any */
import { IGoalsPageData } from '@/views/goals/interface';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { getGoals } from '../../services/goalService';

export interface IGoalsSlice {
  isGetGoalLoading: boolean;
  isGetGoalError: boolean;
  getGoalErrorMessage: string;
  goalData: IGoalsPageData;
}
const initialState: IGoalsSlice = {
  isGetGoalLoading: false,
  isGetGoalError: false,
  getGoalErrorMessage: '',
  goalData: {
    goals: [],
    historicalValues: [],
    milestones: [],
  },
};

function goalsReducer(builder: ActionReducerMapBuilder<IGoalsSlice>) {
  builder
    .addCase(getGoals.pending, (state: IGoalsSlice) => ({
      ...state,
      isGetGoalLoading: true,
      isGetGoalError: false,
      getGoalErrorMessage: '',
    }))
    .addCase(getGoals.fulfilled, (state: IGoalsSlice, action: any) => ({
      ...state,
      isGetGoalLoading: false,
      goalData: action.payload?.data || [],
    }))
    .addCase(getGoals.rejected, (state: IGoalsSlice, action: any) => ({
      ...state,
    }));
}

const goalSlice = createSlice({
  name: 'goals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    goalsReducer(builder);
  },
});

export default goalSlice.reducer;
