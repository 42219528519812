import React, { useEffect } from 'react';
import { DownOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Select, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  getApiErrorMessage,
  getValueLocalStorage,
  isPWA,
  setValueLocalStorage,
} from '@/utils/helper';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store';
import { setSelectedHousehold } from '@/store/common/commonSlice';
import { getHouseholdList } from '@/services/commonService';
import HttpClient from '@/utils/HttpClient';
import customNotification from '@/utils/notification';

const { Header } = Layout;
function MyHeader(props: { collapsed: boolean; setCollapsed: (collapsed: boolean) => void }) {
  const { collapsed, setCollapsed } = props;
  const dispatch = useDispatch<AppDispatch>();
  const householdLists = useSelector((state: AppState) => state.common.householdLists);
  const selectedHousehold = getValueLocalStorage('selectedHousehold');
  const navigate = useNavigate();
  const name = getValueLocalStorage('name');
  const isPwa = isPWA();
  useEffect(() => {
    // Get household lists only if it is PWA
    if (isPwa) {
      dispatch(getHouseholdList());
    }
  }, []);

  const updateHouseholdId = async (value: number | string) => {
    try {
      // update selected household in redux store
      dispatch(setSelectedHousehold(value));
      // update selected household in local storage
      setValueLocalStorage('selectedHousehold', value);
      // update selected household in DB
      await HttpClient.get(`/update-household/${value}`);
    } catch (error) {
      const message = getApiErrorMessage(error);
      customNotification({ type: 'error', message });
    }
  };
  const menu = [
    {
      label: (
        <Button type="text" onClick={() => navigate('/2fa-setup')}>
          Two Factor Settings
        </Button>
      ),
      key: '2fa-setup',
    },
    {
      label: (
        <Button type="text" onClick={() => navigate('/change-password')}>
          Change Password
        </Button>
      ),
      key: 'change-password',
    },
    {
      label: (
        <Button
          type="text"
          onClick={() => {
            window.localStorage.clear();
            navigate('/login');
          }}
        >
          Logout
        </Button>
      ),
      key: 'logout',
    },
  ];
  return (
    <Header
      className="site-layout-background"
      style={{
        padding: 0,
        zIndex: 1000,
        marginLeft: !collapsed ? 210 : 100,
        // marginRight: 16,
      }}
    >
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: 'trigger',
        onClick: () => setCollapsed(!collapsed),
      })}
      <Space direction="horizontal" style={{ float: 'right' }}>
        {isPwa && (
          <Select
            style={{ width: 200 }}
            defaultValue={Number(selectedHousehold) > 0 ? Number(selectedHousehold) : undefined}
            // value={selectedHousehold}
            showSearch
            placeholder="Select Household"
            optionFilterProp="label"
            onChange={(value) => {
              updateHouseholdId(value);
            }}
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={householdLists?.map((household) => ({
              value: household.householdid,
              label: household.friendlyname,
            }))}
          />
        )}
        <Dropdown menu={{ items: menu }}>
          <Button onClick={(e) => e.preventDefault()} style={{ border: 'none' }}>
            <Space>
              Hi, <b>{name}</b>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Space>
    </Header>
  );
}
export default MyHeader;
