import { Card as AntdCard, CardProps, Skeleton } from 'antd';
import { CSSProperties, ReactNode } from 'react';
import CardHeader from './CardHeader';

export interface ICard extends CardProps {
  children: ReactNode;
  iconTitle?: ReactNode;
  renderToolbar?: ReactNode;
  title: string;
  bodyStyle?: CSSProperties;
  isLoading?: boolean;
  subTitle?: string;
}
function Card(props: ICard) {
  const { children, renderToolbar, iconTitle, title, bodyStyle, isLoading, subTitle, ...rest } =
    props;
  return (
    <AntdCard
      styles={{ body: bodyStyle }}
      title={
        <CardHeader
          subTitle={subTitle}
          title={title?.toUpperCase()}
          renderToolbar={renderToolbar}
          iconTitle={iconTitle}
          {...rest}
        />
      }
    >
      {isLoading ? <Skeleton active /> : children}
    </AntdCard>
  );
}

Card.defaultProps = {
  iconTitle: '',
  renderToolbar: null,
  bodyStyle: null,
  isLoading: false,
  subTitle: '',
};
export default Card;
