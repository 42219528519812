/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/naming-convention */
import { Col, Empty, Row, Typography } from 'antd';
import moment from 'moment';
import Card from '@/components/Card';
import { theme } from '@/utils/theme';
import { IAllocationColumns, IAllocationDataRowProps } from '@/views/dashboard/interface';
import './HouseholdAllocationSummary.css';
import AllocationChart from './AllocationChart';

function DataRow(props: IAllocationDataRowProps) {
  const {
    title,
    columns,
    footer,
    showColHeader = false,
    public_strategy_concentrated_stock_visible,
  } = props;

  return (
    <>
      <div className="DataRow-title">
        <Typography.Text className="uppercase title-text" style={{ color: theme.secondaryColor }}>
          {title}
        </Typography.Text>
        {showColHeader && (
          <>
            <Typography.Text className="uppercase market-value-header">
              Market Value
            </Typography.Text>
            <Typography.Text className="uppercase allocation-header">Allocation %</Typography.Text>
          </>
        )}
      </div>
      {columns.map((column: IAllocationColumns) => {
        const { display_name, allocation_display, market_value_display } = column;
        if (
          display_name === 'Concentrated Stock' &&
          public_strategy_concentrated_stock_visible !== 1
        ) {
          return null;
        }
        return (
          <div key={display_name} className="DataRow-column">
            <Typography.Paragraph className="column-name">{display_name}</Typography.Paragraph>
            <Typography.Paragraph className="column-value">
              {market_value_display}
            </Typography.Paragraph>
            <Typography.Paragraph className="column-percentage">
              {allocation_display}
            </Typography.Paragraph>
          </div>
        );
      })}

      <div className="DataRow-divider" />
      <div className="DataRow-footer">
        <Typography.Paragraph className="footer-name">{footer?.name}</Typography.Paragraph>
        <Typography.Paragraph className="footer-value">{footer?.value}</Typography.Paragraph>
        <Typography.Paragraph className="footer-percentage">
          {footer?.percentage}
        </Typography.Paragraph>
      </div>
    </>
  );
}
function HouseholdAllocationSummary(props: {
  isLoading: boolean;
  // TODO: add type for dashboardData
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dashboardData: any;
}) {
  const { isLoading, dashboardData } = props;
  const { household_allocation: allocation } = dashboardData;
  const {
    equityTotal_display,
    fixedTotal_display,
    grandTotal_display,
    private_pending_display,
    private_pending_household_percentage_display,
    total_household_percentage_display,
    total_fixed_income_household_percentage_display,
    total_equity_household_percentage_display,
    private_pending_visible,
    public_strategy_concentrated_stock_visible,
  } = dashboardData;
  const asOfDate = new Date();

  return (
    <Card
      title={`HOUSEHOLD ALLOCATION SUMMARY ${
        asOfDate && `(As of ${moment(asOfDate).format('MMMM DD, YYYY')})`
      }`}
      isLoading={isLoading}
    >
      {Array.isArray(allocation) && allocation.length > 0 ? (
        <Row>
          <Col span={12}>
            {allocation.map((item) => {
              const isEquity = item.display_name === 'Equity';
              const isFixedIncome = item.display_name === 'Fixed Income';

              return (
                <DataRow
                  key={item.display_name}
                  title={item.display_name}
                  columns={item.allocation}
                  footer={{
                    name: `${item.display_name} Total`,
                    value: isEquity ? equityTotal_display : isFixedIncome ? fixedTotal_display : '',
                    percentage: isEquity
                      ? total_equity_household_percentage_display
                      : isFixedIncome
                      ? total_fixed_income_household_percentage_display
                      : '',
                  }}
                  showColHeader={true}
                  public_strategy_concentrated_stock_visible={
                    public_strategy_concentrated_stock_visible
                  }
                />
              );
            })}
            {private_pending_visible === 1 && (
              <>
                <div className="hr-divider" />
                <div className="total-row">
                  <p className="total-name">Pending Private Investment</p>
                  <p className="total-value">{private_pending_display}</p>
                  <p className="total-percentage">{private_pending_household_percentage_display}</p>
                </div>
              </>
            )}

            <div className="hr-divider" />
            <div className="total-row">
              <p style={{ color: theme.secondaryColor }} className="uppercase total-name">
                Household Total
              </p>
              <p style={{ color: theme.secondaryColor }} className="total-value">
                {grandTotal_display}
              </p>
              <p style={{ color: theme.secondaryColor }} className="total-percentage">
                {total_household_percentage_display}
              </p>
            </div>
            <div className="hr-divider" />
          </Col>
          <Col span={12}>
            <AllocationChart
              dashboardData={dashboardData}
              allocation={allocation}
              isLoading={isLoading}
            />
          </Col>
        </Row>
      ) : (
        <Empty />
      )}
    </Card>
  );
}

export default HouseholdAllocationSummary;
