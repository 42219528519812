import { Col, Row } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Table from '@/components/Table';
import moment from 'moment';
import { ITransaction } from './interface';

function RecentTransactions(props: { recentTransactions: ITransaction[]; isLoading: boolean }) {
  const { recentTransactions, isLoading } = props;
  const columns: ColumnsType<ITransaction> = [
    {
      title: 'Date',
      dataIndex: 'transaction_date_display',
      key: 'transaction_date_display',
      sorter: (a, b) =>
        moment(a?.transaction_date_raw).valueOf() - moment(b?.transaction_date_raw).valueOf(),
      defaultSortOrder: 'descend',
    },
    {
      title: 'Type',
      dataIndex: 'transaction_type',
      key: 'transaction_type',
      sorter: (a, b) => a?.transaction_type.localeCompare(b?.transaction_type),
    },
    {
      title: 'Symbol',
      dataIndex: 'symbol',
      key: 'symbol',
      sorter: (a, b) => a?.symbol.localeCompare(b?.symbol),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity_display',
      key: 'quantity_display',
      sorter: (a, b) => a.quantity_raw - b.quantity_raw,
    },
    {
      title: 'Price',
      dataIndex: 'price_display',
      key: 'price_display',
      sorter: (a, b) => a.price_raw - b.price_raw,
    },
    {
      title: 'Market Value',
      dataIndex: 'amount_display',
      key: 'amount_display',
      sorter: (a, b) => a.amount_raw - b.amount_raw,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <Table
          className="bg-header-white"
          columns={columns}
          dataSource={recentTransactions}
          isLoading={isLoading}
          title="Recent Transactions"
          rowKey={(record) => record.transaction_id}
        />
      </Col>
    </Row>
  );
}

export default RecentTransactions;
