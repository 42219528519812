import Card from '@/components/Card';
import { Descriptions, Empty } from 'antd';
import { IPortfolioPerformance } from './interface';

function PortfolioPerformance(props: { isLoading: boolean; performance: IPortfolioPerformance }) {
  const { isLoading, performance } = props;
  const { returns = [], inception_date_display = '', return_date_display = '' } = performance || {};
  return (
    <Card
      title={`Portfolio Performance (As of ${return_date_display}; Net of BIP Advisory Fees)`}
      isLoading={isLoading}
    >
      {returns?.length > 0 ? (
        <Descriptions
          layout="vertical"
          column={returns?.length < 10 ? returns?.length : 10}
          labelStyle={{ fontWeight: 'bold', color: '#000' }}
          colon={false}
        >
          {returns?.map((item) => {
            const { name, return_display, return_raw } = item;
            return (
              <Descriptions.Item label={name} key={name}>
                <span style={return_raw < 0 ? { color: 'red' } : {}}>{`${return_display}`} </span>
                <span style={{ marginLeft: 5 }}>
                  {name?.toLowerCase()?.includes('inception') && `${inception_date_display}`}
                </span>
              </Descriptions.Item>
            );
          })}
        </Descriptions>
      ) : (
        <Empty />
      )}
    </Card>
  );
}

export default PortfolioPerformance;
