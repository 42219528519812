import Card from '@/components/Card';
import { Button, List } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { theme } from '@/utils/theme';
import { InvestmentNewsItem } from '../interface';

import './PrivateMarketDetails.css';

interface IInvestmentNews {
  data: InvestmentNewsItem[];
  isLoading: boolean;
}

function InvestmentNews(props: IInvestmentNews) {
  const navigate = useNavigate();
  const { data, isLoading } = props;
  return (
    <Card title="Investment News" isLoading={isLoading}>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Link to={`/updates/${item.update_id}`} style={{ color: theme['@link-color'] }}>
                  {item?.title}
                </Link>
              }
              description={
                <div className="container">
                  <div>{item.date_display}</div>
                  <div className="content">{item.description}</div>
                  <div className="button">
                    <Button type="primary" onClick={() => navigate(`/updates/${item.update_id}`)}>
                      Read More
                    </Button>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
}

export default InvestmentNews;
