import { Col, Row, Space } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '@/store';
import { getAccountDetailsService } from '@/services/publicMarketService';
import { useParams } from 'react-router-dom';
import useShowErrorToast from '@/hooks/useShowErrorToast';
import { clearAccountDetailsErrorState } from '@/store/public-market/publicMarketSlice';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import RecentTransactions from './RecentTransaction';
import PublicHolding from '../PublicHolding';
import AccountInfo from './AccountInfo';

function AccountDetailsPage() {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getAccountDetailsService(id));
    }
  }, [dispatch, id]);

  const {
    data,
    getAccountDetailsErrorMessage,
    isGetAccountDetailsError,
    isGetAccountDetailsLoading,
  } = useSelector((state: AppState) => state.publicMarket.accountDetails);

  useShowErrorToast({
    isError: isGetAccountDetailsError,
    errorMessage: getAccountDetailsErrorMessage,
    clearErrorState: clearAccountDetailsErrorState,
  });

  const { transactions, positions, account_name, account_nickname, account_number } = data;
  const accountInfo = { account_name, account_nickname, account_number };

  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>
      <CustomHelmet title={`Account - ${account_name}`} />

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <AccountInfo isLoading={isGetAccountDetailsLoading} accountInfo={accountInfo} />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <PublicHolding isLoading={isGetAccountDetailsLoading} publicHoldings={positions} />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col span={24}>
          <RecentTransactions
            isLoading={isGetAccountDetailsLoading}
            recentTransactions={transactions}
          />
        </Col>
      </Row>
    </Space>
  );
}

export default AccountDetailsPage;
