import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AppDispatch, AppState } from '@/store';

import { getPublicMarket } from '@/services/publicMarketService';
import useShowErrorToast from '@/hooks/useShowErrorToast';
import { clearPublicMarketErrorState } from '@/store/public-market/publicMarketSlice';
import CustomBreadcrumb from '@/components/Breadcrumb';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import Account from './Account';
import RecentTrade from './RecentTrade';
import PortfolioManagerComments from './PortfolioManagerComments';
import PublicHolding from './PublicHolding';
import Portfolio from './Portfolio';
import PublicAllocation from './public-allocation/PublicAllocation';

function PublicMarket() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getPublicMarket());
  }, [dispatch]);

  const {
    allocation,
    accounts,
    positions,
    pmComments,
    transactions,
    portfolios,
    isGetPublicMarketLoading,
    isGetPublicMarketError,
    getPublicMarketErrorMessage,
  } = useSelector((state: AppState) => state.publicMarket.publicMarketDetails);
  useShowErrorToast({
    isError: isGetPublicMarketError,
    errorMessage: getPublicMarketErrorMessage,
    clearErrorState: clearPublicMarketErrorState,
  });

  return (
    <>
      <CustomHelmet title="Public Markets Overview" />

      <CustomBreadcrumb
        paths={[
          {
            path: '',
            title: 'Summary',
          },
        ]}
      />
      <Row>
        <Col span={24}>
          <PublicAllocation allocation={allocation} isLoading={isGetPublicMarketLoading} />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Portfolio portfolios={portfolios} isLoading={isGetPublicMarketLoading} />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
        <Col span={24}>
          <Account accounts={accounts} isLoading={isGetPublicMarketLoading} />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
        <Col xs={24} lg={12}>
          <RecentTrade recentTrades={transactions} isLoading={isGetPublicMarketLoading} />
        </Col>
        <Col xs={24} lg={12}>
          <PortfolioManagerComments
            portfolioManagerComments={pmComments}
            isLoading={isGetPublicMarketLoading}
          />
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ marginTop: 20 }}>
        <Col span={24}>
          <PublicHolding publicHoldings={positions} isLoading={isGetPublicMarketLoading} />
        </Col>
      </Row>
    </>
  );
}

export default PublicMarket;
