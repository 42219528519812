import { IPublicMarketData } from '@/views/public-market/interface';

export const mockPublicMarket: IPublicMarketData = {
  allocation: [
    {
      name: 'Domestic',
      market_value_raw: 61,
      market_value_display: '$61',
      percentage_raw: 62.5,
      percentage_display: '62.50%',
    },
    {
      name: 'International',
      market_value_raw: 61,
      market_value_display: '$61',
      percentage_raw: 62.5,
      percentage_display: '62.50%',
    },
    {
      name: 'Hedged-Equity',
      market_value_raw: 61,
      market_value_display: '$61',
      percentage_raw: 62.5,
      percentage_display: '62.50%',
    },
    {
      name: 'Hedged-Yield',
      market_value_raw: 61,
      market_value_display: '$61',
      percentage_raw: 62.5,
      percentage_display: '62.50%',
    },
    {
      name: 'Short-Term Tactical',
      market_value_raw: 61,
      market_value_display: '$61',
      percentage_raw: 62.5,
      percentage_display: '62.50%',
    },
    {
      name: 'Concentrated Stock',
      market_value_raw: 61,
      market_value_display: '$61',
      percentage_raw: 62.5,
      percentage_display: '62.50%',
    },
    {
      name: 'Fixed Income',
      market_value_raw: 61,
      market_value_display: '$61',
      percentage_raw: 62.5,
      percentage_display: '62.50%',
    },
  ],
  portfolios: [
    {
      name: 'Potfolio 1',
      type: 'Primary',
      percentage_raw: 10.5,
      percentage_display: '10.50%',
      market_value_raw: 15,
      market_value_display: '$15',
    },
  ],
  accounts: [
    {
      id: '1',
      account_number: '****1569',
      name: 'Account 1',
      tax_treatment: 'Taxable',
      public_raw: 25.56,
      public_display: '$25.56',
      private_raw: 4689.5,
      private_display: '$4689.50',
      total_raw: 15749.26,
      total_display: '$15749.26',
      public_guid: '61a7ac10-3341-464f-8d24-5736aa8ffe1c',
    },
  ],
  transactions: [
    {
      account_number: '****6789',
      transaction_date_display: '09/05/2023',
      transaction_date_raw: '2023-08-03T18:30:00.000Z',
      symbol: 'Cash',
      type: 'BUY',
      amount_raw: 1983.36,
      amount_display: '$1983.36',
      public_guid: '61a7ac10-3341-464f-8d24-5736aa8ffe1c',
    },
  ],
  positions: [
    {
      position_id: '1',
      symbol: 'Cash',
      description: 'This is description',
      quantity_raw: 75.25,
      quantity_display: '75.25',
      price_raw: 25.5,
      price_display: '$25.50',
      amount_raw: 1568.25,
      amount_display: '$1568.25',
    },
  ],
  pmComments: [
    {
      id: '1',
      date_raw: '2023-09-29',
      date_display: '09/29/2023',
      comment: 'No Comment',
    },
  ],
};
