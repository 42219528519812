import { RuleObject } from 'antd/es/form';
import moment from 'moment';

/*
getApiErrorMessage is used to show proper error message from API to user

*/

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getApiErrorMessage = (error: any) => {
  const message =
    error?.response?.data?.message ||
    (error?.response?.data?.error?.details?.[0]?.path &&
      `${error?.response?.data?.error?.details?.[0]?.path?.replace('/', '')} ${
        error?.response?.data?.error?.details?.[0]?.message
      }`) ||
    (error?.response?.data?.error && error?.response?.data?.error?.message) ||
    error.message ||
    error.toString();
  return message;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setValueLocalStorage = (key: any, value: any) => {
  JSON.stringify(localStorage.setItem(key, value));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getValueLocalStorage = (key: any) => {
  const value = localStorage.getItem(key);
  return value;
};

export const dollarCurrencyFormat = (num: number) =>
  new Intl.NumberFormat(`en-US`, {
    currency: `USD`,
    style: 'currency',
  }).format(num);

export const getTruncatedString = (source: string, skipCount: number) => {
  const content = source.trim();
  if (content.length > skipCount) {
    return `${content.substring(0, skipCount)}...`;
  }
  return source;
};

export const getPreviousBusinessDate = () => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  while (yesterday.getDay() === 0 || yesterday.getDay() === 6) {
    yesterday.setDate(yesterday.getDate() - 1);
  }
  return yesterday;
};

export const appendQueryParams = (url: string, params: Record<string, string>) => {
  const urlObj = new URL(url);
  Object.keys(params).forEach((key) => {
    urlObj.searchParams.set(key, params[key]);
  });
  return urlObj.href;
};

export const isPWA = () => {
  const isPwa = getValueLocalStorage('isPwa');
  return isPwa === '1';
};

export const getPreviousQuarterLastDate = () => {
  // Get the current date
  const currentDate = moment();
  // Get the start of the current quarter
  const startOfCurrentQuarter = currentDate.startOf('quarter');
  // Get the last day of the previous quarter
  const endOfPreviousQuarter = startOfCurrentQuarter.subtract(1, 'day');
  return endOfPreviousQuarter;
};

export const fileDownloadFromUrl = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const validatePassword = async (_: RuleObject, value: string) => {
  if (!value) {
    throw new Error('Please enter a password.');
  }
  if (value.length < 8) {
    throw new Error('Password must be at least 8 characters long.');
  }
  if (!/[A-Z]/.test(value)) {
    throw new Error('Password must include at least one uppercase letter.');
  }
  if (!/[a-z]/.test(value)) {
    throw new Error('Password must include at least one lowercase letter.');
  }
  if (!/[0-9]/.test(value)) {
    throw new Error('Password must include at least one number.');
  }
};
