/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { TableColumnsType } from 'antd';
import Table from '@/components/Table';
import './performanceSummary.css';
import HttpClient from '@/utils/HttpClient';
import { getApiErrorMessage } from '@/utils/helper';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import customNotification from '@/utils/notification';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { IBenchmarks, IPortfolio, IBasePortfolioAndBenchmark } from '../goals/interface';

interface DataType extends IBasePortfolioAndBenchmark {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

const sharedOnCell = (record: DataType, index?: number) => {
  if (record.isTitle) {
    return { colSpan: 0 };
  }

  return {};
};

function calculateTotalColSpan(columns: ColumnsType<DataType>): number {
  let totalColSpan = 0;

  function traverse(columnList: ColumnsType<DataType>) {
    columnList.forEach((column) => {
      if ('children' in column) {
        traverse(column.children as ColumnsType<DataType>);
      } else {
        totalColSpan += 1;
      }
    });
  }

  traverse(columns);
  return totalColSpan;
}
const timePeriodValueRender = (value: string) => {
  // if (value === null) {
  //   return <span>-.--%</span>;
  // }

  const updatedValue = value && value.replace('%', '');
  const sign = Math.sign(Number(updatedValue));
  if (sign < 0) {
    return <span style={{ color: 'red' }}>({Math.abs(Number(updatedValue))}%)</span>;
  }
  return <span>{value}</span>;
};

const columns: TableColumnsType<DataType> = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    width: 400,
    render(value, record, index) {
      return record.isTitle ? <strong>{value}</strong> : value;
    },
    onCell: (record, index) => ({
      colSpan: record.isTitle ? calculateTotalColSpan(columns) : 1,
    }),
  },
  {
    title: `Equity Allocation`,
    dataIndex: 'equity_allocation_display',
    render: (text: number) => (text ? `${text}` : ''),
    onCell: sharedOnCell,
  },

  {
    title: 'Current Market Value',
    dataIndex: 'market_value_display',
    key: 'market_value_display',
    onCell: sharedOnCell,
    render: (text: number) => text || '',
  },

  {
    title: '3 Month',
    key: '3_month_display',
    dataIndex: '3_month_display',
    onCell: sharedOnCell,
    render: (value) => timePeriodValueRender(value),
  },
  {
    title: 'YTD',
    dataIndex: 'ytd_display',
    key: 'ytd_display',
    onCell: sharedOnCell,
    render: (value) => timePeriodValueRender(value),
  },
  {
    title: '1 Year',
    dataIndex: '1_year_display',
    key: '1_year_display',
    onCell: sharedOnCell,
    render: (value) => timePeriodValueRender(value),
  },
  {
    title: '3 Year',
    dataIndex: '3_year_display',
    key: '3_year_display',
    onCell: sharedOnCell,
    render: (value) => timePeriodValueRender(value),
  },
  {
    title: '5 Year',
    dataIndex: '5_year_display',
    key: '5_year_display',
    onCell: sharedOnCell,
    render: (value) => timePeriodValueRender(value),
  },
  {
    title: '10 Year',
    dataIndex: '10_year_display',
    key: '10_year_display',
    onCell: sharedOnCell,
    render: (value) => timePeriodValueRender(value),
  },
  {
    title: 'Since Inception(MM/YY)',
    dataIndex: 'inception_display',
    key: 'inception_display',
    onCell: sharedOnCell,
    render: (text: string, record) => {
      if (text !== undefined) {
        return (
          <>
            {timePeriodValueRender(text)}
            <span>
              {record?.inception_date_display ? ` ${record.inception_date_display}` : ' (--/--)'}
            </span>
          </>
        );
      }
      return '';
    },
  },
];
interface InputData {
  benchmark: string;
  indexName: string;
  period: string;
  value: number | null;
}

interface ReturnData {
  name: string;
  return_raw: number | null;
  return_display: string;
}

interface IndexData {
  name: string;
  returns: ReturnData[];
}

interface BenchmarkData {
  name: string;
  indexes: IndexData[];
}

const periods = [
  { name: '3 month', key: '3_month' },
  { name: 'ytd', key: 'ytd' },
  { name: '1 year', key: '1_year' },
  { name: '3 year', key: '3_year' },
  { name: '5 year', key: '5_year' },
  { name: '10 year', key: '10_year' },
];

const convertData = (data: InputData[]): BenchmarkData[] => {
  const benchmarkMap: { [key: string]: BenchmarkData } = {};

  data.forEach((item) => {
    if (!benchmarkMap[item.benchmark]) {
      benchmarkMap[item.benchmark] = {
        name: item.benchmark,
        indexes: [],
      };
    }

    const benchmark = benchmarkMap[item.benchmark];
    let index = benchmark.indexes.find((idx) => idx.name === item.indexName);

    if (!index) {
      index = {
        name: item.indexName,
        returns: periods.map((period) => ({
          name: period.name,
          return_raw: null,
          return_display: '--.--%',
        })),
      };
      benchmark.indexes.push(index);
    }

    const period = index.returns.find((ret) => ret.name.replace(' ', '_') === item.period);
    if (period) {
      const return_display_value = item.value !== null ? item.value * 100 : null;
      period.return_raw = item.value;
      period.return_display =
        return_display_value !== null ? `${return_display_value.toFixed(2)}%` : '--.--%';
    }
  });
  return Object.values(benchmarkMap);
};

const formatBenchmarks = (benchmarks: BenchmarkData[]): any[] => {
  const result: any[] = [];

  benchmarks.forEach((benchmark) => {
    result.push({
      name: benchmark.name,
      isTitle: true,
    });

    benchmark.indexes.forEach((index) => {
      const returnsObject: { [key: string]: string } = {};
      index.returns.forEach((ret) => {
        const key = `${ret.name.replace(' ', '_')}_display`;
        returnsObject[key] = ret.return_display;
      });

      result.push({
        name: index.name,
        ...returnsObject,
      });
    });
  });
  return result;
};

const formatPortfolios = (portfolios: IPortfolio[]) =>
  portfolios.map((portfolio) => {
    const formattedPortfolio: DataType = {
      name: portfolio.name,
      market_value_raw: portfolio.market_value_raw,
      market_value_display: portfolio.market_value_display,
      equity_allocation_raw: portfolio.equity_allocation_raw,
      equity_allocation_display: portfolio.equity_allocation_display,
      return_date_raw: portfolio.return_date_raw,
      return_date_display: portfolio.return_date_display,
      inception_date_raw: portfolio.inception_date_raw,
      inception_date_display: portfolio.inception_date_display,
      '3_month_display': null,
      '1_year_display': null,
      ytd_display: null,
      '3_year_display': null,
      '5_year_display': null,
      '10_year_display': null,
      inception_display: null,
      '3_month_raw': null,
      '1_year_raw': null,
      ytd_raw: null,
      '3_year_raw': null,
      '5_year_raw': null,
      '10_year_raw': null,
      inception_raw: null,
    };

    // Add returns to the formatted portfolio
    portfolio.returns.forEach((ret) => {
      const baseKey = ret.name.toLowerCase().replace(' ', '_');
      const displayKey = `${baseKey}_display`;
      const rawKey = `${baseKey}_raw`;

      formattedPortfolio[displayKey] = ret.return_display;
      formattedPortfolio[rawKey] = ret.return_raw;
    });

    return formattedPortfolio;
  });

const Benchmarks: React.FC = () => {
  const [benchMarksData, setBenchmarkData] = React.useState<DataType[]>([]);

  const [asOfDate, setAsOfDate] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const fetchBenchMarksData = async () => {
    try {
      setIsLoading(true);
      const response = await HttpClient.get('/benchmark-performance');
      if (response?.data?.performance && response?.data?.benchmarks) {
        const convertedData = convertData(response?.data?.benchmarks);
        const formattedBenchmarks = formatBenchmarks(convertedData);

        const formattedPerformance: DataType[] = formatPortfolios(response.data.performance);
        setBenchmarkData([
          { name: 'My Portfolios (Net Of BIP Advisory Fees', isTitle: true },
          ...formattedPerformance,
          ...formattedBenchmarks,
        ]);
        setAsOfDate(response?.data?.as_of);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      const message = getApiErrorMessage(error);
      customNotification({ type: 'error', message });
    }
  };

  useEffect(() => {
    fetchBenchMarksData();
  }, []);
  return (
    <>
      <CustomHelmet title="Public Market Vs. Benchmarks" />

      <Table
        title={`Performance Summary (As of ${moment(asOfDate).format('MM/DD/YYYY')})`}
        globalSearch={false}
        columns={columns}
        dataSource={benchMarksData}
        bordered={true}
        pagination={false}
        rowClassName="custom-row"
        rowKey={(record) => record.name}
        isLoading={isLoading}
      />
    </>
  );
};

export default Benchmarks;
