import { Empty, Skeleton, Table as AntdTable, TableProps, Input, Row, Col } from 'antd';
import { ReactNode, useEffect, useState } from 'react';
import Card from '../Card';

const { Search } = Input;

interface IGlobalSearchProps {
  globalSearch?: boolean;
  isLoading?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface ITableProps extends Omit<TableProps<any>, 'title'>, IGlobalSearchProps {
  renderToolbar?: ReactNode;
  iconTitle?: ReactNode;
  title?: string;
  isLoading?: boolean;
  isCardLayout?: boolean;
  globalSearch?: boolean;
}
interface CustomTableProps<T> extends TableProps<T>, IGlobalSearchProps {
  globalSearch?: boolean;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function AntdTableView(props: CustomTableProps<any>) {
  const { columns, dataSource = [], globalSearch = true, isLoading, ...rest } = props;
  const [searchedData, setSearchedData] = useState(dataSource);
  useEffect(() => {
    setSearchedData(dataSource);
  }, [dataSource]);
  const search = (value: string) => {
    const filterData = dataSource.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase()))
    );
    setSearchedData(filterData);
  };
  return (
    <>
      {globalSearch && (
        <Row gutter={16}>
          <Col xs={{ span: 24, offset: 0 }} md={{ span: 12, offset: 12 }}>
            <div style={{ textAlign: 'right', marginBottom: '16px' }}>
              <Search
                placeholder="Search"
                style={{ width: 200 }}
                onChange={(e) => search(e.target.value)}
              />
            </div>
          </Col>
        </Row>
      )}
      {isLoading ? (
        <Skeleton active style={{ padding: 10 }} />
      ) : (
        <AntdTable
          locale={{ emptyText: <Empty /> }}
          columns={columns}
          dataSource={searchedData || dataSource}
          {...rest}
        />
      )}
    </>
  );
}
function Table(props: ITableProps) {
  const { title = '', renderToolbar, iconTitle, isCardLayout = true, ...rest } = props;

  return isCardLayout ? (
    <Card
      title={title}
      renderToolbar={renderToolbar}
      iconTitle={iconTitle}
      bodyStyle={{ padding: 0, paddingTop: 10 }}
    >
      <AntdTableView
        rowClassName={(record, index) =>
          // eslint-disable-next-line no-nested-ternary
          index % 2 === 0
            ? 'table-row-light'
            : record.name === 'Total'
            ? 'table-row-white '
            : 'table-row-dark'
        }
        size="small"
        scroll={{ x: 'max-content' }}
        {...rest}
      />
    </Card>
  ) : (
    <AntdTableView
      rowClassName={(record, index) =>
        // eslint-disable-next-line no-nested-ternary
        index % 2 === 0
          ? 'table-row-light'
          : record.name === 'Total'
          ? 'table-row-white '
          : 'table-row-dark'
      }
      size="small"
      scroll={{ x: 'max-content' }}
      {...rest}
    />
  );
}

Table.defaultProps = {
  iconTitle: null,
  renderToolbar: null,
  isLoading: false,
  isCardLayout: true,
  title: '',
  globalSearch: true,
};

AntdTableView.defaultProps = {
  globalSearch: true,
  isLoading: false,
};
export default Table;
