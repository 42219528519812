import { Area, Tooltip } from '@ant-design/plots';
import { dollarCurrencyFormat } from '@/utils/helper';
import moment from 'moment';
import { Datum } from '@ant-design/charts';

export interface IAreaChartProps {
  date: number | string;
  value: number;
  name?: string;
}
function AreaChart(props: {
  data: IAreaChartProps[];
  xAxisLabelType: string;
  isStacked?: boolean;
}) {
  const { data, xAxisLabelType, isStacked = false } = props;
  let tickCount = 0;
  if (xAxisLabelType === 'months') {
    // get unique month
    const uniqueMonth = new Set(data.map((item) => moment(item.date).format('MM/YYYY')));
    tickCount = uniqueMonth.size;
  } else {
    // getUniqueYear count
    const uniqueYear = new Set(data.map((item) => moment(item.date).year()));
    tickCount = uniqueYear.size;
  }
  const config = {
    data,
    xField: 'date',
    yField: 'value',
    seriesField: isStacked ? 'name' : undefined,

    yAxis: {
      label: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formatter: (value: any) => `${dollarCurrencyFormat(value)}`,
      },
    },
    xAxis: {
      tickCount,
      label: {
        formatter: (value: string) =>
          xAxisLabelType === 'year' ? moment(value).format('YYYY') : moment(value).format('MMM'), // Format to month using Moment.js
      },
    },

    tooltip: {
      formatter: isStacked
        ? (value: IAreaChartProps) => ({
            name: value.name,
            value: dollarCurrencyFormat(value.value),
          })
        : undefined,
      customContent: !isStacked
        ? (_, items: Datum) => {
            if (items && items.length) {
              const { date, value } = items?.[0]?.data ?? {};
              const tooltipValue = dollarCurrencyFormat(value);

              return `<div style="padding: 3px;font-weight:bold; margin:3px">${date} - ${tooltipValue}</div>`;
            }
            return '';
          }
        : undefined,
    } as Tooltip,
  };
  return <Area {...config} />;
}
AreaChart.defaultProps = {
  isStacked: false,
};

export default AreaChart;
