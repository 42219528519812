import { Typography } from 'antd';

function NewlineText(props: { text: string }) {
  const { text } = props;
  return (
    <>
      {text
        .split('\n')
        .filter((str) => str)
        .map((str: string) => (
          <Typography.Paragraph key={str}>{str}</Typography.Paragraph>
        ))}
    </>
  );
}

export default NewlineText;
