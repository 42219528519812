import { Col, Row } from 'antd';
import Card from '@/components/Card';
import Table from '@/components/Table';
import PieChart from '@/components/Charts/Pie';
import { ReactNode, useEffect, useState } from 'react';
import Footer from '@/components/Table/Footer';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { getPreviousQuarterLastDate } from '@/utils/helper';
import { IAssetWithoutPosition, IPrivateMarketSummary } from './interface';

const getFontSize = (width: number) => {
  if (width < 450) return 8;
  if (width < 520) return 9;
  return 10;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderFooter(pageData: any, isPending: boolean, columns: ColumnsType<any>): ReactNode {
  return (
    <Footer
      pageData={pageData}
      label="Private Total"
      sumFields={[
        { field: 'committed_display', displayFormat: 'currency', value: 'committed_raw' },
        { field: 'allocation_display', displayFormat: 'percent', value: 'allocation_raw' },
        { field: 'efv_display', displayFormat: 'currency', value: 'efv_raw' },
        { field: 'distributed_display', displayFormat: 'currency', value: 'distributed_raw' },
        { field: 'called_display', displayFormat: 'currency', value: 'called_raw' },
        { field: 'paid_display', displayFormat: 'currency', value: 'paid_raw' },
        { field: 'pending_display', displayFormat: 'currency', value: 'pending_raw' },
        { field: 'remaining_display', displayFormat: 'currency', value: 'remaining_raw' },
      ]}
      columns={columns}
    />
  );
}

function PrivateMarketSummary({ data, isLoading }: IPrivateMarketSummary) {
  const [summaryData, setSummaryData] = useState<IAssetWithoutPosition[]>([]);

  useEffect(() => {
    const outputArray = Object.keys(data).map((key) => {
      const value = data[key];
      return {
        key,
        name: value?.name,
        committed_raw: value?.committed_raw || 0,
        committed_display: value?.committed_display || '$0.00',
        called_raw: value?.called_raw || 0,
        called_display: value?.called_display || '$0.00',
        distributed_raw: value?.distributed_raw || 0,
        distributed_display: value?.distributed_display || '$0.00',
        paid_raw: value?.paid_raw || 0,
        paid_display: value?.paid_display || '$0.00',
        efv_raw: value?.efv_raw || 0,
        efv_display: value?.efv_display || '$0.00',
        allocation_raw: value?.allocation_raw || 0,
        allocation_display: value?.allocation_display || '0.00%',
        pending_raw: value?.pending_raw || 0,
        pending_display: value?.pending_display || '0.00%',
        remaining_raw: value?.remaining_raw || 0,
        remaining_display: value?.remaining_display || '0.00%',
        id: value?.id,
      };
    });

    setSummaryData(outputArray);
  }, [data]);

  // Is Pending is needed to show pending column
  const isPending = summaryData.some((item) => Boolean(item.pending_raw) && item.pending_raw > 0);
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'COMMITTED',
      dataIndex: 'committed_display',
      key: 'committed_display',
    },
    {
      title: 'CALLED',
      dataIndex: 'called_display',
      key: 'called_display',
    },
    {
      title: 'REMAINING',
      dataIndex: 'remaining_display',
      key: 'remaining_display',
    },
    {
      title: 'DISTRIBUTED',
      dataIndex: 'distributed_display',
      key: 'distributed_display',
    },
    {
      title: 'PAID TO DATE',
      dataIndex: 'paid_display',
      key: 'paid_display',
    },
    {
      title: 'PENDING',
      dataIndex: 'pending_display',
      key: 'pending_display',
      hidden: !isPending,
    },
    {
      title: 'EFV',
      dataIndex: 'efv_display',
      key: 'efv_display',
    },
    {
      title: '% OF TOTAL',
      dataIndex: 'allocation_display',
      key: 'allocation_display',
    },
  ].filter((item) => !item.hidden);

  const [legendFontSize, setLegendFontSize] = useState(10);

  useEffect(() => {
    const canvas = document.querySelector('canvas');
    if (canvas) {
      const canvasWidth = canvas.width;
      setLegendFontSize(getFontSize(canvasWidth));
    }
  }, []);

  return (
    <Card
      title="Private Market Summary"
      subTitle={`(All position information provided is as of ${moment().format(
        'MMMM DD, YYYY'
      )}, except Net IRR which is as of ${getPreviousQuarterLastDate().format('MMMM DD, YYYY')})`}
      bodyStyle={{ padding: 12 }}
    >
      <Row gutter={[8, 24]}>
        <Col xs={24} sm={24} md={24} lg={18}>
          <Table
            className="bg-header-white"
            isCardLayout={false}
            dataSource={summaryData}
            columns={columns}
            pagination={false}
            globalSearch={false}
            summary={(pageData) => renderFooter(pageData, isPending, columns)}
            isLoading={isLoading}
            scroll={{ x: 'max-content' }}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} style={{ height: 240 }}>
          {!isLoading && (
            <PieChart
              isCardLayout={false}
              data={[
                {
                  type: 'Equity',
                  value: Number(data?.equity?.allocation_raw) || 0,
                },
                {
                  type: 'Fixed Income',
                  value: Number(data?.debt?.allocation_raw) || 0,
                },
                {
                  type: 'Real Estate',
                  value: Number(data?.real_estate?.allocation_raw) || 0,
                },
              ]}
              chartColors={['#08203c', '#47cd9d', '#f5d20c']}
              isLoading={isLoading}
              tooltipCurrencyFormat={true}
              showCustomPercent={true}
              legendFontSize={legendFontSize}
            />
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default PrivateMarketSummary;
