import Documents from '@/components/Documents';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import { featureFlag } from '@/utils/featureFlags';
import { Typography } from 'antd';

const { Paragraph } = Typography;
function DocumentsPage() {
  return (
    <>
      <CustomHelmet title="Documents" />
      {featureFlag['page:documents'] ? (
        <Documents />
      ) : (
        <Paragraph
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '60vh',
            fontSize: '1.5rem',
          }}
        >
          This page is temporarily unavailable. Please try again later.
        </Paragraph>
      )}
    </>
  );
}

export default DocumentsPage;
