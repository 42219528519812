import { Navigate } from 'react-router-dom';
import TwoFactor from '@/views/auth/2fa';
import AuthLayout from '../layout/AuthLayout';
import PageNotFound from '../views/404';
import ForgotPassword from '../views/auth/forgot-password/ForgotPassword';
import Login from '../views/auth/login/Login';
import Otp from '../views/auth/otp';
import ResetPassword from '../views/auth/reset-password/ResetPassword';
// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
  path: '/',
  element: <AuthLayout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/login" />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/reset-password',
      element: <ResetPassword />,
    },
    { path: '/otp', element: <Otp /> },
    { path: '/second-step-verification', element: <TwoFactor /> },
    {
      path: '*',
      element: <PageNotFound />,
    },
  ],
};
export default AuthRoutes;
