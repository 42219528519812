import { IK1CheckList } from '@/views/private-market/k1-checklist/interface';

export const mockK1CheckList: IK1CheckList = {
  accounts: {
    '1': {
      account_name: 'Account 1',
      positions: [
        {
          investment_name: 'Investment 1',
          committed_amount: 100,
          account_name: 'Account 1',
          file_path: 'file path',
          file_name: 'file name',
          document_id: 1,
        },
      ],
    },
    '2': {
      account_name: 'Account 2',
      positions: [
        {
          investment_name: 'Investment 2',
          committed_amount: 100,
          account_name: 'Account 2',
          file_path: 'file path',
          file_name: 'file name',
          document_id: null,
        },
      ],
    },
  },
  totalNav: 100,
  totalIlLiquid: 100,
};
