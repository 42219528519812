import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiErrorMessage } from '../utils/helper';
import HttpClient from '../utils/HttpClient';

// ==============================|| Dashboard ||============================== //

export const getDashboard = createAsyncThunk('dashboard', async (_, thunkAPI) => {
  try {
    const response = await HttpClient.get('/dashboard');
    return { data: response.data };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return thunkAPI.rejectWithValue(getApiErrorMessage(error));
  }
});

const dashboardService = {
  getDashboard,
};
export default dashboardService;
