import { Button, Form, Input, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RetrySms from '@/components/RetrySms/RetrySms';
import CustomHelmet from '@/components/Helmet/CustomHelmet';
import AuthContainer from '../auth-container/AuthContainer';
import { AppDispatch, AppState } from '../../../store';
import { verifyOtp } from '../../../services/authService';
import Loader from '../../../components/Loader';
import customNotification from '../../../utils/notification';
import { authMessages } from '../../../utils/messages';

function OTP() {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  // const [numberOfAttempts, setNumberOfAttempts] = useState(0);
  const [visible, setVisible] = useState(false);

  const { PLEASE_ENTER_OTP, ONLY_NUMBERS_ALLOWED } = authMessages;
  const { isSubmitOtpLoading, preference_id, loginSuccessMessage } = useSelector(
    (state: AppState) => state.auth
  );

  const onSubmit = (values: { otp: string }) => {
    const { otp } = values;
    if (otp.length === 6) {
      // setNumberOfAttempts((prevCount) => prevCount + 1);
      dispatch(
        verifyOtp({
          code: otp,
          selectedTwoFaMethod: preference_id,
          navigate,
        })
      );
    } else {
      customNotification({ type: 'error', message: PLEASE_ENTER_OTP });
    }
  };

  // const showModal = () => {
  //   setVisible(true);
  // };

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };
  return (
    <AuthContainer>
      <CustomHelmet title="Verification" />

      <Form onFinish={onSubmit}>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <h4 className="bold-text">TWO-FACTOR AUTHENTICATION</h4>
          <p style={{ marginTop: 10, marginBottom: 20 }}>
            {loginSuccessMessage || `Please enter your security code.`}
          </p>
          <Form.Item
            name="otp"
            rules={[
              { required: true, message: PLEASE_ENTER_OTP },
              { pattern: /^[0-9]+$/, message: ONLY_NUMBERS_ALLOWED },
            ]}
          >
            <Input
              data-testid="otpInput"
              placeholder="Verification Code"
              maxLength={6}
              autoFocus={true}
            />
          </Form.Item>
        </div>

        <div className="auth-container-footer">
          {isSubmitOtpLoading ? (
            <Loader />
          ) : (
            <>
              <Form.Item>
                {/* {numberOfAttempts >= 3 && (
                  <Button
                    data-testid="submit"
                    type="primary"
                    className="uppercase"
                    style={{ backgroundColor: '#32c5d2', color: '#fff' }}
                    onClick={showModal}
                  >
                    Trouble Logging In?
                  </Button>
                )} */}
                {Number(preference_id) === 1 && <RetrySms />}
              </Form.Item>
              <Form.Item>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    data-testid="submit"
                    type="primary"
                    htmlType="submit"
                    onSubmit={(e) => e.preventDefault()}
                    className="uppercase"
                    style={{ backgroundColor: '#09323C', color: '#66BFBD' }}
                  >
                    Verify
                  </Button>
                </div>
              </Form.Item>
            </>
          )}
        </div>
      </Form>
      <Modal
        destroyOnClose={true}
        visible={visible}
        title="Trouble Logging in?"
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" key="submit" onClick={handleOk}>
            Send Text!
          </Button>,

          <Button type="primary" danger key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <p>
          If you have forgotten or lost your two-factor credential, you can have a text-message sent
          to the phone number on file. Please click on the "Send Code" to have a temporary passcode
          texted to you
        </p>
      </Modal>
    </AuthContainer>
  );
}
export default OTP;
