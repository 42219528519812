/* eslint-disable @typescript-eslint/no-explicit-any */
import { getHouseholdList, getTwoFaPreferences } from '@/services/commonService';
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { IPreference } from '../auth/authInterface';

export interface ICommonSlice {
  twoFaMethods: [];
  selectedHousehold: string | null;
  householdLists: {
    householdid: number;
    name: string;
    friendlyname: string;
    first_name: string;
    last_name: string;
  }[];
}

const initialState: ICommonSlice = {
  // ==============================|| Common Store ||============================== //
  twoFaMethods: [],
  selectedHousehold: null,
  householdLists: [],
};

function twoFaPreferences(builder: ActionReducerMapBuilder<ICommonSlice>) {
  builder
    // ==============================|| GET Two FA Preferences ||============================== //
    .addCase(getTwoFaPreferences.pending, (state) => ({
      ...state,
    }))
    .addCase(getTwoFaPreferences.fulfilled, (state, action: any) => {
      const formattedPreferences = Array.isArray(action?.payload?.data)
        ? action.payload.data
            .map((preferences: IPreference) => {
              if (preferences.preference === 'SMS') {
                return {
                  id: preferences.id,
                  preference: preferences.preference,
                  name: 'Text Message',
                };
              }
              if (preferences.preference === 'TOTP') {
                return {
                  id: preferences.id,
                  preference: preferences.preference,
                  name: 'Authenticator App',
                };
              }
              return null;
            })
            .filter(Boolean)
        : [];
      return {
        ...state,
        twoFaMethods: formattedPreferences ?? [],
      };
    })
    .addCase(getTwoFaPreferences.rejected, (state, action: any) => ({
      ...state,
    }));
}

function householdList(builder: ActionReducerMapBuilder<ICommonSlice>) {
  builder
    // ==============================|| GET Two FA Preferences ||============================== //
    .addCase(getHouseholdList.pending, (state) => ({
      ...state,
    }))
    .addCase(getHouseholdList.fulfilled, (state, action: any) => ({
      ...state,
      householdLists: action.payload?.data ?? [],
    }))
    .addCase(getHouseholdList.rejected, (state, action: any) => ({
      ...state,
    }));
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setSelectedHousehold: (state, action) => {
      state.selectedHousehold = action.payload;
    },
  },
  extraReducers: (builder) => {
    twoFaPreferences(builder);
    householdList(builder);
  },
});

export const { setSelectedHousehold } = commonSlice.actions;
export default commonSlice.reducer;
