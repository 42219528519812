import { createAsyncThunk } from '@reduxjs/toolkit';
import customNotification from '@/utils/notification';
import { initializePendo } from '@/utils/pendo';
import { getApiErrorMessage, setValueLocalStorage, getValueLocalStorage } from '../utils/helper';
import HttpClient from '../utils/HttpClient';
import { IEnrollData, IOtpData, IPreference, IsignInData } from '../store/auth/authInterface';

export const signIn = createAsyncThunk('/login', async (data: IsignInData, thunkAPI) => {
  try {
    const { email, password, navigate } = data;
    const params = {
      email,
      password,
    };
    const response = await HttpClient.post('/login', params);
    if (response.status === 200) {
      customNotification({ type: 'success', message: response.data.message });

      setValueLocalStorage('userId', response.data.userId);
      setValueLocalStorage('phoneNumber', response.data.phone_number || '');

      if (response?.data?.redirectTo2FA) {
        navigate(`/second-step-verification`, { replace: true });
      } else {
        navigate(`/otp`, { replace: true });
      }
    }
    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessage = getApiErrorMessage(error);
    customNotification({ type: 'error', message: errorMessage });
    return thunkAPI.rejectWithValue('');
  }
});

export const verifyOtp = createAsyncThunk('auth/verifyOtp', async (data: IOtpData, thunkAPI) => {
  try {
    const { code, navigate, isEnrollVerify } = data;
    const userId = getValueLocalStorage('userId');
    const params = {
      secretCode: code,
      userId,
      isEnrollVerify,
    };
    const response = await HttpClient.post('/verify', params);
    if (response.status === 200) {
      customNotification({ type: 'success', message: response.data.message });
      setValueLocalStorage('isLoggedIn', true);
      const name = `${response?.data?.user?.first_name || ''} ${
        response?.data?.user?.last_name || ''
      }`;
      setValueLocalStorage('name', name);
      // For Pendo need email, householdid,name and userId
      setValueLocalStorage('email', response?.data?.user?.email);
      setValueLocalStorage('householdId', response?.data?.user?.householdid);
      // Save pwa related details in local storage only when user is pwa user
      if (response?.data?.user?.ispwa === 1) {
        setValueLocalStorage('isPwa', response?.data?.user?.ispwa);
        setValueLocalStorage('pwaId', response?.data?.user?.pwaid);
        setValueLocalStorage('selectedHousehold', response?.data?.user?.householdid);
      }
      // Used to autofill phone number in 2FA settings Page
      if (response?.data?.user?.preference_id === 1) {
        setValueLocalStorage('phoneNumber', response?.data?.user?.phone_number);
      }
      // Initialize pendo on successful login
      initializePendo();
      // setValueLocalStorage('token', response.data.token);
      navigate('/');
    }

    return response.data;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessage = getApiErrorMessage(error);
    customNotification({ type: 'error', message: errorMessage });
    return thunkAPI.rejectWithValue('');
  }
});

export const enrollService = createAsyncThunk(
  'auth/enroll',
  async (data: IEnrollData, thunkAPI) => {
    try {
      const {
        handleQRData,
        selectedTwoFaMethod,
        setCurrent,
        current,
        handle2FaIsLoading,
        phoneNumber,
        twoFaMethods,
      } = data;
      const userId = getValueLocalStorage('userId');

      const selectedPreference: IPreference = twoFaMethods.find(
        (method: { id: string; preference: string }) => method.preference === selectedTwoFaMethod
      ) || { id: '', preference: '' };
      let bodyParams: { userId: string | null; preference_id: string; phone_number?: string } = {
        userId,
        preference_id: selectedPreference?.id,
        phone_number: '',
      };
      const formattedPhoneNumber = phoneNumber.startsWith('+1') ? phoneNumber : `+1${phoneNumber}`;
      if (selectedTwoFaMethod === 'SMS') {
        bodyParams = {
          ...bodyParams,
          phone_number: formattedPhoneNumber,
          // phone_number: phoneNumber,
        };
      }
      handle2FaIsLoading(true);
      const response = await HttpClient.post('/enroll', bodyParams);
      if (response.status === 200) {
        if (selectedTwoFaMethod === 'TOTP') {
          handleQRData({ qrUrl: response?.data?.qrUrl, secret: response?.data?.secret });
        }
        if (selectedTwoFaMethod === 'SMS') {
          setValueLocalStorage('phoneNumber', formattedPhoneNumber);
        }
        customNotification({ type: 'success', message: response.data.message });
        setCurrent(current + 1);
      }
      handle2FaIsLoading(false);
      return response.data;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = getApiErrorMessage(error);
      customNotification({ type: 'error', message });
      data?.handle2FaIsLoading(false);
      return thunkAPI.rejectWithValue('');
    }
  }
);

export const verifyFactorId = async (data: IOtpData) => {
  try {
    const { code } = data;
    const userId = getValueLocalStorage('userId');
    const params = {
      secretCode: code,
      userId,
    };
    const response = await HttpClient.post('/verify-factor', params);
    customNotification({ type: 'success', message: response.data.message });

    return response.data;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessage = getApiErrorMessage(error);
    customNotification({ type: 'error', message: errorMessage });
    return errorMessage;
  }
};

export const sendOtp = createAsyncThunk('auth/sendOtp', async (_, thunkAPI) => {
  try {
    const userId = getValueLocalStorage('userId');
    const phone = getValueLocalStorage('phoneNumber');

    const params = {
      phone_number: phone,
      userId,
    };
    const response = await HttpClient.post('/resend-otp', params);
    if (response.status === 200) {
      customNotification({ type: 'success', message: response.data.message });
    }

    return response.data;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessage = getApiErrorMessage(error);
    customNotification({ type: 'error', message: errorMessage });
    return thunkAPI.rejectWithValue('');
  }
});

export const changePassword = async (data: { currentPassword: string; newPassword: string }) => {
  try {
    const { currentPassword, newPassword } = data;
    const userId = getValueLocalStorage('userId');
    const params = {
      oldPassword: currentPassword,
      userId,
      newPassword,
    };
    const response = await HttpClient.post('/change-password', params);
    if (response?.data?.success === false) {
      customNotification({ type: 'error', message: response.data.message });
    } else {
      customNotification({ type: 'success', message: response.data.message });
    }

    return response.data;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessage = getApiErrorMessage(error);
    customNotification({ type: 'error', message: errorMessage });
    return errorMessage;
  }
};

export const forgotPassword = async (data: { email: string }) => {
  try {
    const { email } = data;
    const params = {
      email,
    };
    const response = await HttpClient.post('/forgot-password/get-user-by-email', params);
    if (response?.data?.success === true) {
      setValueLocalStorage('email', email);
      setValueLocalStorage('forgotPasswordMessage', response?.data?.message);
      customNotification({ type: 'success', message: response?.data?.message });
    } else {
      customNotification({ type: 'error', message: response?.data?.message });
    }

    return response;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessage = getApiErrorMessage(error);
    customNotification({ type: 'error', message: errorMessage });
    return errorMessage;
  }
};

export const resetPassword = async (data: {
  email: string;
  password: string;
  secretCode: string;
}) => {
  try {
    const { password, email, secretCode } = data;
    const params = {
      email,
      password,
      secretCode,
    };
    const response = await HttpClient.post('/forgot-password', params);
    if (response?.data?.success === true) {
      customNotification({ type: 'success', message: response.data.message });
    } else {
      customNotification({ type: 'error', message: response.data.message });
    }

    return response;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    const errorMessage = getApiErrorMessage(error);
    customNotification({ type: 'error', message: errorMessage });
    return errorMessage;
  }
};
const authService = {
  signIn,
  verifyOtp,
  enrollService,
  verifyFactorId,
  sendOtp,
  changePassword,
};
export default authService;
