import RetrySms from '@/components/RetrySms/RetrySms';
import { verifyFactorId, verifyOtp } from '@/services/authService';
import { AppDispatch, AppState } from '@/store';
import { Space, Typography, Input, Button, QRCode } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const { Paragraph, Text } = Typography;

function LinkAccount(props: {
  selectedTwoFaMethod: string;
  qrData: { qrUrl: string; secret: string };
}) {
  const { selectedTwoFaMethod, qrData } = props;
  // const [isOtpSent, setIsOtpSent] = useState(false);
  const [code, setCode] = useState('');
  const [isFactorVerified, setIsFactorVerified] = useState(false);
  const [isSubmitFactorCodeLoading, setIsSubmitFactorCodeLoading] = useState(false);

  const navigate = useNavigate();

  const isSubmitOtpLoading = useSelector((state: AppState) => state.auth.isSubmitOtpLoading);

  const dispatch = useDispatch<AppDispatch>();

  const onVerify = async () => {
    if (isFactorVerified || selectedTwoFaMethod === 'SMS') {
      return dispatch(verifyOtp({ selectedTwoFaMethod, code, navigate, isEnrollVerify: true }));
    }
    try {
      setIsSubmitFactorCodeLoading(true);
      const response = await verifyFactorId({ code });
      if (response.success === true) {
        setIsFactorVerified(true);
        setCode('');
      }
      setIsSubmitFactorCodeLoading(false);

      return response;
    } catch (error) {
      setIsSubmitFactorCodeLoading(false);
      return error;
    }
  };
  return (
    <>
      {selectedTwoFaMethod === 'SMS' && (
        <>
          <Paragraph>{`To use our text-based authentication, you must have a phone that is able to receive text messages. `}</Paragraph>
          {/* <Space>
            <Input placeholder="Phone Number" size="middle" style={{ width: 300 }} />
            <Button type="primary" onClick={() => setIsOtpSent(true)}>
              Send Text
            </Button>
          </Space> */}
        </>
      )}
      {selectedTwoFaMethod === 'TOTP' && (
        <>
          <Paragraph>
            {` The authentication app that you downloaded in the previous step supports scanning QR codes. Please scan the following image with
            your smartphone’s camera by clicking on “Scan Barcode” in the app.`}
          </Paragraph>
          <QRCode value={qrData?.qrUrl} style={{ margin: 'auto' }} size={200} />
          <Paragraph style={{ marginTop: 10 }}>
            {`If your smart phone does not support QR codes, please click "“Manually Add Account" and enter the following key:`}{' '}
          </Paragraph>
          <Paragraph strong={true}>{qrData?.secret}</Paragraph>
          <Paragraph>
            {`Once you have scanned the QPR code or manually entered the key above, please enter the 6-digit pin showing in your authenticator app and click the ‘Verify Access Code' button. `}
          </Paragraph>
          <Paragraph strong>
            NOTE: You will need to complete this process twice – First to enroll the device and
            second to verify the device.
          </Paragraph>
        </>
      )}
      {isFactorVerified && (
        <Text mark strong>
          Please enter the new code visible in your Authentication App
        </Text>
      )}
      {/* {(isOtpSent || method !== 'text') && ( */}
      <>
        <Typography.Title level={5}>Code:</Typography.Title>
        <Space>
          <Input
            placeholder="Verification Code"
            size="middle"
            style={{ width: 300 }}
            onChange={(e) => {
              setCode(e.target.value);
            }}
            maxLength={6}
            value={code}
          />
          <Button
            type="primary"
            onClick={onVerify}
            disabled={code?.length !== 6}
            loading={isSubmitOtpLoading || isSubmitFactorCodeLoading}
          >
            Verify Access Code
          </Button>
        </Space>
      </>
      {selectedTwoFaMethod === 'SMS' && (
        <div style={{ marginTop: 20 }}>
          <RetrySms />
        </div>
      )}
      {/* )} */}
    </>
  );
}

export default LinkAccount;
